<template>
  <div class="payment-form-container">
    <v-dialog v-model="dialog" max-width="500px" @click:outside="clearData">
      <template v-slot:activator="{ attrs }">
        <v-btn
          v-if="!noCreateButton"
          color="success"
          class="mb-2"
          v-bind="attrs"
          @click="toggleDialog"
          >+ เพิ่มบัญชี</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ modalTitle }}บัญชี</span>
        </v-card-title>

        <v-card-text class="modal-card-text">
          <v-container>
            <v-tabs v-model="tabs" color="success" fixed-tabs>
              <v-tab
                v-for="item in tabItems"
                :key="item.tab"
                :disabled="
                  disabled === '' ? false : disabled === item.tab ? false : true
                "
                >{{ item.text }}</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="item in tabItems" :key="item.tab">
                <v-card class="pt-5">
                  <v-card-text>
                    <v-form ref="form" v-if="item.tab === 'transfer'">
                      <v-row>
                        <v-col sm="12">
                          <v-select
                            label="ธนาคาร*"
                            :items="generateBankItems"
                            v-model="formData.bankID"
                            dense
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            v-model="formData.branchName"
                            label="สาขา*"
                            outlined
                            dense
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            v-model="formData.accountNo"
                            label="เลขที่บัญชี*"
                            counter="10"
                            outlined
                            dense
                            @keypress="validateNumeric"
                            :rules="[rules.required, rules.maxBankingNumber]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            v-model="formData.accountName"
                            label="ชื่อบัญชี*"
                            outlined
                            dense
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form ref="form" v-if="item.tab === 'promptpay'">
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            label="เบอร์มือถือ/เลขบัตรประชาชน/เลขประจำตัวผู้เสียภาษี*"
                            v-model="formData.promptpayNo"
                            counter="13"
                            outlined
                            dense
                            @keypress="validateNumeric"
                            :rules="[rules.required, rules.maxPromptpayNumber]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            v-model="formData.promptpayAccountName"
                            label="ชื่อบัญชี*"
                            outlined
                            dense
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="clearData">ปิดหน้าต่าง</v-btn>
          <v-btn color="success" @click="submitForm" :disabled="validate"
            >บันทึก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, findIndex, forEach, reduce, size } from "lodash";
import Utils from "@/mixin";

export default {
  mixins: [Utils],
  computed: {
    validate() {
      const { tabs } = this;
      const {
        branchName,
        accountNo,
        accountName,
        bankID,
        promptpayNo,
        promptpayAccountName,
      } = this.formData;
      if (
        (tabs === 0 && !(branchName && accountNo && accountName && bankID)) ||
        (tabs === 1 && !(promptpayNo && promptpayAccountName))
      ) {
        return true;
      } else {
        return false;
      }
    },
    generateBankItems() {
      const { banksList } = this;
      const result = reduce(
        banksList.data,
        (o, e) => {
          const { id, name } = e;
          o.push({
            value: id,
            text: name,
          });
          return o;
        },
        []
      );
      if (this.formData.bankID === null)
        this.formData.bankID = result[0]?.value; // eslint-disable-line
      return result;
    },
  },
  data: () => ({
    modalTitle: "เพิ่ม",
    data: {},
    initialForm: {
      branchName: "",
      accountNo: "",
      accountName: "",
      bankID: null,
      promptpayNo: "",
      promptpayAccountName: "",
    },
    formData: {
      branchName: "",
      accountNo: "",
      accountName: "",
      bankID: null,
      promptpayNo: "",
      promptpayAccountName: "",
    },
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
      maxBankingNumber: (value) =>
        (!!value && value.length <= 10) || "รูปแบบเลขบัญชีไม่ถูกต้อง",
      maxPromptpayNumber: (value) =>
        (!!value && value.length <= 13 && value.length >= 10) ||
        "รูปแบบพร้อมเพย์ไม่ถูกต้อง",
    },
    tabs: null,
    tabItems: [
      { text: "บัญชีธนาคาร", tab: "transfer" },
      { text: "พร้อมเพย์", tab: "promptpay" },
    ],
    upOption: {
      maxWidth: 1000,
    },
    disabled: "",
  }),
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async getInitialData() {
      await this.getPaymentMethods();
      await this.getBanks();
    },
    removeImage(item, index) {
      const ritem = this.data?.productPhotos?.find((e) => e.url === item);

      if (ritem?.id) {
        this.removeProductPhoto(ritem).then(() => this.removeImgIndex(index));
        return;
      }
      this.removeImgIndex(index);
    },
    removeImgIndex(index) {
      const imgs = this.formData.image;
      imgs.splice(index, 1);
      this.formData.image = imgs;
    },
    clearData() {
      if (this.$props.editId !== "") this.$props.setInitialDialog();
      this.toggleDialog();
      this.$nextTick(() => {
        this.modalTitle = "เพิ่ม";
        const cloneInit = cloneDeep(this.initialForm);
        this.formData = Object.assign({}, cloneInit);
        if (size(this.$refs.form) > 0) {
          forEach(this.$refs.form, (i) => {
            i.resetValidation();
          });
        }
        this.disabled = "";
        this.tabs = null;
      });
    },
    async submitForm() {
      const { formData, tabs, tabItems } = this;

      this.$emit("submit", { ...formData, slug: tabItems[tabs]?.tab });
      this.clearData();
    },
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    noCreateButton: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      default: () => {},
    },
    setInitialDialog: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "แก้ไข";
        const { accountBanksList } = this;
        const index = findIndex(
          accountBanksList?.data || [],
          (i) => i.id === this.editId
        );

        if (index !== -1) {
          const data = accountBanksList?.data?.[index] || {};
          this.data = data;
          const { slug } = data?.shopPaymentMethod?.paymentMethod;

          if (slug === "transfer") {
            this.disabled = slug;
            this.tabs = 0;
            this.formData = {
              branchName: data.branchName,
              accountNo: data.accountNo,
              accountName: data.accountName,
              bankID: data.bank.id,
            };
          } else {
            this.disabled = slug;
            this.tabs = 1;
            this.formData = {
              promptpayNo: data.accountNo,
              promptpayAccountName: data.accountName,
            };
          }
        }
      }
    },
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
  async mounted() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
  },
};
</script>