import axios from 'axios'

import { baseURL } from '@/plugins/common'
import { getToken } from '@/plugins/auth'

const token = getToken()

let headers = {}
if (token) {
  headers = {
    Authorization: `Bearer ${token}`
  }
}

const REQUEST = axios.create({
  baseURL,
  headers
})

export const setAuthorization = (token) => REQUEST.defaults.headers.common['Authorization'] = `Bearer ${token}`

export async function REQUEST_GET(URL, data, headers) {
  const res = await REQUEST.get(URL, { params: data, headers })
  return res
}

export async function REQUEST_POST(URL, data, config = {}) {
  const res = await REQUEST.post(URL, data, config)
  return res
}

export async function REQUEST_PUT(URL, data) {
  const res = await REQUEST.put(URL, data)
  return res
}

export async function REQUEST_DELETE(URL, paramId) {
  const res = await REQUEST.delete(URL, { data: paramId })
  return res
}

export async function REQUEST_PATCH(URL, data) {
  const res = await REQUEST.patch(URL, data)
  return res
}

export async function REQUEST_DOWNLOAD(URL, data) {
  const res = await REQUEST.get(URL, { 
    params: data ,
    responseType:"blob"
  })
  return res
}