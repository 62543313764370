import { cloneDeep, findIndex, size } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_PUT,
  REQUEST_POST,
  REQUEST_PATCH,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  accountBanks: {
    data: [],
    total: 0,
  },
  notifitions: {
    data: [],
    total: 0,
  },
  banks: {
    data: [],
  },
  buyers: {
    data: [],
    total: 0,
  },
  sellers: {
    data: [],
    total: 0,
  },
  paymentMethods: {
    data: [],
  },
  paymentTypes: {
    data: [],
  },
  regions: {
    data: [],
    total: 0,
  },
  shopTypes: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_PARTNER_BUYERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/cms/shops/${data.selectedShop}/partners/buyer`,
        data.form
      )
      const { total } = res.data
      state.buyers = {
        data: res?.data?.data,
        total,
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_TYPES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/shops/buyer`, data.form)
      state.shopTypes = {
        data: res?.data,
        total: size(res.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_BUYERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/shops/buyer`, data.form)
      state.buyers = {
        data: res?.data,
        total: size(res.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_COUPON_BUYERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/cms/coupon-transaction/list-buyer`,
        data.form
      )
      state.buyers = {
        data: res?.data?.data,
        total: size(res.data?.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SELLERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/shops/seller`, data.form)
      state.sellers = {
        data: res?.data,
        total: size(res.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_COUPON_SELLERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/cms/coupon-transaction/list-seller`,
        data.form
      )
      state.sellers = {
        data: res?.data?.data,
        total: size(res.data?.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_PAYMENT_METHODS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/payment_methods`
      )
      state.paymentMethods = {
        data: res?.data,
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ACCOUNT_BANKS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/payment_methods/details`
      )
      state.accountBanks = {
        data: res?.data,
        total: size(res?.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_NOTIFICATION(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/notifications`,
        data.form
      )
      state.notifitions = {
        data: res?.data?.data,
        total: size(res?.data?.data),
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_PAYMENT_TYPES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/paymenttypes`
      )
      state.paymentTypes = {
        data: res?.data?.data,
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_BANKS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/banks`)
      state.banks = {
        data: res?.data,
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_BANK(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/payment_methods/${data.form.slug}/details`,
        data.form
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 201: {
          state.accountBanks.data.push(res.data)
          state.accountBanks.total++
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.CREATE.SUCCESS,
            'ok',
            'success'
          )
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.CREATE.ERROR,
            'error',
            'error'
          )
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPDATE_PARTNER_BUYERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/cms/shops/${data.selectedShop}/partners/credit/bulk`,
        { data: data.form }
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.ERROR, 'error', 'error')
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async UPDATE_PAYMENT_METHODS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/payment_methods/${data.paramId}`
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.ERROR,
            'error',
            'error'
          )
          location.reload()
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPDATE_PAYMENT_TYPES(state, data) {
    data.toggleLoading()
    try {
      const { id, shopPaymentType } = data.form
      const res = await REQUEST_PATCH(
        `/api/shops/${data.selectedShop}/paymenttypes/${id}/enable/${shopPaymentType.enabled}`
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.ERROR,
            'error',
            'error'
          )
          location.reload()
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EDIT_BANK(state, data) {
    data.toggleLoading()
    try {
      const { form } = data
      const formData = new FormData()
      if (form.slug === 'transfer') {
        formData.append('bankID', form.bankID)
        formData.append('accountName', form.accountName)
        formData.append('accountNo', form.accountNo)
        formData.append('branchName', form.branchName)
      } else {
        formData.append('accountName', form.accountName)
        formData.append('accountNo', form.accountNo)
      }
      const res = await REQUEST_PUT(
        `/api/shops/${data.selectedShop}/payment_methods/${form.slug}/details/${form.id}`,
        formData
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          const accountBanks = cloneDeep(state.accountBanks.data)
          const index = findIndex(accountBanks, { id: res?.data?.id })

          accountBanks[index] = res.data
          state.accountBanks = {
            ...state.accountBanks,
            data: accountBanks,
          }
          // Alert
          alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.ERROR, 'error', 'error')
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async GET_REGIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/shop-regions`)
      state.regions = {
        data: res.data?.data,
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async DELETE_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(
        `/api/shops/${data.selectedShop}/payment_methods/details/${data.form?.id}`
      )

      const accountBanks = cloneDeep(state.accountBanks.data)
      const index = findIndex(accountBanks, { id: data.form?.id })

      accountBanks.splice(index, 1)
      state.accountBanks = {
        ...state.accountBanks,
        data: accountBanks,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getBuyers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_PARTNER_BUYERS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getShopTypes(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOP_TYPES(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getAdminBuyers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_BUYERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getCouponBuyers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COUPON_BUYERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getAdminSellers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SELLERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getCouponSellers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COUPON_SELLERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getPaymentMethods(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_PAYMENT_METHODS(state, {
      toggleLoading,
      selectedShop,
      toggleAlert,
    })
  },
  getBankAccounts(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ACCOUNT_BANKS(state, {
      toggleLoading,
      selectedShop,
      toggleAlert,
    })
  },
  getNotification(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_NOTIFICATION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getBanks(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_BANKS(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  getPaymentTypes(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_PAYMENT_TYPES(state, {
      toggleLoading,
      selectedShop,
      toggleAlert,
    })
  },
  createBankAccount(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.CREATE_BANK(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  updatePaymentMethods(context, paramId) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.UPDATE_PAYMENT_METHODS(state, {
      toggleLoading,
      selectedShop,
      paramId,
      toggleAlert,
    })
  },
  updatePaymentTypes(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.UPDATE_PAYMENT_TYPES(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  updateBuyers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.UPDATE_PARTNER_BUYERS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  editBankAccount(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EDIT_BANK(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getRegions(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_REGIONS(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  deleteBankAccounts(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.DELETE_PRODUCT(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  accountBanksList(state) {
    // eslint-disable-line
    return state.accountBanks
  },
  notificationList(state) {
    // eslint-disable-line
    return state.notifitions
  },
  banksList(state) {
    // eslint-disable-line
    return state.banks
  },
  buyersList(state) {
    // eslint-disable-line
    return state.buyers
  },
  sellersList(state) {
    // eslint-disable-line
    return state.sellers
  },
  paymentMethodsList(state) {
    // eslint-disable-line
    return state.paymentMethods
  },
  paymentTypeList(state) {
    // eslint-disable-line
    return state.paymentTypes
  },
  regionList(state) {
    // eslint-disable-line
    return state.regions
  },
  shopTypeList(state) {
    // eslint-disable-line
    return state.shopTypes
  },
}

export const shops = {
  state,
  mutations,
  actions,
  getters,
}
