import { cloneDeep, findIndex } from 'lodash'
import { REQUEST_POST, REQUEST_DELETE } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  productPhotos: {
    data: [],
    total: 0,
  },
  premiumProductPhotos: {
    data: [],
    totle: 0,
  },
}

export const mutations = {
  async UPLOAD_PRODUCT_PHOTO(state, data) {
    data.toggleLoading()
    try {
      var formData = new FormData()
      const len = data?.form?.photos?.length || 0
      for (let i = 0; i < len; i++) {
        formData.append('photos', data.form.photos[i])
      }

      const res = await REQUEST_POST(
        `/api/products/${data.form.id}/photos`,
        formData
      )
      state.productPhotos.data.push(res.data)
      state.productPhotos.total++
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPLOAD_PREMIUM_PRODUCT_PHOTO(state, data) {
    data.toggleLoading()
    try {
      var formData = new FormData()
      const len = data?.form?.photos?.length || 0
      for (let i = 0; i < len; i++) {
        formData.append('photos', data.form.photos[i])
      }

      const res = await REQUEST_POST(
        `/api/premium-products/${data.form.id}/images`,
        formData
      )
      state.premiumProductPhotos.data.push(res.data)
      state.premiumProductPhotos.total++
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async REMOVE_PRODUCT_PHOTO(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(
        `/api/products/${data.form.productId}/photos/${data.form.id}`
      )

      const photos = cloneDeep(state.productPhotos.data)
      const index = findIndex(photos, { id: data.form?.id })

      photos.splice(index, 1)
      state.productPhotos = {
        ...state.productPhotos,
        data: photos,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      console.log(e)
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      throw Error(error)
    }
  },
  async REMOVE_PREMIUM_PRODUCT_PHOTO(state, data) {
    data.toggleLoading()
    try {
      const formData = { ids: [data.form.id] }
      await REQUEST_DELETE(
        `/api/premium-products/${data.form.premiumProductId}/images`,
        formData
      )

      const photos = cloneDeep(state.premiumProductPhotos.data)
      const index = findIndex(photos, { id: data.form?.id })

      photos.splice(index, 1)
      state.premiumProductPhotos = {
        ...state.premiumProductPhotos,
        data: photos,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      console.log(e)
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      throw Error(error)
    }
  },
}

export const actions = {
  uploadProductPhoto(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPLOAD_PRODUCT_PHOTO(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  uploadPremiumProductPhoto(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPLOAD_PREMIUM_PRODUCT_PHOTO(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  removeProductPhoto(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.REMOVE_PRODUCT_PHOTO(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  removePremiumProductPhoto(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.REMOVE_PREMIUM_PRODUCT_PHOTO(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  productPhotos(state) {
    // eslint-disable-line
    return state.productPhotos
  },
  premiumProductPhotos(state) {
    // eslint-disable-line
    return state.premiumProductPhotos
  },
}

export const productPhotos = {
  state,
  mutations,
  actions,
  getters,
}
