import { cloneDeep, findIndex } from 'lodash'
import { REQUEST_GET, REQUEST_POST, REQUEST_PUT, REQUEST_DELETE } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  producttypes: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_PRODUCTTYPES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/product-types', data.form)
      state.producttypes.data = res?.data?.data || []
      state.producttypes.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_PRODUCTTYPE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/cms/product-types', data.form)
      state.producttypes.data.push(res.data)
      state.producttypes.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PRODUCTTYPE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/cms/product-types/${data.form?.id}`, data.form)

      const producttypes = cloneDeep(state.producttypes.data)
      const index = findIndex(producttypes, { id: res?.data?.id })

      producttypes[index] = res.data
      state.producttypes = {
        ...state.producttypes,
        data: producttypes,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_PRODUCTTYPE(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/cms/product-types/${data.form?.id}`)

      const producttypes = cloneDeep(state.producttypes.data)
      const index = findIndex(producttypes, { id: data.form?.id })

      producttypes.splice(index, 1)
      state.producttypes = {
        ...state.producttypes,
        data: producttypes,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getProductTypes(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('GET_PRODUCTTYPES', { toggleLoading, form, toggleAlert })
  },
  createProductType(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_PRODUCTTYPE', { toggleLoading, form, toggleAlert })
  },
  editProductType(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_PRODUCTTYPE', { toggleLoading, form, toggleAlert })
  },
  deleteProductType(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('DELETE_PRODUCTTYPE', { toggleLoading, form, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  producttypes(state) { // eslint-disable-line
    return state.producttypes
  },
}

export const producttypes = {
  state,
  mutations,
  actions,
  getters,
}
