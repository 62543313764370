<template>
  <div class="promotion-detail-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <CustomModal
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :modal="modal"
      @confirm="clearModal"
      disableBottomAction
      disableCancel
      confirmIcon
    >
      <template v-slot:actionHeader>
        <h3 class="text-black">รายละเอียดโปรโมชั่น</h3>
      </template>
      <template v-slot:body>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title>ข้อมูลโปรโมชั่น</v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <b>ชื่อโปรโมชั่น*</b>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  label="ชื่อโปรโมชั่น"
                  v-model="formData.name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <b>วันที่เริ่มต้น*</b>
              </v-col>
              <v-col cols="12" md="10">
                <DatePicker
                  :text-field-props="textFieldeProps"
                  :date-picker-props="colorProps"
                  :time-picker-props="colorProps"
                  :tab-props="colorProps"
                  timeFormat="HH:mm:ss"
                  placeholder="วันเริ่มต้น"
                  v-model="formData.startTime"
                  @input="updateInput"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <b>วันที่สิ้นสุด*</b>
              </v-col>
              <v-col cols="12" md="10">
                <DatePicker
                  :text-field-props="textFieldeProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="colorProps"
                  :tab-props="colorProps"
                  timeFormat="HH:mm:ss"
                  placeholder="วันที่สิ้นสุด"
                  v-model="formData.endTime"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <b>สถานะการใช้งานโปรโมชั่น*</b>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  label="สถานะการใช้งานโปรโมชั่น"
                  :items="activeList"
                  v-model="formData.active"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title>
            เงื่อนไขโปรโมชั่น
            <div class="ml-auto">
              <v-btn color="success" @click="conditionModalConfirmAction"
                >เพิ่มเงื่อนไขโปรโมชั่น</v-btn
              >
            </div>
          </v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-data-table
              :loading="isLoading"
              :headers="conditionHeaders"
              :items="conditionList"
              :server-items-length="conditionList.length"
              hide-default-footer
              no-data-text="ไม่พบข้อมูล"
              no-results-text="ไม่พบข้อมูล"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.type === 'product' || item.type === 'brand'"
                  :src="item.image"
                  contain
                  width="100"
                  height="100"
                  @click="
                    item.imageArray.length > 0
                      ? showImg(item.imageArray)
                      : () => {}
                  "
                />
                <div v-else>-</div>
              </template>
              <template v-slot:item.type="{ item }">
                <div v-if="item.type === 'product'">สินค้า</div>
                <div v-else-if="item.type === 'brand'">แบรนด์</div>
                <div v-else>หมวดหมู่</div>
              </template>
              <template v-slot:item.unit="{ item }">
                <div v-if="item.unit === 'total'">ยอดรวม</div>
                <div v-else>จำนวน</div>
              </template>
              <template v-slot:item.volume="{ item }">
                {{ $numberWithCommas(item.volume) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="elevation-5 rounded-xl">
          <v-card-title>
            ประโยชน์ที่ได้รับ
            <div class="ml-auto">
              <v-btn color="success" @click="benefitModalConfirmAction"
                >เพิ่มประโยชน์ที่ได้รับ</v-btn
              >
            </div>
          </v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-data-table
              :loading="isLoading"
              :headers="benefitHeaders"
              :items="benefitList"
              :server-items-length="benefitList.length"
              hide-default-footer
              no-data-text="ไม่พบข้อมูล"
              no-results-text="ไม่พบข้อมูล"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.type === 'product'"
                  :src="item.image"
                  contain
                  width="100"
                  height="100"
                  @click="
                    item.imageArray.length > 0
                      ? showImg(item.imageArray)
                      : () => {}
                  "
                />
                <div v-else>-</div>
              </template>
              <template v-slot:item.type="{ item }">
                <div v-if="item.type === 'product'">สินค้า</div>
                <div v-else>ส่วนลดเงินสด</div>
              </template>
              <template v-slot:item.volume="{ item }">
                {{ $numberWithCommas(item.volume) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-divider class="mx-3" />
        <div class="text-right mt-3">
          <v-btn class="mr-2" color="grey" text @click="clearModal"
            >ปิดหน้าต่าง</v-btn
          >
          <v-btn color="success" @click="submitForm" :disabled="checkConfirm"
            >บันทึก</v-btn
          >
        </div>
      </template>
    </CustomModal>
    <!--------------------- Condition Modal ------------------------>
    <CustomModal
      :modal="conditionModal"
      @confirm="conditionModalConfirmAction"
      :disabled="checkConditionConfirm"
      :width="1080"
    >
      <template v-slot:body>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title
            >เงื่อนไขโปรโมชั่น
            <div class="ml-auto">
              <v-btn color="success" @click="addConditionModalConfirmAction"
                >เพิ่มเงื่อนไข</v-btn
              >
            </div>
          </v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-data-table
              :loading="isLoading"
              :headers="conditionHeaders"
              :items="displayConditionList"
              :server-items-length="displayConditionList.length"
              hide-default-footer
              no-data-text="ไม่พบข้อมูล"
              no-results-text="ไม่พบข้อมูล"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.type === 'product' || item.type === 'brand'"
                  :src="item.image"
                  contain
                  width="100"
                  height="100"
                  @click="
                    item.imageArray.length > 0
                      ? showImg(item.imageArray)
                      : () => {}
                  "
                />
                <div v-else>-</div>
              </template>
              <template v-slot:item.type="{ item }">
                <div v-if="item.type === 'product'">สินค้า</div>
                <div v-else-if="item.type === 'brand'">แบรนด์</div>
                <div v-else>หมวดหมู่</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="error" icon @click="removeCondition(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.unit="{ item }">
                <v-select
                  v-if="item.type === 'category' || item.type === 'brand'"
                  label="ประเภทเป้าหมาย"
                  :items="unitList"
                  v-model="item.unit"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                />
                <v-select
                  v-if="item.type === 'product'"
                  label="ประเภทเป้าหมาย"
                  :items="unitProductList"
                  v-model="item.unit"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  :rules="[rules.required]"
                />
              </template>
              <template v-slot:item.volume="{ item }">
                <v-text-field
                  class="target-input"
                  label="จำนวนเป้าหมาย"
                  v-model="item.volume"
                  @keypress="validateNumeric"
                  dense
                  outlined
                  hide-details
                  :rules="[rules.required, rules.min]"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>
    </CustomModal>
    <CustomModal
      :modal="addConditionModal"
      @confirm="addConditionModalConfirmAction"
      :width="1280"
      disableConfirm
    >
      <template v-slot:body>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title>เพิ่มเงื่อนไข</v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-tabs v-model="tabs" color="success" fixed-tabs>
              <v-tab v-for="tabItem in tabItems" :key="tabItem.tab">{{
                tabItem.text
              }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="tabItem in tabItems" :key="tabItem.tab">
                <v-card class="pt-5">
                  <v-card-text>
                    <div v-if="tabItem.tab === 'category'">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="isLoading"
                            v-model="keyword"
                            label="ค้นหาจาก"
                            single-line
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn
                            outlined
                            depressed
                            @click="searchKeyword(tabItem.tab)"
                            color="#009b3f"
                            >ค้นหา</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-data-table
                        :loading="isLoading"
                        :headers="categoryHeaders"
                        :items="categoryList"
                        :server-items-length="categories && categories.total"
                        hide-default-footer
                        no-data-text="ไม่พบข้อมูล"
                        no-results-text="ไม่พบข้อมูล"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            depressed
                            text
                            color="success"
                            @click="selectItem(item, tabItem.tab)"
                            >เพิ่ม</v-btn
                          >
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="tabItem.tab === 'brand'">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="isLoading"
                            v-model="keyword"
                            label="ค้นหาจาก"
                            single-line
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn
                            outlined
                            depressed
                            @click="searchKeyword(tabItem.tab)"
                            color="#009b3f"
                            >ค้นหา</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-data-table
                        :loading="isLoading"
                        :headers="brandHeaders"
                        :items="brandList"
                        :server-items-length="brands && brands.total"
                        hide-default-footer
                        no-data-text="ไม่พบข้อมูล"
                        no-results-text="ไม่พบข้อมูล"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            depressed
                            text
                            color="success"
                            @click="selectItem(item, tabItem.tab)"
                            >เพิ่ม</v-btn
                          >
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="tabItem.tab === 'product'">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="isLoading"
                            v-model="keyword"
                            label="ค้นหาจาก"
                            single-line
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-combobox
                            v-model="category"
                            :items="categoryItems"
                            label="หมวดหมู่"
                            outlined
                            single-line
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn
                            outlined
                            depressed
                            @click="searchKeyword(tabItem.tab)"
                            color="#009b3f"
                            >ค้นหา</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-data-table
                        :loading="isLoading"
                        :headers="productHeaders"
                        :items="productList"
                        :server-items-length="products && products.total"
                        hide-default-footer
                        no-data-text="ไม่พบข้อมูล"
                        no-results-text="ไม่พบข้อมูล"
                      >
                        <template v-slot:item.productPhotosUrl="{ item }">
                          <v-img
                            :src="item.productPhotosUrl"
                            contain
                            width="100"
                            height="100"
                            @click="
                              item.productPhotosArray.length > 0
                                ? showImg(item.productPhotosArray)
                                : () => {}
                            "
                          />
                        </template>
                        <template v-slot:item.status="{ item }">
                          <StatusChip
                            v-if="item.status === 'อนุมัติ'"
                            color="#d9ead3ff"
                            text-color="#009b3f"
                            chipText="อนุมัติ"
                          />
                          <StatusChip
                            v-else-if="item.status === 'รออนุมัติ'"
                            color="#fff2cd"
                            text-color="#f67b00"
                            chipText="รออนุมัติ"
                          />
                          <StatusChip
                            v-else-if="
                              item.status !== 'รออนุมัติ' &&
                              item.status !== 'อนุมัติ'
                            "
                            color="#f4cbcc"
                            text-color="#da291c"
                            :chipText="item.status"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            depressed
                            text
                            color="success"
                            @click="selectItem(item, tabItem.tab)"
                            >เพิ่ม</v-btn
                          >
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </template>
    </CustomModal>
    <!--------------------- Benefit Modal ------------------------>
    <CustomModal
      :modal="benefitModal"
      @confirm="benefitModalConfirmAction"
      :disabled="checkBenefitConfirm"
      :width="1080"
    >
      <template v-slot:body>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title
            >ประโยชน์ที่ได้รับ
            <div class="ml-auto">
              <v-btn color="success" @click="addBenefitModalConfirmAction"
                >เพิ่มประโยชน์ที่ได้รับ</v-btn
              >
            </div>
          </v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-data-table
              :loading="isLoading"
              :headers="benefitHeaders"
              :items="displayBenefitList"
              :server-items-length="displayBenefitList.length"
              hide-default-footer
              no-data-text="ไม่พบข้อมูล"
              no-results-text="ไม่พบข้อมูล"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.type === 'product'"
                  :src="item.image"
                  contain
                  width="100"
                  height="100"
                  @click="
                    item.imageArray.length > 0
                      ? showImg(item.imageArray)
                      : () => {}
                  "
                />
                <div v-else>-</div>
              </template>
              <template v-slot:item.type="{ item }">
                <div v-if="item.type === 'product'">สินค้า</div>
                <div v-else>ส่วนลดเงินสด</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="error" icon @click="removeBenefit(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.volume="{ item }">
                <v-text-field
                  class="target-input"
                  label="จำนวนที่ได้รับ"
                  v-model="item.volume"
                  @keypress="validateNumeric"
                  dense
                  outlined
                  hide-details
                  :rules="[rules.required, rules.min]"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>
    </CustomModal>
    <CustomModal
      :modal="addBenefitModal"
      @confirm="addBenefitModalConfirmAction"
      :width="1280"
      disableConfirm
    >
      <template v-slot:body>
        <v-card class="elevation-5 rounded-xl mb-3">
          <v-card-title>เพิ่มประโยชน์ที่ได้รับ</v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-tabs v-model="benefitTabs" color="success" fixed-tabs>
              <v-tab v-for="tabItem in benefitTabItems" :key="tabItem.tab">{{
                tabItem.text
              }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="benefitTabs">
              <v-tab-item v-for="tabItem in benefitTabItems" :key="tabItem.tab">
                <v-card class="pt-5">
                  <v-card-text>
                    <div v-if="tabItem.tab === 'cash'">
                      <v-data-table
                        :loading="isLoading"
                        :headers="saleHeaders"
                        :items="saleList"
                        :server-items-length="saleList.length"
                        hide-default-footer
                        no-data-text="ไม่พบข้อมูล"
                        no-results-text="ไม่พบข้อมูล"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            depressed
                            text
                            color="success"
                            @click="selectItem(item, tabItem.tab)"
                            >เพิ่ม</v-btn
                          >
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="tabItem.tab === 'premiumProduct'">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :disabled="isLoading"
                            v-model="keyword"
                            label="ค้นหาจาก"
                            single-line
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-combobox
                            v-model="category"
                            :items="categoryItems"
                            label="หมวดหมู่"
                            outlined
                            single-line
                            dense
                            hide-details
                          />
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn
                            outlined
                            depressed
                            @click="searchKeyword(tabItem.tab)"
                            color="#009b3f"
                            >ค้นหา</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-data-table
                        :loading="isLoading"
                        :headers="productHeaders"
                        :items="productList"
                        :server-items-length="products && products.total"
                        hide-default-footer
                        no-data-text="ไม่พบข้อมูล"
                        no-results-text="ไม่พบข้อมูล"
                      >
                        <template v-slot:item.productPhotosUrl="{ item }">
                          <v-img
                            :src="item.productPhotosUrl"
                            contain
                            width="100"
                            height="100"
                            @click="
                              item.productPhotosArray.length > 0
                                ? showImg(item.productPhotosArray)
                                : () => {}
                            "
                          />
                        </template>
                        <template v-slot:item.status="{ item }">
                          <StatusChip
                            v-if="item.status === 'อนุมัติ'"
                            color="#d9ead3ff"
                            text-color="#009b3f"
                            chipText="อนุมัติ"
                          />
                          <StatusChip
                            v-else-if="item.status === 'รออนุมัติ'"
                            color="#fff2cd"
                            text-color="#f67b00"
                            chipText="รออนุมัติ"
                          />
                          <StatusChip
                            v-else-if="
                              item.status !== 'รออนุมัติ' &&
                              item.status !== 'อนุมัติ'
                            "
                            color="#f4cbcc"
                            text-color="#da291c"
                            :chipText="item.status"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            depressed
                            text
                            color="success"
                            @click="selectItem(item, tabItem.tab)"
                            >เพิ่ม</v-btn
                          >
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { parse } from "date-fns";
import moment from "moment";
import { cloneDeep, findIndex, forEach, reduce, size } from "lodash";
import Utils from "@/mixin";

import DatePicker from "@/components/DatePicker";
import CustomModal from "@/components/Modal";
import LightBox from "@/components/LightBox.vue";
import StatusChip from "@/components/StatusChip";

export default {
  components: {
    DatePicker,
    CustomModal,
    LightBox,
    StatusChip,
  },
  computed: {
    displayConditionList() {
      const { formData } = this;
      return formData.displayConditions || [];
    },
    conditionList() {
      const { formData } = this;
      return formData.conditions || [];
    },
    displayBenefitList() {
      const { formData } = this;
      return formData.displayPremiums || [];
    },
    benefitList() {
      const { formData } = this;
      return formData.premiums || [];
    },
    categoryList() {
      const { categories } = this;
      return categories.data || [];
    },
    brandList() {
      const { brands } = this;
      return brands.data || [];
    },
    categoryItems() {
      const { categories } = this;
      const result = reduce(
        categories.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    productList() {
      const { products } = this;
      const result = reduce(
        products.data,
        (o, e) => {
          let productPhotosArray = [];
          let categoriesName = "";
          let count = 1;
          forEach(e.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          forEach(e.categories, (i) => {
            categoriesName += i.name;
            if (count < size(e.categories)) {
              categoriesName += ", ";
              count++;
            }
          });
          o.push({
            ...e,
            productPhotosArray,
            productPhotosUrl: e.productPhotos?.[0]?.url || "",
            categoriesName,
          });
          return o;
        },
        []
      );
      return result;
    },
    saleList() {
      return [{ name: "ส่วนลดเงินสด" }];
    },
    checkConditionConfirm() {
      const { displayConditions } = this.formData;
      let result = false;
      forEach(displayConditions, (i) => {
        if (i.unit === "" || parseInt(i.volume) === 0 || i.volume === "")
          result = true;
      });
      return result;
    },
    checkBenefitConfirm() {
      const { displayPremiums } = this.formData;
      let result = false;
      forEach(displayPremiums, (i) => {
        if (i.unit === "" || parseInt(i.volume) === 0 || i.volume === "")
          result = true;
      });
      return result;
    },
    checkConfirm() {
      const { name, startTime, endTime } = this.formData;
      if (name === "" || startTime === undefined || endTime === undefined) {
        return true;
      }
      return false;
    },
    dateProps() {
      const { startTime } = this.formData;
      const min = moment(startTime)
        // .add(1, "day")
        .format("YYYY-MM-DD");
      return { color: "success", min };
    },
  },
  created() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    total: 0,
    unitList: [{ text: "ยอดรวม", value: "total" }],
    unitProductList: [
      { text: "จำนวน", value: "qty" },
      { text: "ยอดรวม", value: "total" },
    ],
    activeList: [
      { text: "เปิดใช้งานแล้ว", value: true },
      { text: "ยังไม่เปิดใช้งาน", value: false },
    ],
    conditionHeaders: [
      { text: "ภาพสินค้า", value: "image", sortable: false, align: "center" },
      { text: "ประเภทเงื่อนไข", value: "type", sortable: false },
      { text: "รายละเอียดเงื่อนไข", value: "name", sortable: false },
      { text: "", value: "actions", sortable: false },
      { text: "ประเภทเป้าหมาย", value: "unit", sortable: false },
      {
        text: "จำนวนเป้าหมาย",
        value: "volume",
        align: "right",
        sortable: false,
      },
    ],
    benefitHeaders: [
      { text: "ภาพสินค้า", value: "image", sortable: false },
      { text: "ประเภทสิทธิประโยชน์", value: "type", sortable: false },
      {
        text: "รายละเอียดสิทธิประโยชน์",
        value: "name",
        sortable: false,
      },
      { text: "", value: "actions", sortable: false },
      { text: "หน่วย", value: "unit", sortable: false },
      {
        text: "จำนวนที่ได้รับ",
        value: "volume",
        align: "right",
        sortable: false,
      },
    ],
    categoryHeaders: [
      { text: "รหัสหมวดหมู่", value: "code", sortable: false },
      { text: "ชื่อหมวดหมู่", value: "name", sortable: false },
      { text: "ดำเนินการ", value: "actions", sortable: false, width: "180px" },
    ],
    brandHeaders: [
      { text: "รหัสแบรนด์", value: "slug", sortable: false },
      { text: "ชื่อแบรนด์", value: "name", sortable: false },
      { text: "ดำเนินการ", value: "actions", sortable: false, width: "180px" },
    ],
    productHeaders: [
      { text: "ภาพสินค้า", value: "productPhotosUrl", sortable: false },
      { text: "ชื่อสินค้า", value: "name", sortable: false, width: "200px" },
      {
        text: "รหัสสินค้า",
        value: "sku",
        sortable: false,
      },
      {
        text: "หมวดหมู่",
        value: "categoriesName",
        sortable: false,
        width: "200px",
      },
      {
        text: "ราคา",
        value: "price",
        align: "center",
        sortable: false,
      },
      {
        text: "สถานะการขาย",
        value: "status",
        sortable: false,
      },
      { text: "ดำเนินการ", value: "actions", sortable: false, width: "180px" },
    ],
    saleHeaders: [
      { text: "หัวข้อ", value: "name", sortable: false },
      { text: "ดำเนินการ", value: "actions", sortable: false, width: "180px" },
    ],
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
      min: (value) => value > 0 || "กรุณากรอกมากกว่า 0",
    },
    initialForm: {
      name: "",
      startTime: undefined,
      endTime: undefined,
      active: true,
      displayConditions: [],
      conditions: [],
      displayPremiums: [],
      premiums: [],
    },
    formData: {
      name: "",
      startTime: undefined,
      endTime: undefined,
      active: true,
      displayConditions: [],
      conditions: [],
      displayPremiums: [],
      premiums: [],
    },
    conditionModal: false,
    addConditionModal: false,
    tabs: null,
    tabItems: [
      { text: "หมวดหมู่", tab: "category" },
      { text: "แบรนด์", tab: "brand" },
      { text: "สินค้า", tab: "product" },
    ],
    benefitTabs: null,
    benefitTabItems: [
      { text: "ส่วนลดเงินสด", tab: "cash" },
      { text: "สินค้า", tab: "premiumProduct" },
    ],
    keyword: "",
    category: "",
    benefitModal: false,
    addBenefitModal: false,
    textFieldeProps: {
      outlined: true,
      dense: true,
      hideDetails: true,
    },
    colorProps: {
      color: "success",
    },
  }),
  methods: {
    updateInput() {
      this.formData.endTime = undefined;
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async getInitialData() {
      const { selectedShop } = this;
      let condition = {
        shopId: selectedShop,
      };
      await this.getBrands(condition);
      await this.getCategories(condition);
      await this.getProducts(condition);
    },
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async searchKeyword(tab) {
      const { selectedShop, categories } = this;
      let condition = {
        shopId: selectedShop,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.category) {
        let categoryIds = 0;
        forEach(categories.data, (e) => {
          if (e.name === this.category) {
            categoryIds = e.id;
          }
        });
        condition.categoryIds = categoryIds;
      }

      switch (tab) {
        case "category": {
          await this.getCategories(condition);
          break;
        }
        case "brand": {
          await this.getBrands(condition);
          break;
        }
        case "product":
        case "premiumProduct": {
          await this.getProducts(condition);
          break;
        }
        default:
          break;
      }
    },
    conditionModalConfirmAction(value = false) {
      const cloneDisplayCondition =
        cloneDeep(this.formData.displayConditions) || [];
      const cloneCondition = cloneDeep(this.formData.conditions) || [];
      if (value) {
        this.formData.conditions = cloneDisplayCondition;
      } else {
        this.formData.conditions = cloneCondition;
        this.formData.displayConditions = cloneCondition;
      }
      this.conditionModal = !this.conditionModal;
    },
    addConditionModalConfirmAction() {
      this.addConditionModal = !this.addConditionModal;
    },
    benefitModalConfirmAction(value = false) {
      const cloneDisplayPremiums =
        cloneDeep(this.formData.displayPremiums) || [];
      const clonePremiums = cloneDeep(this.formData.premiums) || [];
      if (value) {
        this.formData.premiums = cloneDisplayPremiums;
      } else {
        this.formData.premiums = clonePremiums;
        this.formData.displayPremiums = clonePremiums;
      }
      this.benefitModal = !this.benefitModal;
    },
    addBenefitModalConfirmAction() {
      this.addBenefitModal = !this.addBenefitModal;
    },
    removeCondition(item) {
      const index = findIndex(
        this.formData.displayConditions,
        (i) => i.refId === item.refId && i.type === item.type
      );
      this.formData.displayConditions.splice(index, 1);
    },
    removeBenefit(item) {
      const index = findIndex(
        this.formData.displayPremiums,
        (i) => i.refId === item.refId && i.type === item.type
      );
      this.formData.displayPremiums.splice(index, 1);
    },
    selectItem(item, tab) {
      if (tab === "category" || tab === "brand" || tab === "product") {
        this.formData.displayConditions.push({
          type: tab,
          refId: (item.id && item.id.toString()) || "0",
          unit: tab === "product" ? "qty" : "total",
          volume: 1,
          image:
            item.logoImgUrl ||
            (item.productPhotos && item.productPhotos[0].url) ||
            "",
          name: item.name,
        });
      } else {
        this.formData.displayPremiums.push({
          type: tab === "premiumProduct" ? "product" : tab,
          refId: (item.id && item.id.toString()) || "0",
          unit: tab === "premiumProduct" ? item.unit : "จำนวนเงิน",
          volume: 1,
          image:
            item.logoImgUrl ||
            (item.productPhotos && item.productPhotos[0].url) ||
            "",
          name: item.name,
        });
      }
      this.addConditionModal = false;
      this.addBenefitModal = false;
    },
    clearModal() {
      this.$nextTick(() => {
        const cloneInit = cloneDeep(this.initialForm);
        this.formData = Object.assign({}, cloneInit);
        if (size(this.$refs.form) > 0) {
          forEach(this.$refs.form, (i) => {
            i.resetValidation();
          });
        }
        this.disabled = "";
        this.tabs = null;
        this.benefitTabs = null;
      });
      this.clearData();
    },
    async submitForm() {
      const { formData } = this;

      this.$emit("submit", { ...formData });
      this.clearModal();
    },
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    create: {
      type: Boolean,
    },
    modal: {
      type: Boolean,
    },
    clearData: {
      type: Function,
    },
  },
  mixins: [Utils],
  watch: {
    editId(nextProps, prevProps) {
      if (prevProps !== nextProps && nextProps !== "") {
        const { promotions } = this;
        const clonePromotions = cloneDeep(promotions);
        const index = findIndex(
          clonePromotions?.data || [],
          (i) => i.id === this.editId
        );
        if (index !== -1) {
          const data = clonePromotions?.data?.[index] || {};
          const conditions = reduce(
            data.conditions,
            (o, e) => {
              o.push({
                ...e,
                image:
                  (e.jsonData && e.jsonData.logoImgUrl) ||
                  (e.jsonData &&
                    e.jsonData.productPhotos &&
                    e.jsonData.productPhotos[0].url) ||
                  "",
              });
              return o;
            },
            []
          );
          const premiums = reduce(
            data.premiums,
            (o, e) => {
              o.push({
                ...e,
                image:
                  (e.jsonData && e.jsonData.logoImgUrl) ||
                  (e.jsonData &&
                    e.jsonData.productPhotos &&
                    e.jsonData.productPhotos[0].url) ||
                  "",
                unit:
                  e.type === "product"
                    ? e.jsonData && e.jsonData.unit
                    : "จำนวนเงิน",
              });
              return o;
            },
            []
          );
          const DEFAULT_MOMENT_FORMAT = "YYYY-MM-DD HH:mm:ss";
          const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
          const DEFAULT_TIME_FORMAT = "HH:mm:ss";
          const startTime = moment(data.startTime).format(
            DEFAULT_MOMENT_FORMAT
          );
          const endTime = moment(data.endTime).format(DEFAULT_MOMENT_FORMAT);
          this.formData = {
            name: data.name,
            description: data.description,
            startTime: parse(
              startTime,
              `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
              new Date()
            ),
            endTime: parse(
              endTime,
              `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
              new Date()
            ),
            active: data.active,
            conditions,
            displayConditions: conditions,
            premiums,
            displayPremiums: premiums,
          };
        }
      }
    },
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
};
</script>

<style lang="scss">
.target-input {
  input {
    text-align: right;
  }
}
</style>