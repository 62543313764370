import Vue from 'vue'
import VueRouter from 'vue-router'
import { get, includes } from 'lodash'

import { getters } from '../store/Auth'

import { PERMISSION } from '../plugins/auth'

import Home from '../views/Auth/Home.vue'
import DashboardCoupon from '../views/Auth/DashboardCoupon.vue'
import DashboardRetail from '../views/Auth/DashboardRetail.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Register from '../views/Register.vue'
import Order from '../views/Auth/Order.vue'

import Account from '../views/Auth/Admin/Account.vue'
import Setting from '../views/Auth/Admin/Setting.vue'
import Pdpa from '../views/Auth/Admin/Pdpa.vue'
import AdminBrand from '../views/Auth/Admin/Brand.vue'
import AdminCategory from '../views/Auth/Admin/Category.vue'
import AdminProductType from '../views/Auth/Admin/ProductType.vue'
import AdminProduct from '../views/Auth/Admin/Product.vue'
import AdminUserProduct from '../views/Auth/Admin/UserProduct.vue'
import ManagePermssion from '../views/Auth/Admin/Permission.vue'
import AdminPremiumProduct from '../views/Auth/Admin/PremiumProduct.vue'
import AdminCoupon from '../views/Auth/Admin/Coupon.vue'
import AdminPointSetting from '../views/Auth/Admin/PointSetting.vue'
import AdminCouponReport from '../views/Auth/Admin/CouponReport.vue'

import UserProduct from '../views/Auth/User/Product.vue'
import PaymentSetting from '../views/Auth/User/Setting/Payment.vue'
import BankingSetting from '../views/Auth/User/Setting/Banking.vue'
import PromotionSetting from '../views/Auth/User/Setting/Promotion.vue'
import Notification from '../views/Auth/User/Notification.vue'
import CouponReport from '../views/Auth/User/Setting/CouponReport.vue'

import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'authLayout',
    },
    component: Home,
  },
  {
    path: '/dashboard-coupon',
    name: 'DashboardCoupon',
    meta: {
      layout: 'authLayout',
    },
    component: DashboardCoupon,
  },
  {
    path: '/dashboard-retail',
    name: 'DashboardRetail',
    meta: {
      layout: 'authLayout',
    },
    component: DashboardRetail,
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'homeLayout',
    },
    component: Login,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'homeLayout',
    },
    component: ResetPassword,
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      layout: 'homeLayout',
    },
    component: Register,
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      layout: 'authLayout',
      permission: [PERMISSION.APP_ADMIN, PERMISSION.SHOP_ADMIN],
    },
    component: Account,
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      layout: 'authLayout',
      permission: [PERMISSION.APP_ADMIN, PERMISSION.SHOP_ADMIN],
    },
    component: Setting,
  },
  {
    path: '/pdpa',
    name: 'Pdpa',
    meta: {
      layout: 'authLayout',
      permission: [PERMISSION.APP_ADMIN, PERMISSION.SHOP_ADMIN],
    },
    component: Pdpa,
  },
  {
    path: '/order',
    name: 'Order',
    props: true,
    meta: {
      layout: 'authLayout',
      permission: [PERMISSION.APP_ADMIN, PERMISSION.SHOP_ADMIN],
    },
    component: Order,
  },
  {
    path: '/admin/brand',
    name: 'Admin-Brand',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminBrand,
  },
  {
    path: '/admin/category',
    name: 'Admin-Category',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminCategory,
  },
  {
    path: '/admin/product-type',
    name: 'Admin-Product-Type',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminProductType,
  },
  {
    path: '/admin/agent-product',
    name: 'Admin-AgentProduct',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminUserProduct,
  },
  {
    path: '/admin/product',
    name: 'Admin-Product',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminProduct,
  },
  {
    path: '/manage-permission',
    name: 'ManagePermssion',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: ManagePermssion,
  },
  {
    path: '/admin/premium-product',
    name: 'Admin-PremiumProduct',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminPremiumProduct,
  },
  {
    path: '/admin/coupon',
    name: 'Admin-Coupon',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminCoupon,
  },
  {
    path: '/admin/setting/coupon-report',
    name: 'Admin-CounponReport',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminCouponReport,
  },
  {
    path: '/point-setting',
    name: 'Admin-PointSetting',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.APP_ADMIN,
    },
    component: AdminPointSetting,
  },
  {
    path: '/agent/product',
    name: 'Agent-Product',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: UserProduct,
  },
  {
    path: '/agent/setting/payment',
    name: 'Agent-PaymentSetting',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: PaymentSetting,
  },
  {
    path: '/agent/setting/banking',
    name: 'Agent-BankingSetting',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: BankingSetting,
  },
  {
    path: '/agent/setting/promotion',
    name: 'Agent-PromotionSetting',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: PromotionSetting,
  },
  {
    path: '/agent/notification',
    name: 'Agent-Notification',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: Notification,
  },
  {
    path: '/agent/setting/coupon-report',
    name: 'Agent-CounponReport',
    meta: {
      layout: 'authLayout',
      permission: PERMISSION.SHOP_ADMIN,
    },
    component: CouponReport,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: get(process, 'env.BASE_URL'),
  routes,
})

const ignorePath = (to, from) => {
  return (
    get(to, 'name') !== 'Home' &&
    get(from, 'name') !== 'Home' &&
    get(to, 'name') !== 'Login' &&
    get(from, 'name') !== 'Login' &&
    get(to, 'name') !== 'NotFound' &&
    get(from, 'name') !== 'NotFound'
  )
}

router.beforeEach((to, from, next) => {
  const { role } = getters
  if (role && get(to, 'path') !== get(from, 'path') && ignorePath(to, from)) {
    switch (role) {
      case PERMISSION.APP_ADMIN: {
        if (!includes(to.meta.permission, role)) {
          router.push({ name: 'Home' })
        }
        break
      }
      case PERMISSION.SHOP_ADMIN: {
        if (!includes(to.meta.permission, role)) {
          router.push({ name: 'Home' })
        }
        break
      }
      default:
        break
    }
  }
  next()
})

export default router
