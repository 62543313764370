<template>
  <div class="gallery-list-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <label
      v-if="label"
      class="v-label v-label--active theme--light mb-2"
      style="
        display: block;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
      "
      >{{ label }}</label
    >
    <div
      class="gallery-item-container"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="gallery-item-wrapper">
        <div class="upload-file-section">
          <v-icon
            v-if="removeable && !disabled"
            class="close-icon"
            color="error"
            @click="remove(item, index)"
            >mdi-close-circle-outline</v-icon
          >
          <img
            :src="itemToSrc(item)"
            width="100px"
            height="auto"
            @click="disabled || !editable ? showImg(item) : edit(item, index)"
          />
        </div>
        <div v-if="!disableText" class="text-center">
          ไฟล์ที่ {{ index + 1 }}
        </div>
      </div>
    </div>
    <div class="add-gallery-item-container">
      <v-btn
        v-if="max > items.length && !disabled"
        class="add-file-button"
        outlined
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <input
      ref="uploaderFile"
      class="d-none"
      type="file"
      :accept="accept"
      @change="setImage"
    />
    <CustomModal
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :modal="modal"
      @confirm="cropImage"
      disableBottomAction
    >
      <template v-slot:actionHeader>
        <v-btn text color="success" class="mr-2" @click.prevent="zoom(0.1)"
          >ซูมเข้า</v-btn
        >
        <v-btn text color="success" class="mr-2" @click.prevent="zoom(-0.1)"
          >ซูมออก</v-btn
        >
        <v-btn text color="success" @click.prevent="reset">รีเช็ต</v-btn>
        <v-checkbox
          label="ตัดรูปภาพทรงกลม"
          v-model="rounded"
          color="#009b3f"
          light
          hide-details
        />
      </template>
      <template v-slot:body>
        <div class="container">
          <div class="row">
            <div class="col">
              <div :class="`image-container${rounded ? ' rounded' : ''}`">
                <img id="cropper" :src="tmp" width="100%" height="auto" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </CustomModal>
    <Confirm v-model="conf.status" :data="conf" @confirm="removeObject" />
  </div>
</template>

<script>
import { forEach, size } from "lodash";
import moment from "moment";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

import CustomModal from "@/components/Modal";
import Confirm from "@/components/Confirm";
import LightBox from "@/components/LightBox.vue";

export default {
  components: {
    CustomModal,
    Confirm,
    LightBox,
  },
  props: {
    value: {
      type: [Array, String, File],
    },
    label: {
      type: String,
    },
    max: {
      type: Number,
    },
    accept: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    removeable: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const value = this.value;
      if (value) {
        if (Array.isArray(value)) {
          return value;
        } else {
          return [value];
        }
      }
      return [];
    },
    hasRemoveEvent() {
      return this.$listeners && this.$listeners.remove;
    },
  },
  data() {
    return {
      rounded: false,
      imgArr: [],
      imgShow: false,
      modal: false,
      tmp: null,
      conf: {
        status: false,
        title: "ยืนยันการลบไฟล์?",
        text: `ไฟล์`,
        cancelText: `ยกเลิก`,
        confirmText: `ยืนยัน`,
      },
      item: {},
      options: {
        aspectRatio: 1,
        movable: false,
        zoomable: true,
        rotatable: false,
        scalable: false,
      },
      cropper: null,
    };
  },
  methods: {
    getRoundedCanvas(sourceCanvas) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    },
    showImg(image) {
      const { value } = this;
      this.imgShow = true;
      let imageURL = image;
      if (typeof value !== "string" && size(value) > 1) {
        let result = [];
        if (image instanceof File) {
          forEach((value) => {
            result.push(URL.createObjectURL(value));
          });
        }
        if (typeof image === "string") {
          result = value;
        }
        this.imgArr = result;
      } else {
        if (image instanceof File) {
          imageURL = URL.createObjectURL(image);
        }
        if (typeof image === "string") {
          this.imgArr = [imageURL];
        } else {
          this.imgArr = imageURL;
        }
      }
    },
    update(item) {
      this.value[item?.index] = item?.file;
    },
    add() {
      this.$refs.uploaderFile.value = null;
      this.$refs.uploaderFile.click();
    },
    edit(item, index) {
      if (this.editable) {
        this.item = { type: "edit", data: item, index };

        this.$refs.uploaderFile.value = null;
        this.$refs.uploaderFile.click();
      }
    },
    remove(item, index) {
      this.conf = {
        ...this.conf,
        text: `ไฟล์ที่ ${index + 1}`,
        status: true,
        data: item,
        index: index,
      };
    },
    removeByIndex(arr = [], index = 0) {
      arr.splice(index, 1);
      return arr;
    },
    removeObject(value = false) {
      if (value) {
        const { data, index } = this.conf;

        if (this.hasRemoveEvent) {
          this.$emit("remove", data, index);
        } else {
          const value = this.value;
          if (Array.isArray(value)) {
            this.$emit("input", this.removeByIndex(value, index));
          } else {
            this.$emit("input", null);
          }
        }
      }

      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
        index: undefined,
      };
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file !== undefined) {
        this.modal = true;
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.tmp = event.target.result;
            const image = document.getElementById("cropper");
            this.cropper = new Cropper(image, this.options);
            image.src = event.target.result;
            this.cropper?.destroy();
            this.cropper = new Cropper(image, this.options);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
    async cropImage(crop = false) {
      if (crop) {
        const input = this.value;
        const file = await this.cropperToFile();

        if (Array.isArray(this.value)) {
          if (this.editable && this.item?.type === "edit") {
            input[this.item.index] = file;
          } else {
            input.push(file);
          }
          this.$emit("input", input);
        } else {
          this.$emit("input", file);
        }
      }
      this.modal = false;
      const image = document.getElementById("cropper");
      image.src = null;
      this.cropper?.destroy();
      this.cropper = new Cropper(image, this.options);
      this.rounded = false;
    },
    async cropperToFile() {
      const { rounded } = this;
      if (rounded) {
        let croppedCanvas;
        let roundedCanvas;

        // Crop
        croppedCanvas = this.cropper.getCroppedCanvas(this.option);

        // Round
        roundedCanvas = this.getRoundedCanvas(croppedCanvas);

        return new Promise((resolve) =>
          roundedCanvas?.toBlob((blob) =>
            resolve(new File([blob], `${moment().unix()}.jpg`))
          )
        );
      } else {
        return new Promise((resolve) =>
          this.cropper
            ?.getCroppedCanvas(this.option)
            ?.toBlob((blob) =>
              resolve(new File([blob], `${moment().unix()}.jpg`))
            )
        );
      }
    },
    itemToSrc(item) {
      if (item instanceof File) {
        const imageURL = URL.createObjectURL(item);
        return imageURL;
      }
      return item;
    },
    zoom(percent) {
      this.cropper?.zoom(percent);
    },
    reset() {
      this.cropper?.reset();
    },
  },
};
</script>

<style lang="scss">
$light-gray: #cccccc;
$gray: #b0b0b0;
$background-button: #f3f3f3;
$black: black;

.image-container {
  max-height: 497px;
  min-height: 200px;
  margin: auto;
  &.rounded {
    .cropper-view-box,
    .cropper-face {
      border-radius: 50%;
    }
  }
}

@media (min-width: 768px) {
  .image-container {
    min-height: 497px;
  }
}

.gallery-list-container {
  overflow: auto !important;

  .gallery-item-container {
    display: inline-block;
    float: left;
    width: 115px;
    padding-bottom: 20px;
    .gallery-item-wrapper {
      margin-right: 10px;
      width: 102px;
      .text-center {
        margin-top: 5px;
      }

      .upload-file-section {
        position: relative;
        width: 102px;
        .close-icon {
          z-index: 2;
          border-radius: 50%;
          // color: $light-gray !important;
          position: absolute;
          top: 2px;
          right: 2px;
        }
        .v-btn {
          z-index: 1;
          &.upload-button {
            background: $background-button;
            border-color: $light-gray;
            border-radius: 0;
            border-style: dashed;
            height: 102px !important;
            width: 102px !important;
            padding: 16px !important;
            &.image {
              padding: 0 !important;
            }
            .v-btn__content {
              display: block;
              .v-icon {
                color: $gray;
              }
              .button-content {
                span {
                  color: $gray;
                  display: block;
                  letter-spacing: 0;
                  white-space: pre;
                }
              }
            }
          }
        }
      }
    }
  }
  .add-gallery-item-container {
    display: inline-block;
    padding-bottom: 20px;
    width: 102px;
    .add-file-button {
      background: $background-button;
      border-color: $light-gray;
      border-radius: 0;
      border-style: dashed;
      height: 102px !important;
      width: 102px;
      padding: 16px 30px !important;
      .v-icon {
        color: $gray;
      }
    }
  }
}
</style>