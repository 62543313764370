import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
  REQUEST_DOWNLOAD,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  coupons: {
    data: [],
    total: 0,
  },
  couponTransactions: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_COUPONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/coupons', data.form)
      state.coupons.data = res?.data?.data || []
      state.coupons.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_COUPONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        '/cms/coupon-transaction/list-coupon',
        data.form
      )
      state.coupons.data = res?.data?.data || []
      state.coupons.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_COUPON_TRANSACTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/coupons-transactions', data.form)
      state.couponTransactions.data = res?.data?.data || []
      state.couponTransactions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_COUPON_TRANSACTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/coupons-transactions/list', data.form)
      state.couponTransactions.data = res?.data?.data || []
      state.couponTransactions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_COUPON(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/cms/coupons', data.form)
      state.coupons.data.push(res.data)
      state.coupons.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        error || GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_COUPON(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/cms/coupons/${data.form?.id}`, data.form)

      const coupons = cloneDeep(state.coupons.data)
      const index = findIndex(coupons, { id: res?.data?.id })

      coupons[index] = res.data
      state.coupons = {
        ...state.coupons,
        data: coupons,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_COUPON(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/cms/coupons/${data.form?.id}`)

      const coupons = cloneDeep(state.coupons.data)
      const index = findIndex(coupons, { id: data.form?.id })

      coupons.splice(index, 1)
      state.coupons = {
        ...state.coupons,
        data: coupons,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EXPORT_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_DOWNLOAD(
        `/cms/coupon-transaction/export`,
        data.form
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_ADMIN_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_DOWNLOAD(
        `/cms/coupon-transactions/exportCI`,
        data.form
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
}

export const actions = {
  getCoupons(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COUPONS(state, { toggleLoading, form, toggleAlert })
  },
  getAdminCoupons(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_COUPONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getCouponTransactions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COUPON_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getAdminCouponTransactions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_COUPON_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  createCoupon(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_COUPON(state, { toggleLoading, form, toggleAlert })
  },
  editCoupon(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_COUPON(state, { toggleLoading, form, toggleAlert })
  },
  deleteCoupon(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_COUPON(state, { toggleLoading, form, toggleAlert })
  },
  exportCoupons(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EXPORT_ORDERS(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  exportAdminCoupons(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EXPORT_ADMIN_ORDERS(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  coupons(state) {
    // eslint-disable-line
    return state.coupons
  },
  couponTransactions(state) {
    // eslint-disable-line
    return state.couponTransactions
  },
}

export const coupons = {
  state,
  mutations,
  actions,
  getters,
}
