<template>
  <v-app>
    <component :is="layout">
      <v-main>
        <router-view />
      </v-main>
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "defaultLayout";
    },
  },
  created() {
    // if (
    //   this.$browserDetect.isChrome ||
    //   this.$browserDetect.isChromeIOS ||
    //   this.$browserDetect.isFirefox ||
    //   this.$browserDetect.isSafari
    // ) {
    //   // this.$swalNoBtn('เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน');
    // } else {
    //   this.$swalNoBtn(
    //     "เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน"
    //   );
    //   this.navigate("/");
    // }
  },
};
</script>

<style lang="scss">
$light-gray: #f6f6f6;

.v-main {
  background: $light-gray;
  // min-width: calc(1184px + 256px);
  height: 100%;
}
</style>
