<template>
  <div class="banking-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">บัญชีธนาคาร</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="generateList"
        :server-items-length="accountBanksList ? accountBanksList.total : total"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <BankingForm
              class="text-right"
              :editId="editId"
              :dialog="modalDialog"
              :toggleDialog="toggleDialog"
              :setInitialDialog="setInitialDialog"
              @submit="submit"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.bankLogo="{ item }">
          <v-img
            v-if="item.bankLogo"
            class="bank-image-container"
            :src="item.bankLogo"
            contain
            width="60"
            height="60"
            @click="showImg(item.bankLogo)"
          />
          <v-img
            v-else
            class="bank-image-container"
            contain
            :src="require('@/assets/promptpay-logo.png')"
            width="60"
            height="60"
            @click="showImg(item.bankLogo)"
          />
        </template>
        <template v-slot:item.paymentType="{ item }">
          <div v-if="item.paymentType === 'transfer'">บัญชีธนาคาร</div>
          <div v-else>พร้อมเพย์</div>
        </template>
        <template v-slot:item.accountNo="{ item }">
          {{ $bankingFormat(item.accountNo) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="editItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
          <v-btn depressed text color="error" @click="deleteItem(item)"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import { get, reduce } from "lodash";
import Utils from "@/mixin";

import LightBox from "@/components/LightBox.vue";

import BankingForm from "./Banking/Form";
import Confirm from "@/components/Confirm";

export default {
  name: "Banking",
  components: {
    BankingForm,
    Confirm,
    LightBox,
  },
  computed: {
    generateList() {
      const { data } = this.accountBanksList;
      const result = reduce(
        data,
        (a, b) => {
          a.push({
            ...b,
            bankLogo: b.bank?.logo,
            bankName: b.bank?.name,
            paymentType: b.shopPaymentMethod?.paymentMethod?.slug,
          });
          return a;
        },
        []
      );
      return result;
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    modalDialog: false,
    total: 0,
    headers: [
      { text: "", value: "bankLogo", sortable: false, width: "100" },
      {
        text: "ประเภท",
        value: "paymentType",
        sortable: false,
      },
      { text: "ชื่อธนาคาร", value: "bankName" },
      { text: "สาขา", value: "branchName", sortable: false },
      { text: "เลขที่บัญชี", value: "accountNo", sortable: false },
      { text: "ชื่อบัญชี", value: "accountName", sortable: false },
      { text: "ดำเนินการ", value: "actions", sortable: false, align: "center" },
    ],
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `บัญชี`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
  }),
  methods: {
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      await this.getBankAccounts();
    },
    editItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    toggleDialog() {
      this.modalDialog = !this.modalDialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const slug = get(data, "slug");
      let submitForm = {};
      if (slug === "transfer") {
        submitForm = {
          accountName: get(data, "accountName"),
          accountNo: get(data, "accountNo"),
          branchName: get(data, "branchName"),
          bankID: get(data, "bankID"),
          slug,
        };
      } else {
        submitForm = {
          accountName: get(data, "promptpayAccountName"),
          accountNo: get(data, "promptpayNo"),
          slug,
        };
      }
      if (this.editId !== "") {
        submitForm.id = this.editId;
        await this.editBankAccount(submitForm);
      } else {
        await this.createBankAccount(submitForm);
      }
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `บัญชี ${data?.bankName || 'พร้อมเพย์'}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deleteBankAccounts({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  mounted() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banking-container {
  padding: 20px;
  .bank-image-container {
    margin: 10px;
  }
}
</style>