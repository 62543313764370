<template>
  <v-chip
    v-bind="$attrs"
    :ripple="false"
    :class="`status-chip${largeSize ? '' : ' small'}`"
    ><span>{{ chipText }}</span></v-chip
  >
</template>

<script>
export default {
  props: {
    chipText: {
      type: String,
    },
    largeSize: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.status-chip {
  text-align: center;
  width: 120px !important;
  &.small {
    padding: 0 !important;
    width: 70px !important;
    span {
      font-size: 10px;
    }
  }
  span {
    font-size: inherit;
    font-weight: bold;
    width: 100%;
    justify-content: center;
  }
  .v-chip--outlined {
    border-color: #bdbdbd;
  }
}
</style>