<template>
  <div id="default-layout">
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      :onClose="() => onCloseAlert()"
    />
    <div v-show="isLoading" class="loading-process">
      <v-progress-circular indeterminate color="light-blue darken-1" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import Utils from "@/mixin";

import Alert from "@/components/Alert.vue";

export default {
  components: {
    Alert,
  },
  computed: {
    snackbar() {
      if (!isEmpty(this.alert?.message)) return true;
      return false;
    },
    alertText() {
      if (!isEmpty(this.alert?.message)) return this.alert?.message;
      return "";
    },
    alertType() {
      if (!isEmpty(this.alert?.message)) return this.alert?.type;
      return "";
    },
  },
  method: {
    onCloseAlert() {
      this.alert.message = "";
    },
  },
  watch: {
    snackbar(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.alert.message = "";
        }, 3000);
      }
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
#default-layout {
  min-height: 100vh;
  position: relative;
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>