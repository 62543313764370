<template>
  <div class="alert-container">
    <v-snackbar :value="snackbar" top right :color="type"
      >{{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    snackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "error",
    },
  },
};
</script>