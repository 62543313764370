<template>
  <div class="dashboard-coupon-container">
    <div v-if="ciReport !== null">
      <CIReport
        :accessToken="ciReport.accessToken"
        :embedUrl="ciReport.embedUrl"
        :basicFilter="ciReport.basicFilter"
      />
    </div>
  </div>
</template>

<script>
import { models } from "powerbi-client";

import Utils from "@/mixin";

import CIReport from "@/features/CIReport.vue";

import { getJWTToken } from "@/plugins/auth";

export default {
  name: "DashbaordCoupon",
  components: {
    CIReport,
  },
  created() {
    this.jwtToken = getJWTToken();
  },
  data: () => ({
    ciReport: null,
    jwtToken: null,
  }),
  mounted() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      const { jwtToken } = this;
      const res = await this.getCouponDashboard();
      this.ciReport = {
        accessToken: res.token,
        embedUrl: res.embedUrl,
        basicFilter: {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "sor v_shops_info",
            column: "shop_code",
          },
          operator: "Eq",
          values: [jwtToken.shop.code],
          filterType: models.FilterType.BasicFilter,
          requireSingleSelection: true,
        },
      };
    },
  },
  mixins: [Utils],
};
</script>