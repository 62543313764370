import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DOWNLOAD,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  orderDashboard: null,
  orderBestSallers: null,
  orderTopSallers: null,
  orderTopShop: null,
  orderDetail: null,
  orders: {
    data: [],
    total: 0,
  },
  orderList: {
    data: [],
    total: 0,
  },
  orderPaymentList: {
    data: [],
    total: 0,
  },
  orderHistoryList: {
    data: [],
    total: 0,
  },
  orderShipmentImageList: [],
  // orderShipmentList: {
  //   data: [],
  //   total: 0,
  // },
}

export const mutations = {
  async GET_ADMIN_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/orders`, data.form)
      state.orders.data = res?.data?.data || []
      state.orders.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/seller/orders`,
        data.form
      )
      state.orders.data = res?.data?.data || []
      state.orders.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_ADMIN_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_DOWNLOAD(`/api/orders/export`, data.form)
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_ORDERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_DOWNLOAD(
        `/api/shops/${data.selectedShop}/seller/orders/export`,
        data.form
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_ORDER_INDEX(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/orders/${data.form.orderId}`)
      state.orderDetail = res.data
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ORDER_INDEX(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/orders/${data.form.orderId}`
      )
      state.orderDetail = res.data
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_ORDERS_LIST(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/orders/${data.form?.orderId}/orderlists`,
        data.form
      )
      state.orderList.data = res?.data || []
      state.orderList.total = res?.data?.length || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ORDERS_LIST(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/orderlists`,
        data.form
      )
      state.orderList.data = res?.data || []
      state.orderList.total = res?.data?.length || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_ORDERS_PAYMENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/orders/${data.form?.orderId}/payments`
      )
      state.orderPaymentList.data = res?.data?.data || []
      state.orderPaymentList.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ORDERS_PAYMENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/seller/payments`
      )
      state.orderPaymentList.data = res?.data?.data || []
      state.orderPaymentList.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ADMIN_ORDERS_HISTORY(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/orders/${data.form?.orderId}/histories`,
        data.form
      )
      state.orderHistoryList.data = res?.data || []
      state.orderHistoryList.total = res?.data?.length || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_ORDERS_HISTORY(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/histories`,
        data.form
      )
      state.orderHistoryList.data = res?.data || []
      state.orderHistoryList.total = res?.data?.length || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  // async GET_ORDERS_SHIPMENT(state, data) {
  //   data.toggleLoading()
  //   try {
  //     const res = await REQUEST_GET(
  //       `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/delivery`
  //     )
  //     state.orderShipmentList.data = res?.data?.data || []
  //     state.orderShipmentList.total = res?.data?.total || 0
  //     data.toggleLoading()
  //     return state
  //   } catch (e) {
  //     data.toggleLoading()
  //     const error = errorMessage(e)
  //     return error
  //   }
  // },
  async APPROVE_ORDER(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/approve`,
        data.form
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // const orders = cloneDeep(state.orders.data)
          // const index = findIndex(orders, { id: res?.data?.id })

          // orders[index] = {
          //   ...orders[index],
          //   ...res.data,
          // }
          // state.orders = {
          //   ...state.orders,
          //   data: orders,
          // }
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async CANCEL_ORDER(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/seller/cancel`,
        data.form
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // const orders = cloneDeep(state.orders.data)
          // const index = findIndex(orders, { id: res?.data?.id })

          // orders[index] = res.data
          // state.orders = {
          //   ...state.orders,
          //   data: orders,
          // }
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async APPROVE_PAYMENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/payments/${data.form?.paymentId}/approve`,
        data.form
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          const orderPaymentList = cloneDeep(state.orderPaymentList.data)
          const index = findIndex(orderPaymentList, { id: res?.data?.id })

          orderPaymentList[index] = {
            ...orderPaymentList[index],
            ...res.data,
          }
          state.orderPaymentList = {
            ...state.orderPaymentList,
            data: orderPaymentList,
          }

          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async UPLOAD_SHIPMENT(state, data) {
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('note', data.form?.note)
      formData.append('image', data.form?.image)
      formData.append('orderList', JSON.stringify(data.form?.orderList))
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/delivery/ship`,
        formData
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_ORDER(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}`,
        data.form
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async GET_PRICE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/products/${data.form?.productId}/qty/${data.form?.qty}/price`
      )
      data.toggleLoading()
      return res.data?.price
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/dashboard`,
        data.form
      )
      data.toggleLoading()
      state.orderDashboard = res?.data
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_CI_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/power_bi`)
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_RETAIL_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/power_bi/retail`)
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_COUPON_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/power_bi/coupon_usage`)
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_BEST_SELLERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/best_sellers`,
        data.form
      )
      data.toggleLoading()
      state.orderBestSallers = res?.data
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_TOP_SELLERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/top_sales`,
        data.form
      )
      data.toggleLoading()
      state.orderTopSallers = res?.data
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_TOP_SHOPS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/top_sales`,
        data.form
      )
      data.toggleLoading()
      state.orderTopShop = res?.data
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHIPMENT_IMAGE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/delivery_photos`,
        { type: data.form?.type }
      )
      data.toggleLoading()
      state.orderShipmentImageList = res?.data
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPLOAD_SHIPMENT_IMAGE(state, data) {
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('type', data.form?.type)
      formData.append('photos', data.form?.image)
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/orders/${data.form?.orderId}/delivery_photos`,
        formData
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_SHIPMENT_IMAGE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_DELETE(
        `/api/shops/${data.selectedShop}/delivery_photos/${data.form?.deleteId}`
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.DELETE.SUCCESS,
            'ok',
            'success'
          )
        }
      }
      data.toggleAlert(alert)

      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getAdminOrders(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_ORDERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrders(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ORDERS(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  exportAdminOrder(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EXPORT_ADMIN_ORDERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  exportOrders(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EXPORT_ORDERS(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  getAdminOrderIndex(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_ORDER_INDEX(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrderIndex(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ORDER_INDEX(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  getAdminOrdersList(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_ORDERS_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrdersList(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ORDERS_LIST(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  getAdminOrderPayment(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_ORDERS_PAYMENT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrderPayment(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ORDERS_PAYMENT(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  getAdminOrderHistory(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ADMIN_ORDERS_HISTORY(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrderHistory(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_ORDERS_HISTORY(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  // getOrderShipment(context, form) {
  //   const toggleLoading = () => context.dispatch('toggleLoading')
  //   const toggleAlert = (alertForm) =>
  //     context.dispatch('toggleAlertMessage', alertForm)
  //   const selectedShop = context?.rootGetters?.selectedShop
  //   return mutations.GET_ORDERS_SHIPMENT(state, {
  //     toggleLoading,
  //     selectedShop,
  //     form,
  //     toggleAlert,
  //   })
  // },
  approveOrder(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.APPROVE_ORDER(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  cancelOrder(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.CANCEL_ORDER(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  approvePayment(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.APPROVE_PAYMENT(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  uploadShipment(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.UPLOAD_SHIPMENT(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  editOrder(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EDIT_ORDER(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  getPrice(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_PRICE(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getOrderDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_DASHBOARD(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getCIDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_CI_DASHBOARD(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getRetailDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_RETAIL_DASHBOARD(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getCouponDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COUPON_DASHBOARD(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getOrderBestSellers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_BEST_SELLERS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getOrderTopSellers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_TOP_SELLERS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getOrderTopShops(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_TOP_SHOPS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getShipmentImage(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_SHIPMENT_IMAGE(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  uploadShipmentImage(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.UPLOAD_SHIPMENT_IMAGE(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  deleteShipmentImage(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.DELETE_SHIPMENT_IMAGE(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  orderDetail(state) {
    // eslint-disable-line
    return state.orderDetail
  },
  orders(state) {
    // eslint-disable-line
    return state.orders
  },
  orderList(state) {
    // eslint-disable-line
    return state.orderList
  },
  orderPaymentList(state) {
    // eslint-disable-line
    return state.orderPaymentList
  },
  // orderShipmentList(state) {
  //   // eslint-disable-line
  //   return state.orderShipmentList
  // },
  orderHistoryList(state) {
    // eslint-disable-line
    return state.orderHistoryList
  },
  orderDashboard(state) {
    // eslint-disable-line
    return state.orderDashboard
  },
  orderBestSallers(state) {
    // eslint-disable-line
    return state.orderBestSallers
  },
  orderTopSallers(state) {
    // eslint-disable-line
    return state.orderTopSallers
  },
  orderTopShop(state) {
    // eslint-disable-line
    return state.orderTopShop
  },
  orderShipmentImageList(state) {
    // eslint-disable-line
    return state.orderShipmentImageList
  },
}

export const orders = {
  state,
  mutations,
  actions,
  getters,
}
