<template>
  <div class="point-setting-container">
    <!-- <InitialPoint /> -->
    <PromotionPoint class="mt-5" />
  </div>
</template>

<script>
import Utils from "@/mixin";

// import InitialPoint from "./PointSetting/InitialPoint";
import PromotionPoint from "./PointSetting/PromotionPoint";

export default {
  name: "Admin-PointSetting",
  mixins: [Utils],
  components: {
    // InitialPoint,
    PromotionPoint,
  },
};
</script>

<style lang="scss" scoped>
.point-setting-container {
  padding: 20px;
}
</style>