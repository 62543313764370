import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from './Auth'
import { categories } from './Category'
import { brands } from './Brand'
import { companies } from './Company'
import { producttypes } from './ProductType'
import { shops } from './Shop'
import { products } from './Product'
import { orders } from './Order'
import { productPhotos } from './ProductPhoto'
import { promotions } from './Promotion'
import { coupons } from './Coupon'

Vue.use(Vuex)

export const state = {
  isLoading: false,
  alertMessage: undefined,
}

export const mutations = {
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading
    return state
  },
  TOGGLE_ALERT_MESSAGE(state, alertForm) {
    state.alertMessage = alertForm
    return state
  },
}

export const actions = {
  toggleLoading(context) {
    context.commit('TOGGLE_LOADING')
  },
  toggleAlertMessage(context, formData) {
    context.commit('TOGGLE_ALERT_MESSAGE', formData)
  },
}

export const getters = {
  isLoading(state) {
    return state.isLoading
  },
  alert(state) {
    return state.alertMessage
  },
}

export const modules = {
  authentication,
  companies,
  categories,
  brands,
  shops,
  producttypes,
  products,
  orders,
  productPhotos,
  promotions,
  coupons,
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
})
