<template>
  <div class="payment-container">
    <h2 class="mb-3">การชำระเงิน</h2>
    <v-row>
      <v-col sm="8">
        <v-card class="payment-wrapper pb-3 elevation-5 rounded-xl">
          <v-card-title>ประเภทการชำระ</v-card-title>
          <v-divider class="mb-3" />
          <div
            class="d-flex mb-4"
            v-for="(item, index) in paymentList"
            :key="index"
          >
            <span class="ml-3 payment-name">{{ item.name }}</span>
            <span v-if="item.shopPaymentType">
              <v-switch
                class="pt-0 mt-0"
                color="#009b3f"
                dense
                inset
                hide-details
                @change="onTogglePaymentType(item)"
                v-model="item.shopPaymentType.enabled"
              />
            </span>
          </div>
          <v-card-title>รายการผู้ซื้อ</v-card-title>
          <v-card-subtitle
            ><i
              >กรุณาเลือกผู้ซื้อที่อนุญาตให้ชำระเงินแบบเครดิต</i
            ></v-card-subtitle
          >
          <v-divider />
          <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="dataList"
            :server-items-length="buyersList ? buyersList.total : total"
            :footer-props="{
              itemsPerPageOptions,
            }"
            :options.sync="options"
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-spacer />
                <v-btn
                  color="success"
                  outlined
                  class="mr-4"
                  @click="switchTablePayment"
                >
                  เปิด/ปิดทั้งหมด
                </v-btn>
                <v-btn color="success" @click="submitAction">บันทึก</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.creditAvailable="{ item }">
              <v-switch
                class="pt-0 mt-0 ml-5"
                color="#009b3f"
                dense
                inset
                hide-details
                v-model="item.creditAvailable"
              />
            </template>
            <template v-slot:item.creditDay="{ item }">
              <v-text-field
                class="input-day-field"
                outlined
                dense
                hide-details
                @keypress="validateNumeric"
                v-model="item.creditDay"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card class="payment-wrapper pb-3 elevation-5 rounded-xl">
          <v-card-title>ช่องทางการชำระ</v-card-title>
          <v-divider class="mb-3" />
          <div
            class="d-flex mb-4"
            v-for="(item, index) in paymentMethodsList.data"
            :key="index"
          >
            <span class="ml-3 payment-name">{{ item.paymentMethod.name }}</span>
            <v-switch
              class="pt-0 mt-0"
              color="#009b3f"
              dense
              inset
              hide-details
              v-model="item.is_enable"
              @change="() => onTogglePaymentMethod(item.paymentMethod.id)"
            /></div
        ></v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Utils from "@/mixin";
import { forEach, isEmpty, reduce } from "lodash";
import { itemsPerPageOptions } from "@/plugins/common";

export default {
  name: "Payment",
  computed: {
    dataList() {
      const { buyersList } = this;
      const result = reduce(
        buyersList.data,
        (o, e) => {
          o.push({
            buyerId: e.buyerId,
            name: e.buyer.name,
            creditAvailable: e.creditAvailable,
            creditDay: e.creditDay,
          });
          return o;
        },
        []
      );
      this.agentList = result; // eslint-disable-line
      return result;
    },
    paymentList() {
      const { paymentTypeList } = this;
      const result = reduce(
        paymentTypeList.data,
        (o, e) => {
          if (isEmpty(e.shopPaymentType)) {
            o.push({
              ...e,
              shopPaymentType: {
                enabled: false,
              },
            });
          } else {
            o.push({
              ...e,
            });
          }
          return o;
        },
        []
      );
      return result;
    },
  },
  data: () => ({
    itemsPerPageOptions: [],
    total: 0,
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    agentList: [],
    headers: [
      { text: "ชื่อผู้ซื้อ", value: "name" },
      {
        text: "ชำระเงินเครดิต",
        value: "creditAvailable",
        width: "100px",
        align: "center",
        sortable: false,
      },
      {
        text: "จำนวนวัน",
        value: "creditDay",
        width: "100px",
        align: "center",
        sortable: false,
      },
    ],
    flagPayment: true,
  }),
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async getInitialData() {
      const condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }
      await this.getBuyers(condition);
      await this.getPaymentMethods();
      await this.getPaymentTypes();
    },
    async onTogglePaymentMethod(id) {
      await this.updatePaymentMethods(id);
    },
    async onTogglePaymentType(e) {
      await this.updatePaymentTypes(e);
    },
    switchTablePayment() {
      const { agentList, flagPayment } = this;
      forEach(agentList, (i) => {
        i.creditAvailable = flagPayment;
      });
      this.flagPayment = !flagPayment;
    },
    submitAction() {
      const { agentList } = this;
      const formData = reduce(
        agentList,
        (o, e) => {
          o.push({ ...e, creditDay: parseInt(e.creditDay) });
          return o;
        },
        []
      );
      this.updateBuyers(formData);
    },
  },
  mounted() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    options: {
      handler() {
        const { selectedShop } = this;
        if (selectedShop !== "") this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
.payment-container {
  padding: 20px;
  .payment-wrapper {
    padding: 0 20px;
    .payment-name {
      width: 100%;
    }
    .input-day-field {
      input {
        text-align: right;
      }
    }
  }
}
</style>