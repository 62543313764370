import {
  forEach,
  get,
  toString,
  size,
  includes as lodashIncludes,
} from 'lodash'
import jwtDecode from 'jwt-decode'

export const getToken = () => {
  const token = localStorage.getItem('token')
  return toString(token)
}

export const getJWTToken = (token = getToken()) => {
  const jwtToken = jwtDecode(token)
  const shopList = get(jwtToken, 's')
  const roleList = get(jwtToken, 'u.roles')
  let checkAccess = false
  if (size(roleList) > 0) {
    const admin = lodashIncludes(roleList, 'app.admin')
    if (admin) {
      const role = PERMISSION.APP_ADMIN
      const result = {
        ...get(jwtToken, 'u'),
        shop: get(jwtToken, 's')[0],
        role,
      }
      return result
    }
  }
  forEach(shopList, (i) => {
    forEach(i.roles, (j) => {
      if (j.includes('shop')) {
        checkAccess = true
      }
    })
  })
  if (size(shopList) > 0 && checkAccess) {
    const role = PERMISSION.SHOP_ADMIN
    const result = {
      ...get(jwtToken, 'u'),
      shop: get(jwtToken, 's')[0],
      role,
    }
    return result
  }
  return null
}

export const getJWTShop = (token = getToken()) => {
  const jwtToken = jwtDecode(token)
  const shopList = get(jwtToken, 's')
  if (size(shopList) > 0) {
    return shopList
  }
  return []
}

export const PERMISSION = {
  /**
    (2, "app.admin", "Application administrator"),
    (3, "app.report", "Application report viewer"),
    (4, "app.user.admin", "Application user administrator"),
    (5, "app.user.viewer", "Application user viewer"),
    (6, "app.order.admin", "Application order administrator"),
    (7, "app.order.viewer", "Application order viewer"),
    (8, "app.product.admin", "Application product administrator"),
    (9, "app.product.viewer", "Application product viewer"),
    */
  APP_ADMIN: 'app.admin',
  APP_REPORT: 'app.report',
  APP_USER_ADMIN: 'app.user.admin',
  APP_USER_VIEWER: 'app.user.viewer',
  APP_ORDER_ADMIN: 'app.order.admin',
  APP_ORDER_VIEWER: 'app.order.viewer',
  APP_PRODUCT_ADMIN: 'app.product.admin',
  APP_PRODUCT_VIEWER: 'app.product.viewer',
  /**
    (10, "shop.admin", "Shop administrator"),
    (11, "shop.report", "Shop report viewer"),
    (12, "shop.user.admin", "Shop user administrator"),
    (13, "shop.user.viewer", "Shop user viewer"),
    (14, "shop.order.admin", "Shop order administrator"),
    (15, "shop.order.viewer", "Shop order viewer"),
    (16, "shop.product.admin", "Shop product administrator"),
    (17, "shop.product.viewer", "Shop product viewer"),
  */
  SHOP_ADMIN: 'shop.admin',
  SHOP_REPORT: 'shop.report',
  SHOP_USER_ADMIN: 'shop.user.admin',
  SHOP_USER_VIEWER: 'shop.user.viewer',
  SHOP_ORDER_ADMIN: 'shop.order.admin',
  SHOP_ORDER_VIEWER: 'shop.order.viewer',
  SHOP_PRODUCT_ADMIN: 'shop.product.admin',
  SHOP_PRODUCT_VIEWER: 'shop.product.viewer',
}
