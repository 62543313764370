<template>
  <div class="notification-container">
    <h2 class="mb-3">การแจ้งเตือน</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <div v-for="(i, index) in generateList" :key="index">
        <v-divider class="my-3" v-if="index !== 0" />
        <div class="notification-content mx-4">
          <span>
            <span class="green-font" v-if="i.event === 'new_order'">
              คุณได้รับออเดอร์ใหม่
            </span>
            <span class="green-font" v-else-if="i.event === 'order_confirmed'">
              ออเดอร์ของคุณได้รับการยืนยัน
            </span>
            <span class="green-font" v-else-if="i.event === 'inform_payment'">
              ออเดอร์ได้รับการชำระเงิน
            </span>
            <span class="green-font" v-else-if="i.event === 'order_shipped'">
              ออเดอร์ของคุณกำลังจัดส่ง
            </span>
            <span class="red-font" v-else>ออเดอร์ของคุณถูกยกเลิก</span>
          </span>
          <span class="float-end">{{ $generateDate(i.createdAt) }} น. </span>
          <div><b>ออเดอร์</b> {{ i.order.orderNumber }}</div>
          <div>
            <b>ลูกค้า</b>
            <span v-if="i.order.buyer.shopType === 'agent'" class="green--text">
              {{ i.order.buyer.name }}
            </span>
            <span v-if="i.order.buyer.shopType === 'retail'" class="blue--text">
              {{ i.order.buyer.name }}
            </span>
            <span
              v-if="
                i.order.buyer.shopType === 'sub-agent' ||
                i.order.buyer.shopType === 'sub-direct'
              "
              class="light-green--text"
            >
              {{ i.order.buyer.name }}
            </span>
          </div>
          <div>
            <b>ประเภทลูกค้า</b> {{ shopTypeName(i.order.buyer.shopType) }}
          </div>
          <div>
            <b class="mr-2">สถานะ</b>
            <StatusChip
              v-if="i.order.status === 'complete'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="เสร็จสิ้น"
            />
            <StatusChip
              v-else-if="i.order.status === 'inprogress'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="กำลังดำเนินการ"
            />
            <StatusChip
              v-else-if="i.order.status === 'pending'"
              color="#f4cbcc"
              text-color="#da291c"
              chipText="ที่ต้องยืนยัน"
            />
            <StatusChip
              v-else-if="i.order.status === 'cancel'"
              color="#f3f3f3ff"
              text-color="#666666"
              chipText="ยกเลิก"
            />
          </div>
          <div><b>หมายเหตุ</b> {{ i.note }}</div>
          <v-btn
            class="notification-button"
            outlined
            @click="redirectOrder(i.order.orderNumber)"
            >ดูรายละเอียด</v-btn
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { reduce } from "lodash";
import Utils from "@/mixin";

import StatusChip from "@/components/StatusChip";

export default {
  components: { StatusChip },
  computed: {
    generateList() {
      const { notificationList } = this;
      let result = [];
      if (notificationList !== null) {
        result = reduce(
          notificationList?.data,
          (a, b) => {
            a.push({ ...b });
            return a;
          },
          []
        );
      }
      return result;
    },
  },
  data: () => ({}),
  mounted() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
  },
  methods: {
    shopTypeName(txt) {
      let result;
      switch (txt) {
        case "agent":
          result = "เอเย่นต์";
          break;
        case "sub-agent":
          result = "ซับเอเยนต์";
          break;
        case "sub-direct":
          result = "ซับเอเยนต์";
          break;
        case "retail":
          result = "ร้านค้าปลีก";
          break;
        default:
          break;
      }
      return result;
    },
    async getInitialData() {
      await this.getNotification({ order: "createdAt:desc" });
    },
    redirectOrder(orderNumber) {
      this.$router.push({ name: "Order", params: { orderNumber } });
    },
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$blue-text: #4986e8;
$light-green-text: #92c47c;
$gray: #6a6a6a;
$green: #009b3f;
$red: #da291c;

.green--text {
  color: $green;
}
.blue-text {
  color: $blue-text;
}
.light-green--text {
  color: $light-green-text;
}

.notification-container {
  padding: 20px;
  font-size: 14px;
  .notification-content {
    color: $gray;
    @media (min-width: 768px) {
      position: relative;
    }
    .red-font {
      color: $red;
      font-weight: bold;
      font-size: 16px;
    }
    .green-font {
      color: $green;
      font-weight: bold;
      font-size: 16px;
    }
    .notification-button {
      @media (min-width: 768px) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      @media (max-width: 768px) {
        display: flex;
        margin: 5px 0 0 auto;
      }
    }
  }
}
</style>