<template>
  <div class="product-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">สินค้าไทยเบฟฯ</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="(products && products.data) || []"
        :server-items-length="products && products.total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" :height="checkScreen">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      :disabled="isLoading"
                      v-model="keyword"
                      label="ค้นหาจาก"
                      single-line
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-combobox
                      v-model="producttype"
                      item-value="id"
                      item-text="name"
                      :items="typeList"
                      label="ประเภทสินค้า"
                      outlined
                      dense
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  class="table-button"
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  :block="checkMdScreen"
                  >ค้นหา</v-btn
                >
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex justify-end">
                  <ProductForm
                    :agentReadOnly="agentReadOnly"
                    :editId="editId"
                    :dialog="dialog"
                    :toggleDialog="toggleDialog"
                    :setInitialDialog="setInitialDialog"
                    @submit="submit"
                  />
                </div>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.productPhotos="{ item }">
          <v-img
            :src="
              item.productPhotos &&
              item.productPhotos[0] &&
              item.productPhotos[0].url
            "
            contain
            width="100"
            height="100"
            @click="showImg(item.productPhotos[0].url)"
          />
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            v-if="item.status === 'วางขาย'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="ปกติ"
          />
          <StatusChip
            v-else-if="item.status === 'รออนุมัติ'"
            color="#fff2cd"
            text-color="#f67b00"
            chipText="รออนุมัติ"
          />
          <StatusChip
            v-else-if="item.status !== 'รออนุมัติ' && item.status !== 'วางขาย'"
            color="#f4cbcc"
            text-color="#da291c"
            :chipText="item.status"
          />
        </template>
        <template v-slot:item.alcohol="{ item }">
          <v-icon v-if="!item.alcohol" color="red">mdi-close</v-icon>
          <v-icon v-else color="green">mdi-check</v-icon>
        </template>
        <template v-slot:item.price="{ item }">
          <span>
            <span
              v-if="item.productStepPrices && item.productStepPrices.length > 0"
            >
              {{ calStep(item).min }} ~ {{ calStep(item).max }}</span
            >
            <span v-else>{{ item.price }}</span></span
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="editItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
          <v-btn
            depressed
            text
            color="error"
            @click="deleteItem(item)"
            :disabled="agentReadOnly"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import { forEach, get, reduce } from "lodash";
import Utils from "@/mixin";
import { companygroup, itemsPerPageOptions } from "@/plugins/common";
import { getJWTToken, PERMISSION } from "@/plugins/auth";
import { calStep } from "@/utils/product";

import StatusChip from "@/components/StatusChip";
import ProductForm from "./Product/Form";
import LightBox from "@/components/LightBox.vue";

import Confirm from "@/components/Confirm";

export default {
  name: "Admin-Product",
  components: {
    LightBox,
    StatusChip,
    ProductForm,
    Confirm,
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    dialog: false,
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      {
        text: "ภาพสินค้า",
        value: "productPhotos",
        align: "start",
        sortable: false,
      },
      { text: "ชื่อสินค้า", value: "name" },
      { text: "รหัสสินค้า", value: "sku" },
      { text: "ประเภทสินค้า", value: "productType.name" },
      // { text: "หมวดหมู่", value: "category" },
      { text: "แบรนด์", value: "brand.name" },
      { text: "ราคา", value: "price", align: "center" },
      // { text: "สถานะการขาย", value: "status", width: "70px" },
      {
        text: "แอลกอฮอล์",
        value: "alcohol",
        width: "120px",
        sortable: false,
        align: "center",
      },
      {
        text: "ดำเนินการ",
        value: "actions",
        align: "center",
        sortable: false,
        width: "200px",
      },
    ],
    // category: "",
    producttype: "",
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `สินค้า`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    jwtToken: null,
    agentReadOnly: false,
    windowWidth: window.innerWidth,
  }),
  async created() {
    this.jwtToken = getJWTToken();
    await this.getProductTypes({});
  },
  mounted() {
    const { jwtToken } = this;
    switch (jwtToken?.role) {
      case PERMISSION.APP_ADMIN: {
        break;
      }
      case PERMISSION.SHOP_ADMIN: {
        this.agentReadOnly = true;
        break;
      }
      default: {
        break;
      }
    }
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    typeList() {
      return [{ name: "ทั้งหมด", id: 0 }, ...this.producttypes?.data] || [];
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "64px";
      } else if (windowWidth < 960) {
        return "250px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    calStep,
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      const { producttypes } = this;
      let condition = {
        companyGroup: companygroup,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.producttype) {
        let productTypeIds = 0;
        forEach(producttypes.data, (e) => {
          if (e.name === this.producttype?.name) {
            productTypeIds = e.id;
          }
        });
        if (productTypeIds !== 0) condition.productTypeIds = productTypeIds;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getProducts(condition);
    },
    editItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `สินค้า ${data?.name}`,
        status: true,
        data,
      };
    },
    searchKeyword() {
      this.getInitialData();
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const stepPrices = reduce(
        get(data, "stepPrice"),
        (a, b) => {
          if (b.id !== undefined) {
            a.push({
              productStepPriceId: b.id,
              min: parseInt(b.min),
              max: parseInt(b.max),
              price: parseFloat(b.price),
            });
          } else {
            a.push({
              min: parseInt(b.min),
              max: parseInt(b.max),
              price: parseFloat(b.price),
            });
          }
          return a;
        },
        []
      );
      const submitForm = {
        productTypeId: get(data, "producttype"),
        name: get(data, "name"),
        sku: get(data, "code"),
        description: get(data, "description"),
        brandId: get(data, "brand"),
        price: parseFloat(get(data, "price")) || 0,
        salePrice: parseFloat(get(data, "salePrice")) || 0,
        unit: get(data, "unit"),
        categoryId: get(data, "categoryId"),
        stepPrices: {
          updates: stepPrices,
          deletes: get(data, "deleteStepPrice"),
        },
        alcohol: get(data, "alcohol"),
      };

      let product = {};
      if (this.editId !== "") {
        submitForm.id = this.editId;
        product = await this.editProduct(submitForm);
      } else {
        product = await this.createProduct(submitForm);
      }

      const photos = get(data, "image");
      if (photos && Array.isArray(photos) && photos.length > 0) {
        const files = photos.reduce((o, e) => {
          if (e instanceof File) o.push(e);
          return o;
        }, []);

        if (files.length > 0)
          await this.uploadProductPhoto({ id: product.id, photos: files });
      }

      this.editId = "";
      this.getInitialData();
    },
    async deleteData(value = false) {
      if (value) {
        await this.deleteProduct({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
.product-container {
  padding: 20px;
  .table-button {
    margin-top: 3px;
  }
}
</style>