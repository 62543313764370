import { cloneDeep, findIndex } from 'lodash'
import { REQUEST_GET, REQUEST_POST, REQUEST_PUT, REQUEST_DELETE } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  companies: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_COMPANIES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/companies', data.form)
      state.companies.data = res?.data?.data || []
      state.companies.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_COMPANY(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/cms/companies', data.form)
      state.companies.data.push(res.data)
      state.companies.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_COMPANY(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/cms/companies/${data.form?.id}`, data.form)

      const companies = cloneDeep(state.companies.data)
      const index = findIndex(companies, { id: res?.data?.id })

      companies[index] = res.data
      state.companies = {
        ...state.companies,
        data: companies,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_COMPANY(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/cms/companies/${data.form?.id}`)

      const companies = cloneDeep(state.companies.data)
      const index = findIndex(companies, { id: data.form?.id })

      companies.splice(index, 1)
      state.companies = {
        ...state.companies,
        data: companies,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getCompanies(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('GET_COMPANIES', { toggleLoading, form, toggleAlert })
  },
  createCompany(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_COMPANY', { toggleLoading, form, toggleAlert })
  },
  editCompany(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_COMPANY', { toggleLoading, form, toggleAlert })
  },
  deleteCompany(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('DELETE_COMPANY', { toggleLoading, form, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  companies(state) { // eslint-disable-line
    return state.companies
  },
}

export const companies = {
  state,
  mutations,
  actions,
  getters,
}
