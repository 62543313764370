<template>
  <div class="category-form-container">
    <v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
      <template v-slot:activator="{ attrs }">
        <v-btn color="success" class="mb-2" v-bind="attrs" @click="toggleDialog"
          >+ เพิ่มแบรนด์</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ modalTitle }}แบรนด์</span>
        </v-card-title>

        <v-card-text class="modal-card-text">
          <v-container>
            <v-form ref="form">
              <v-text-field
                v-model="formData.slug"
                label="รหัสแบรนด์*"
                outlined
                dense
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="formData.name"
                label="ชื่อแบรนด์*"
                outlined
                dense
                :rules="[rules.required]"
              />
              <v-select
                v-model="formData.company"
                item-value="id"
                item-text="name"
                outlined
                dense
                :items="companies.data"
                :rules="[rules.required]"
                label="บริษัท*"
              />
              <v-textarea
                v-model="formData.description"
                label="รายละเอียด"
                rows="5"
                outlined
                dense
              >
              </v-textarea>
              <GalleryUploadFile
                v-model="formData.logoImg"
                label="เลือกรูปโลโก้*"
                :max="1"
                :option="upOption"
                :editable="true"
                accept="image/*"
              />
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="clearData">ปิดหน้าต่าง</v-btn>
          <v-btn color="success" @click="submitForm" :disabled="validate"
            >บันทึก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, findIndex, get } from "lodash";
import Utils from "@/mixin";

import GalleryUploadFile from "@/features/GalleryUploadFile";

export default {
  mixins: [Utils],
  components: {
    GalleryUploadFile,
  },
  computed: {
    validate() {
      const { name, slug, company, logoImg } = this.formData;
      if (!(name && slug && company && logoImg)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    modalTitle: "เพิ่ม",
    initialForm: {
      name: "",
      slug: "",
      company: "",
      description: "",
    },
    formData: {
      name: "",
      slug: "",
      company: "",
      description: "",
    },
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
    },
    upOption: {
      maxWidth: 1000,
    },
  }),
  created() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      await this.getCompanies({});
    },
    clearData() {
      if (this.$props.editId !== "") this.$props.setInitialDialog();
      this.toggleDialog();
      this.$nextTick(() => {
        this.modalTitle = "เพิ่ม";
        const cloneInit = cloneDeep(this.initialForm);
        this.formData = Object.assign({}, cloneInit);
        if (this.$refs.form) this.$refs.form.resetValidation();
      });
    },
    async submitForm() {
      const { formData } = this;
      if (this.$props.editId !== "") {
        const submitForm = {
          name: get(formData, "name"),
          slug: get(formData, "slug"),
          description: get(formData, "description"),
          companyId: get(formData, "company"),
          logoImg: get(formData, "logoImg"),
          id: this.$props.editId,
        };
        await this.editBrand(submitForm);
        this.getBrands({});
      } else {
        const submitForm = {
          name: get(formData, "name"),
          slug: get(formData, "slug"),
          description: get(formData, "description"),
          companyId: get(formData, "company"),
          logoImg: get(formData, "logoImg"),
        };

        await this.createBrand(submitForm);
        this.getBrands({});
      }
      this.clearData();
    },
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      default: () => {},
    },
    setInitialDialog: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "แก้ไข";
        const { brands } = this;
        const index = findIndex(brands.data, (i) => i.id === this.editId);

        if (index !== -1) {
          const data = brands.data[index];
          this.formData = {
            name: data.name,
            slug: data.slug,
            company: data.companyId,
            description: data.description,
            logoImg: data.logoImgUrl,
          };
        }
      }
    },
  },
};
</script>