<template>
  <v-container class="register-container" data-testid="register">
    <v-row>
      <v-col cols="12" offset-md="3" md="6" offset-lg="3" lg="6">
        <router-link :to="'/'">
          <v-img
            class="home-logo"
            :src="require('@/assets/logo.png')"
            height="200px"
            contain
          />
        </router-link>
        <h1>ลงทะเบียน ช้างชวนช้อป</h1>
        <v-form
          :disabled="isLoading"
          v-model="valid"
          :lazy-validation="false"
          @submit.prevent="submitForm"
        >
          <v-text-field
            label="Name"
            id="name"
            v-model="name"
            :rules="[rules.required]"
          />
          <v-text-field
            label="Email"
            id="email"
            v-model="email"
            type="email"
            :rules="[rules.required, rules.emailValidate]"
          />
          <v-text-field
            label="Phone"
            id="phone"
            v-model="phone"
            :rules="[rules.required]"
          />
          <v-text-field
            label="Password"
            id="password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :rules="[rules.required]"
            v-model="password"
          />
          <v-text-field
            label="Confirm Password"
            id="confirm-password"
            :type="showConfirm ? 'text' : 'password'"
            :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirm = !showConfirm"
            :rules="[rules.required]"
            v-model="confirmPassword"
          />
          <div v-if="pdpa !== null">
            <div
              v-for="(item, index) in pdpa"
              :key="index"
              class="pdpa-container"
            >
              <div v-if="item !== null && item.subConsent">
                <v-row
                  class="ma-0"
                  v-for="(pdpaItem, pdpaIndex) in item.subConsent"
                  :key="pdpaIndex"
                >
                  <v-col class="pa-0" cols="1">
                    <v-checkbox v-model="pdpaItem.checkbox" />
                  </v-col>
                  <v-col class="pa-0 align-self-center">
                    <div v-html="parseText(pdpaItem.description)" />
                    <div>
                      รายละเอียด
                      <span class="action-button" @click="viewPdpa(index)"
                        >คลิกที่นี่</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="item !== null && !item.subConsent">
                <v-row class="ma-0">
                  <v-col class="pa-0" cols="1">
                    <v-checkbox v-model="item.checkbox" />
                  </v-col>
                  <v-col class="pa-0 align-self-center">
                    {{ item.agreeText }}
                    <div>
                      รายละเอียด
                      <span class="action-button" @click="viewPdpa(index)"
                        >คลิกที่นี่</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <v-btn
            class="mt-2"
            id="register-button"
            outlined
            rounded
            large
            block
            type="submit"
            :disabled="isLoading || !valid || validateInput || validatePdpa"
            >ลงทะเบียน</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
    <CustomModal
      :modal="modal"
      @confirm="submitAction"
      disableCancel
      :data="confirmData"
    >
      <template v-slot:header>
        <div v-if="pdpaDetail !== null">
          <h4>{{ pdpaDetail.title }}</h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="container" v-if="pdpaDetail !== null">
          <!-- <div v-for="(item, index) in pdpa" :key="index"> -->
          <div v-html="pdpaDetail.content" />
          <!-- </div> -->
        </div>
      </template>
    </CustomModal>
  </v-container>
</template>

<script>
import { forEach, reduce, size } from "lodash";
import Utils from "@/mixin";

import { SetRedirectURL } from "@/plugins/common";

import CustomModal from "@/components/Modal";

export default {
  components: {
    CustomModal,
  },
  mixins: [Utils],
  async created() {
    await localStorage.removeItem("token");
    await this.checkHash();
  },
  computed: {
    validateInput() {
      const { confirmPassword, password } = this;
      return confirmPassword === password ? false : true;
    },
    validatePdpa() {
      const { pdpa } = this;
      let result = false;
      if (pdpa !== null) {
        forEach(pdpa, (i) => {
          if (i.checkbox !== undefined && !i.checkbox) result = true;
          if (i.subConsent) {
            forEach(i.subConsent, (j) => {
              if (!j.checkbox && j.isRequired) result = true;
            });
          }
        });
      }
      return result;
    },
  },
  data: () => ({
    valid: false,
    show: false,
    showConfirm: false,
    name: "",
    email: "",
    phone: "",
    confirmPassword: "",
    password: "",
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        if (re.test(String(email).toLowerCase())) {
          return true;
        }
        return "รูปแบบอีเมลไม่ถูกต้อง";
      },
    },
    token: "",
    query: {},
    modal: false,
    pdpa: null,
    pdpaDetail: null,
    confirmData: {
      confirmText: "ปิด",
    },
  }),
  methods: {
    parseText(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
    async checkHash() {
      // replace url pathname
      const { origin, pathname } = window.location;
      const aLink = document.createElement("a");
      aLink.href = origin + pathname;
      const url = new URL(aLink);

      const redirect = SetRedirectURL(
        this.$route.hash,
        encodeURIComponent(this.$route.fullPath)
      );
      if (redirect.token) {
        this.token = redirect.token;
        this.query = {
          ref: this.$route.query.ref,
        };
        await this.appAccess(this.token).then(async () => {
          await this.checkPdpa();
        });
        // replace url pathname
        window.history.pushState({}, "", url);
      } else {
        window.location = redirect.url;
      }
    },
    async submitForm() {
      const { pdpa } = this;
      const consent = reduce(
        pdpa,
        (a, b) => {
          const SubConsentAccept = reduce(
            b.subConsent,
            (i, o) => {
              i.push({ subConsentId: o.subConsentId, isAccept: o.checkbox });
              return i;
            },
            []
          );
          a.push({ consentId: b.id, isAccept: true, SubConsentAccept });
          return a;
        },
        []
      );
      const formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        // confirmPassword: this.confirmPassword,
        password: this.password,
        inviteRef: this.query?.ref,
        consent,
      };
      // console.log(formData);
      this.register(formData);
    },
    submitAction() {
      this.modal = false;
    },
    async checkPdpa() {
      const res = await this.getUserConsent();
      const pdpa = res?.data;
      const result = [];
      forEach(pdpa, (i) => {
        const { isForce, title, content, subConsent } = i;
        if (isForce) {
          // this.modal = true;
          result.push({
            ...i,
            id: i.id,
            title,
            content,
            subConsent,
          });
        }
      });
      if (size(result) > 0) this.pdpa = result;
    },
    viewPdpa(index) {
      const { pdpa } = this;
      this.pdpaDetail = pdpa[index];
      this.modal = true;
    },
  },
};
</script>

<style lang="scss">
$green: #009b3f;

.register-container {
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .pdpa-container {
    text-align: left;
    i {
      color: $green !important;
    }
  }
  .action-button {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
}
</style>