<template>
  <div class="change-password-container">
    <h2 class="mb-3">เปลี่ยนรหัสผ่าน</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <v-row>
        <v-col offset-md="4" md="4" cols="12">
          <v-form
            class="text-center"
            :disabled="isLoading"
            v-model="valid"
            :lazy-validation="false"
            @submit.prevent="submitForm"
          >
            <v-text-field
              label="รหัสผ่านเก่า*"
              id="old-password"
              :type="showOld ? 'text' : 'password'"
              :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showOld = !showOld"
              :rules="[rules.required]"
              v-model="oldPassword"
            />
            <v-text-field
              label="รหัสผ่านใหม่*"
              id="new-password"
              :type="showNew ? 'text' : 'password'"
              :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showNew = !showNew"
              :rules="[rules.required]"
              v-model="newPassword"
            />
            <v-text-field
              label="ยืนยันรหัสผ่าน*"
              id="confirm-password"
              :type="showConfirm ? 'text' : 'password'"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirm = !showConfirm"
              :rules="[rules.required]"
              v-model="confirmPassword"
            />
            <v-btn
              id="change-password-button"
              color="success"
              type="submit"
              :disabled="isLoading || !valid || checkInput"
              >ยืนยัน</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Utils from "@/mixin";

export default {
  computed: {
    checkInput() {
      const { confirmPassword, newPassword } = this;
      return confirmPassword === newPassword ? false : true;
    },
  },
  data: () => ({
    valid: false,
    showNew: false,
    showOld: false,
    showConfirm: false,
    confirmPassword: "",
    oldPassword: "",
    newPassword: "",
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
    },
  }),
  methods: {
    async submitForm() {
      const { oldPassword, newPassword, confirmPassword } = this;
      const formData = {
        oldPassword,
        newPassword,
        confirmPassword,
      };
      this.changePassword(formData);
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
.change-password-container {
  padding: 20px;
}
</style>