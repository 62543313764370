<template>
  <div class="pdpa-container">
    <h2 class="mb-3">ข้อมูลการยินยอม</h2>
    <v-container>
      <v-row justify="center">
        <v-expansion-panels accordion focusable>
          <v-expansion-panel v-for="(item, index) in pdpa" :key="index">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.content" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { forEach, size } from "lodash";
import Utils from "@/mixin";

export default {
  data: () => ({
    pdpa: null,
  }),
  mixins: [Utils],
  mounted() {
    this.getConsent();
  },
  methods: {
    async getConsent() {
      const res = await this.getCompleteConsent();
      const pdpa = res?.data;
      const result = [];
      forEach(pdpa, (i) => {
        const { isForce, title, content, subConsent } = i;
        if (isForce) {
          this.modal = true;
          result.push({
            ...i,
            id: i.id,
            title,
            content,
            subConsent,
          });
        }
      });
      if (size(result) > 0) {
        this.pdpa = result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdpa-container {
  padding: 20px;
}
</style>