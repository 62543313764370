<template>
  <div class="category-form-container">
    <v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
      <template v-slot:activator="{ attrs }">
        <v-btn color="success" class="mb-2" v-bind="attrs" @click="toggleDialog"
          >+ เพิ่มสินค้าพรีเมี่ยม</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ modalTitle }}สินค้าพรีเมี่ยม</span>
        </v-card-title>

        <v-card-text class="modal-card-text">
          <v-container>
            <v-form ref="form">
              <v-text-field
                v-model="formData.name"
                label="ชื่อสินค้าพรีเมี่ยม*"
                outlined
                dense
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="formData.code"
                label="รหัสสินค้าพรีเมี่ยม*"
                outlined
                dense
                :rules="[rules.required]"
              />
              <GalleryUploadFile
                v-model="formData.image"
                label="เลือกรูปสินค้าพรีเมี่ยม*"
                :max="10"
                :removeable="true"
                :option="upOption"
                accept="image/*"
                @remove="removeImage"
              />
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="clearData">ปิดหน้าต่าง</v-btn>
          <v-btn color="success" @click="submitForm" :disabled="validate"
            >บันทึก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, findIndex } from "lodash";
import Utils from "@/mixin";

import GalleryUploadFile from "@/features/GalleryUploadFile";

export default {
  mixins: [Utils],
  components: {
    GalleryUploadFile,
  },
  computed: {
    validate() {
      const { name, code, image } = this.formData;
      if (!(name && code && image.length !== 0)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    modalTitle: "เพิ่ม",
    data: {},
    initialForm: {
      name: "",
      code: "",
      image: [],
    },
    formData: {
      name: "",
      code: "",
      image: [],
    },
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
    },
    upOption: {
      maxWidth: 1000,
    },
  }),
  methods: {
    removeImage(item, index) {
      const ritem = this.data?.photo?.find((e) => e.uri === item);
      if (ritem?.id) {
        this.removePremiumProductPhoto(ritem).then(() =>
          this.removeImgIndex(index)
        );
        return;
      }
      this.removeImgIndex(index);
    },
    removeImgIndex(index) {
      const imgs = this.formData.image;
      imgs.splice(index, 1);
      this.formData.image = imgs;
    },
    clearData() {
      if (this.$props.editId !== "") this.$props.setInitialDialog();
      this.toggleDialog();
      this.$nextTick(() => {
        this.modalTitle = "เพิ่ม";
        const cloneInit = cloneDeep(this.initialForm);
        this.formData = Object.assign({}, cloneInit);
        if (this.$refs.form) this.$refs.form.resetValidation();
      });
    },
    async submitForm() {
      const { formData } = this;
      const submitForm = { ...formData };
      this.$emit("submit", submitForm);
      this.clearData();
    },
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      default: () => {},
    },
    setInitialDialog: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "แก้ไข";
        const { premiumProducts } = this;
        const index = findIndex(
          premiumProducts.data,
          (i) => i.id === this.editId
        );

        if (index !== -1) {
          const data = premiumProducts.data[index];
          this.data = data;
          this.formData = {
            name: data.name,
            code: data.code,
            image: data.photo?.map((e) => e.uri) || [],
          };
        }
      }
    },
  },
};
</script>