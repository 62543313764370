<template>
  <div :class="`home-container${adminRole ? ' no-padding' : ''}`">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <div v-if="!adminRole">
      <h2 class="mb-3">แดชบอร์ดภาพรวมร้านค้า</h2>
      <v-card class="mb-5 pb-4 dashboard-container elevation-5 rounded-xl">
        <v-card-title>ติดตามออเดอร์และสินค้า</v-card-title>
        <v-card-text>
          <v-row
            v-if="dashboardOrder"
            class="d-flex justify-center text-center"
          >
            <v-col sm="3">
              <div class="title red-text">
                {{ dashboardOrder.status.pending }}
              </div>
              <span
                class="click-section"
                @click="redirectOrder({ status: { pending: true } })"
                >ที่ต้องยืนยัน ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title yellow-text">
                {{ dashboardOrder.paymentStatus.pending }}
              </div>
              <span
                class="click-section"
                @click="redirectOrder({ paymentStatus: { pending: true } })"
                >รอลูกค้าชำระ ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title red-text">
                {{ dashboardOrder.paymentStatus.inform }}
              </div>
              <span
                class="click-section"
                @click="redirectOrder({ paymentStatus: { inform: true } })"
                >ที่ต้องตรวจสอบชำระเงิน ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title red-text">
                {{ dashboardOrder.shipStatus.pending }}
              </div>
              <span
                class="click-section"
                @click="redirectOrder({ shipStatus: { pending: true } })"
                >ที่ต้องจัดส่ง ></span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="dashboardOrder"
            class="d-flex justify-center text-center"
          >
            <v-col sm="3">
              <div class="title yellow-text">
                {{ dashboardOrder.shipStatus.ship }}
              </div>
              <span
                class="click-section"
                @click="redirectOrder({ shipStatus: { ship: true } })"
                >รอลูกค้ารับสินค้า ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title yellow-text">
                {{ dashboardOrder.product.confirm }}
              </div>
              <span class="click-section" @click="redirectProduct"
                >สินค้ารออนุมัติ ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title red-text">
                {{ dashboardOrder.product.edit }}
              </div>
              <span class="click-section" @click="redirectProduct"
                >สินค้าที่ต้องแก้ไข ></span
              >
            </v-col>
            <v-col sm="3">
              <div class="title red-text">
                {{ dashboardOrder.product.suspend }}
              </div>
              <span class="click-section" @click="redirectProduct"
                >สินค้าถูกระงับ ></span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-5 dashboard-container elevation-5 rounded-xl">
        <v-card-title>ภาพรวมยอดขาย</v-card-title>
        <v-card-text>
          <v-row
            v-if="overallOrder"
            class="mb-3 d-flex justify-center text-center"
          >
            <v-col sm="4">
              <div class="title green-text">
                {{ numberWithCommas(overallOrder.day) }}
              </div>
              <span>ยอดขายวันนี้</span>
            </v-col>
            <v-col sm="4">
              <div class="title green-text">
                {{ numberWithCommas(overallOrder.month) }}
              </div>
              <span>ยอดขายเดือนนี้</span>
            </v-col>
            <v-col sm="4">
              <div class="title yellow-text">
                {{ numberWithCommas(overallOrder.overdue) }}
              </div>
              <span>ยอดค้างชำระ</span>
            </v-col>
          </v-row>
          <h3>ยอดขายย้อนหลัง 6 เดือน</h3>
          <div v-if="labels.length > 0 && chartData.length > 0">
            <LineChart :labels="labels" :data="chartData" />
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mb-5 dashboard-container elevation-5 rounded-xl">
        <v-card-title
          >สินค้าขายดี 10 อันดับ
          <div class="ml-auto d-flex align-center">
            <v-checkbox
              class="mt-0 mr-4"
              label="เฉพาะสินค้าไทยเบฟ"
              v-model="thaibevBestSale"
              color="#009b3f"
              hide-details
            />
            <v-btn-toggle
              v-model="monthBestSale"
              dense
              color="#009b3f"
              mandatory
            >
              <v-btn>เดือนนี้</v-btn>
              <v-btn>ทั้งหมด</v-btn>
            </v-btn-toggle>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="isLoading"
            :headers="headersBestSale"
            :items="bestsaleList"
            :server-items-length="bestsaleList.length || total"
            hide-default-footer
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:item.name="{ item }">
              <div class="d-inline-flex align-center">
                <v-img
                  :src="item.photo"
                  contain
                  width="100"
                  height="100"
                  @click="showImg(item.photo)"
                />
                <div>{{ item.name }}</div>
              </div>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberWithCommas(item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card
        class="mb-5 dashboard-container elevation-5 rounded-xl"
        v-if="jwtToken.shop && jwtToken.shop.shopType === 'agent'"
      >
        <v-card-title
          >ยอดซื้อซับเอเย่นต์ 10 อันดับ
          <div class="ml-auto d-flex align-center">
            <v-checkbox
              class="mt-0 mr-4"
              label="เฉพาะสินค้าไทยเบฟ"
              v-model="thaibevTopSale"
              color="#009b3f"
              hide-details
            />
            <v-btn-toggle
              v-model="monthTopSale"
              dense
              color="#009b3f"
              mandatory
            >
              <v-btn>เดือนนี้</v-btn>
              <v-btn>ทั้งหมด</v-btn>
            </v-btn-toggle>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="isLoading"
            :headers="headersTopSale"
            :items="topsaleList"
            :server-items-length="topsaleList.length || total"
            hide-default-footer
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:item.amount="{ item }">
              {{ numberWithCommas(item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card class="mb-5 dashboard-container elevation-5 rounded-xl">
        <v-card-title
          >ยอดซื้อร้านค้าปลีก 10 อันดับ
          <div class="ml-auto d-flex align-center">
            <v-checkbox
              class="mt-0 mr-4"
              label="เฉพาะสินค้าไทยเบฟ"
              v-model="thaibevTopShop"
              color="#009b3f"
              hide-details
            />
            <v-btn-toggle
              v-model="monthTopShop"
              dense
              color="#009b3f"
              mandatory
            >
              <v-btn>เดือนนี้</v-btn>
              <v-btn>ทั้งหมด</v-btn>
            </v-btn-toggle>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="isLoading"
            :headers="headersTopShop"
            :items="topshopList"
            :server-items-length="topshopList.length || total"
            hide-default-footer
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:item.amount="{ item }">
              {{ numberWithCommas(item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <div v-if="ciReport !== null">
        <CIReport
          :accessToken="ciReport.accessToken"
          :embedUrl="ciReport.embedUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, reduce } from "lodash";
import Utils from "@/mixin";

import LightBox from "@/components/LightBox.vue";
import LineChart from "@/components/LineChart.vue";
import CIReport from "@/features/CIReport.vue";

import { getJWTToken, PERMISSION } from "@/plugins/auth";

export default {
  name: "Home",
  components: {
    LightBox,
    LineChart,
    CIReport,
  },
  computed: {
    dashboardOrder() {
      const { orderDashboard } = this;
      let result = {
        status: {
          pending: 0,
        },
        paymentStatus: {
          pending: 0,
          inform: 0,
        },
        shipStatus: {
          pending: 0,
          ship: 0,
        },
        product: {
          confirm: 0,
          edit: 0,
          suspend: 0,
        },
      };
      if (orderDashboard !== null) {
        const { order, product } = orderDashboard;
        result = {
          ...result,
          product,
          status: {
            pending: order?.confirmOrder,
          },
          paymentStatus: {
            pending: order?.payment,
            inform: order?.confirmPayment,
          },
          shipStatus: {
            pending: order?.receive,
            ship: order?.ship,
          },
        };
      }
      return result;
    },
    overallOrder() {
      const { orderDashboard } = this;
      let result = {
        day: 0,
        month: 0,
        overdue: 0,
      };
      if (orderDashboard !== null) {
        const { daySales, monthSales, overdue } = orderDashboard?.order;
        result = {
          day: daySales,
          month: monthSales,
          overdue,
        };
      }
      return result;
    },
    chartData() {
      const { orderDashboard } = this;
      let result = [];
      if (orderDashboard !== null) {
        const { monthly } = orderDashboard;
        const cloneMonth = cloneDeep(monthly);
        const sortMonth = cloneMonth.sort((a, b) => a.month - b.month);
        result = reduce(
          sortMonth,
          (a, b) => {
            a.push(b.net);
            return a;
          },
          []
        );
      }
      return result;
    },
    labels() {
      const { orderDashboard } = this;
      let result = [];
      if (orderDashboard !== null) {
        const { monthly } = orderDashboard;
        const cloneMonth = cloneDeep(monthly);
        const sortMonth = cloneMonth.sort((a, b) => a.month - b.month);
        const monthNamesThai = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ];
        result = reduce(
          sortMonth,
          (a, b) => {
            a.push(
              `${monthNamesThai[b.month - 1]} ${(b.year + 543)
                .toString()
                .substring(2, 4)}`
            );
            return a;
          },
          []
        );
      }
      return result;
    },
    bestsaleList() {
      const { orderBestSallers } = this;
      const result = reduce(
        orderBestSallers,
        (a, b, i) => {
          a.push({ ...b, orderNo: i + 1 });
          return a;
        },
        []
      );
      return result;
    },
    topsaleList() {
      const { orderTopSallers } = this;
      const result = reduce(
        orderTopSallers,
        (a, b, i) => {
          a.push({ ...b, orderNo: i + 1 });
          return a;
        },
        []
      );
      return result;
    },
    topshopList() {
      const { orderTopShop } = this;
      const result = reduce(
        orderTopShop,
        (a, b, i) => {
          a.push({ ...b, orderNo: i + 1 });
          return a;
        },
        []
      );
      return result;
    },
  },
  created() {
    this.jwtToken = getJWTToken();
  },
  data: () => ({
    total: 0,
    thaibevBestSale: false,
    monthBestSale: 1,
    headersBestSale: [
      { text: "อันดับ", value: "orderNo", sortable: false },
      { text: "สินค้า", value: "name", sortable: false },
      { text: "ยอดขาย", value: "amount", sortable: false },
    ],
    thaibevTopSale: false,
    monthTopSale: 1,
    headersTopSale: [
      { text: "อันดับ", value: "orderNo", sortable: false },
      { text: "ซับเอเย่นต์", value: "name", sortable: false },
      { text: "ยอดซื้อ", value: "amount", sortable: false },
    ],
    thaibevTopShop: false,
    monthTopShop: 1,
    headersTopShop: [
      { text: "อันดับ", value: "orderNo", sortable: false },
      { text: "ร้านค้าปลีก", value: "name", sortable: false },
      { text: "ยอดซื้อ", value: "amount", sortable: false },
    ],
    imgArr: [],
    imgShow: false,
    adminRole: false,
    jwtToken: null,
    ciReport: null,
  }),
  methods: {
    async getInitialData() {
      await this.getOrderDashboard();
      await this.getBestSale();
      await this.getTopSale();
      await this.getTopShop();
    },
    async getBestSale() {
      const { thaibevBestSale, monthBestSale } = this;
      await this.getOrderBestSellers({
        master: thaibevBestSale,
        month: monthBestSale === 0 ? 1 : 0,
      });
    },
    async getTopSale() {
      const { thaibevTopSale, monthTopSale } = this;
      await this.getOrderTopSellers({
        master: thaibevTopSale,
        month: monthTopSale === 0 ? 1 : 0,
        shopType: "sub-agent",
      });
    },
    async getTopShop() {
      const { thaibevTopShop, monthTopShop } = this;
      await this.getOrderTopShops({
        master: thaibevTopShop,
        month: monthTopShop === 0 ? 1 : 0,
        shopType: "retail",
      });
    },
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    redirectOrder(filterOrder) {
      this.$router.push({ name: "Order", params: { filterOrder } });
    },
    redirectProduct() {
      this.$router.push({ name: "Agent-Product" });
    },
    numberWithCommas(number) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const result = formatter.format(number);
      return result;
    },
  },
  async mounted() {
    const { selectedShop, jwtToken } = this;
    switch (jwtToken?.role) {
      case PERMISSION.APP_ADMIN: {
        this.adminRole = true;
        const res = await this.getCIDashboard();
        this.ciReport = {
          accessToken: res.token,
          embedUrl: res.embedUrl,
        };
        break;
      }
      case PERMISSION.SHOP_ADMIN: {
        if (selectedShop !== "") this.getInitialData();
        break;
      }
      default: {
        break;
      }
    }
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        const { jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      }
    },
    thaibevBestSale: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getBestSale();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    thaibevTopSale: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getTopSale();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    monthBestSale: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getBestSale();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    monthTopSale: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getTopSale();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scope>
$green: #009b3f;
$black: #424242;
$red: #da291c;
$yellow: #e97700;
$white: rgba(255, 255, 255, 1);

.home-container {
  padding: 20px;
  &.no-padding {
    padding: 0px;
  }
  .dashboard-container {
    .v-card__title {
      color: $black;
      font-size: 18px;
      font-weight: bold;
      .v-input {
        .v-label {
          color: $black;
          font-weight: normal;
        }
      }
      .append-switch {
        font-size: 16px;
        padding-top: 4px;
      }
      .v-btn-toggle {
        .v-btn {
          border-radius: inherit;
          &.v-btn--active {
            &::before {
              opacity: 1;
            }
            span {
              color: $white;
            }
          }
        }
      }
    }
    .v-card__text {
      color: $black;
      .title {
        &.green-text {
          color: $green;
          font-weight: bold;
        }
        &.red-text {
          color: $red;
          font-weight: bold;
        }
        &.yellow-text {
          color: $yellow;
          font-weight: bold;
        }
      }
      .click-section {
        cursor: pointer;
      }
    }
  }
}
</style>