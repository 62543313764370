<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    labels: {
      type: Array,
    },
    data: {
      type: Array,
    },
  },
  mounted() {
    const { labels, data } = this;
    this.renderChart(
      {
        labels,
        datasets: [
          {
            label: "ยอดขาย",
            data,
            backgroundColor: "transparent",
            borderColor: "#009b3f",
            pointBackgroundColor: "#009b3f",
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              const formatter = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const result = formatter.format(value);
              return result;
            },
          }, // end callbacks:
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                userCallback: function (value) {
                  const formatter = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  const result = formatter.format(value);
                  return result;
                },
              },
            },
          ],
        },
      }
    );
  },
};
</script>

<style lang="scss" scoped>
#line-chart {
  height: 400px !important;
  @media (max-width: 600px) {
    height: 200px !important;
  }
}
</style>