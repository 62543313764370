import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  promotions: {
    data: [],
    total: 0,
  },
  pointPromotions: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/promotions`,
        data.form
      )
      state.promotions.data = res?.data?.data || []
      state.promotions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_POINT_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/promotion-points`, data.form)
      state.pointPromotions.data = res?.data?.data || []
      state.pointPromotions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/promotions`,
        data.form
      )
      state.promotions.data.push(res.data)
      state.promotions.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_POINT_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(`/api/promotion-points`, data.form)
      state.pointPromotions.data.push(res.data)
      state.pointPromotions.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/shops/${data.selectedShop}/promotions/${data.form?.id}`,
        data.form
      )

      const promotions = cloneDeep(state.promotions.data)
      const index = findIndex(promotions, { id: res?.data?.id })

      promotions[index] = res.data
      state.promotions = {
        ...state.promotions,
        data: promotions,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_POINT_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/promotion-points/${data.form?.id}`,
        data.form
      )

      const pointPromotions = cloneDeep(state.pointPromotions.data)
      const index = findIndex(pointPromotions, { id: res?.data?.id })

      pointPromotions[index] = res.data
      state.pointPromotions = {
        ...state.pointPromotions,
        data: pointPromotions,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(
        `/api/shops/${data.selectedShop}/promotions/${data.form?.id}`
      )

      const promotions = cloneDeep(state.promotions.data)
      const index = findIndex(promotions, { id: data.form?.id })

      promotions.splice(index, 1)
      state.promotions = {
        ...state.promotions,
        data: promotions,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_POINT_PROMOTION(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/promotion-points/${data.form?.id}`)

      const pointPromotions = cloneDeep(state.pointPromotions.data)
      const index = findIndex(pointPromotions, { id: data.form?.id })

      pointPromotions.splice(index, 1)
      state.pointPromotions = {
        ...state.pointPromotions,
        data: pointPromotions,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_PROMOTIONS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  getPointPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_POINT_PROMOTIONS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  createPromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.CREATE_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  createPointPromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.CREATE_POINT_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  editPromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EDIT_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  editPointPromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.EDIT_POINT_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  deletePromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.DELETE_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  deletePointPromotion(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.DELETE_POINT_PROMOTION(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  promotions(state) {
    // eslint-disable-line
    return state.promotions
  },
  pointPromotions(state) {
    // eslint-disable-line
    return state.pointPromotions
  },
}

export const promotions = {
  state,
  mutations,
  actions,
  getters,
}
