import { mapActions, mapGetters } from 'vuex'

//Global computed and methods
export default {
  //Store getters
  computed: mapGetters([
    'isLoading',
    'alert',
    'auth',
    'shopList',
    'selectedShop',
    'companies',
    'categories',
    'brands',
    'producttypes',
    'products',
    'productPhotos',
    'buyersList',
    'paymentMethodsList',
    'banksList',
    'accountBanksList',
    'paymentTypeList',
    'orders',
    'orderList',
    'orderPaymentList',
    // 'orderShipmentList',
    'orderHistoryList',
    'sellersList',
    'orderDetail',
    'regionList',
    'orderDashboard',
    'orderBestSallers',
    'orderTopSallers',
    'orderTopShop',
    'notificationList',
    'orderShipmentImageList',
    'shopTypeList',
    'promotions',
    'coupons',
    'premiumProducts',
    'premiumProductPhotos',
    'couponTransactions',
    'pointPromotions',
  ]),
  //Store actions
  methods: {
    ...mapActions([
      'toggleLoading',
      'toggleAlertMessage',
      'appAccess',
      'login',
      'register',
      'logout',
      'forgotPassword',
      'resetPassword',
      'updateShopList',
      'selectedShopList',
      'getCompanies',
      'createCompany',
      'editCompany',
      'deleteCompany',
      'getCategories',
      'createCategory',
      'editCategory',
      'deleteCategory',
      'getBrands',
      'createBrand',
      'editBrand',
      'deleteBrand',
      'getProductTypes',
      'createProductType',
      'editProductType',
      'deleteProductType',
      'getProducts',
      'createProduct',
      'editProduct',
      'deleteProduct',
      'uploadProductPhoto',
      'removeProductPhoto',
      'getBuyers',
      'updateBuyers',
      'getPaymentMethods',
      'updatePaymentMethods',
      'getBanks',
      'getBankAccounts',
      'createBankAccount',
      'editBankAccount',
      'deleteBankAccounts',
      'getShopProducts',
      'getShopBrands',
      'transferProduct',
      'getPaymentTypes',
      'updatePaymentTypes',
      'createAgentProduct',
      'getAgentProducts',
      'updateProductStatus',
      'editAgentProduct',
      'getAdminOrders',
      'getOrders',
      'getOrdersList',
      'approveOrder',
      'cancelOrder',
      'getOrderPayment',
      'approvePayment',
      // 'getOrderShipment',
      'uploadShipment',
      'getOrderHistory',
      'editOrder',
      'changePassword',
      'getAdminOrdersList',
      'getAdminOrderPayment',
      'getAdminOrderHistory',
      'getPrice',
      'getAdminBuyers',
      'getAdminSellers',
      'getOrderIndex',
      'getAdminOrderIndex',
      'getRegions',
      'exportAdminOrder',
      'exportOrders',
      'getOrderDashboard',
      'getOrderBestSellers',
      'getOrderTopSellers',
      'getOrderTopShops',
      'getCIDashboard',
      'getRetailDashboard',
      'getCouponDashboard',
      'checkUserConsent',
      'acceptUserConsent',
      'getUserConsent',
      'getCompleteConsent',
      'getNotification',
      'uploadShipmentImage',
      'deleteShipmentImage',
      'getShipmentImage',
      'getShopTypes',
      'getPromotions',
      'createPromotion',
      'editPromotion',
      'deletePromotion',
      'getCoupons',
      'getAdminCoupons',
      'createCoupon',
      'editCoupon',
      'deleteCoupon',
      'getPremiumProducts',
      'createPremiumProduct',
      'editPremiumProduct',
      'deletePremiumProduct',
      'uploadPremiumProductPhoto',
      'removePremiumProductPhoto',
      'getCouponTransactions',
      'getAdminCouponTransactions',
      'getCouponBuyers',
      'getCouponSellers',
      'getPointPromotions',
      'createPointPromotion',
      'editPointPromotion',
      'deletePointPromotion',
      'exportCoupons',
      'exportAdminCoupons',
    ]),
  },
}
