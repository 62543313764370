<template>
  <div class="product-form-container">
    <v-dialog v-model="dialog" max-width="800px" @click:outside="clearData">
      <template v-slot:activator="{ attrs }">
        <v-btn
          v-if="!noCreateButton"
          color="success"
          class="mb-2"
          v-bind="attrs"
          :disabled="agentReadOnly"
          @click="toggleDialog"
          >+ เพิ่มสินค้า</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ modalTitle }}สินค้า</span>
        </v-card-title>

        <v-card-text class="modal-card-text">
          <v-container>
            <v-form ref="form">
              <v-card class="pa-4">
                <div class="title mb-4">ข้อมูลทั่วไป</div>
                <v-text-field
                  v-model="formData.name"
                  label="ชื่อสินค้า*"
                  outlined
                  dense
                  :disabled="agentReadOnly"
                  :rules="[rules.required]"
                />
                <v-text-field
                  v-model="formData.code"
                  label="รหัสสินค้า (SKU)*"
                  outlined
                  dense
                  :disabled="agentReadOnly"
                  :rules="[rules.required]"
                />
                <v-textarea
                  v-model="formData.description"
                  label="รายละเอียดสินค้า*"
                  outlined
                  dense
                  :disabled="agentReadOnly"
                  :rules="[rules.required]"
                />
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="formData.producttype"
                      item-value="id"
                      item-text="name"
                      :items="producttypes.data || []"
                      :rules="[rules.required]"
                      outlined
                      dense
                      label="ประเภทสินค้า*"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="formData.brand"
                      item-value="id"
                      item-text="name"
                      :items="brands.data || []"
                      :rules="[rules.required]"
                      outlined
                      dense
                      label="แบรนด์*"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="formData.categoryId"
                      item-value="id"
                      item-text="name"
                      :items="categories.data || []"
                      label="หมวดหมู่"
                      multiple
                      chips
                      outlined
                      hide-details
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch
                      color="#009b3f"
                      dense
                      inset
                      hide-details
                      label="สินค้ามีแอลกอฮอล์"
                      v-model="formData.alcohol"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mt-6 pa-4">
                <div class="title mb-4">ข้อมูลการขาย</div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="formData.price"
                      label="ราคาเต็ม*"
                      type="number"
                      min="0"
                      outlined
                      dense
                      :rules="[rules.min]"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="formData.salePrice"
                      label="ราคาขาย*"
                      type="number"
                      min="0"
                      outlined
                      dense
                      :rules="[rules.min]"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="formData.unit"
                      label="หน่วย*"
                      type="text"
                      min="0"
                      outlined
                      dense
                      :rules="[rules.required]"
                      :disabled="agentReadOnly"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mt-6 pa-4">
                <div class="title mb-4">ราคาขายส่ง</div>
                <v-row v-if="formData.stepPrice.length > 0">
                  <v-col offset="3" cols="3" class="text-center">
                    ขั้นต่ำ
                  </v-col>
                  <v-col cols="3" class="text-center"> สูงสุด </v-col>
                  <v-col cols="3" class="text-center"> ราคา </v-col>
                </v-row>
                <div v-for="(item, index) in formData.stepPrice" :key="index">
                  <v-row>
                    <v-col cols="3" sm="2" class="text-center mt-2"
                      >{{ index + 1 }}. ช่วงราคาที่ {{ index + 1 }}</v-col
                    >
                    <v-col cols="3">
                      <v-text-field
                        class="number-input"
                        v-model="item.min"
                        label="ขั้นต่ำ"
                        outlined
                        :disabled="index !== 0 || agentReadOnly"
                        dense
                        @change="changeNewValueStatus(index)"
                        @keypress="validateNumeric"
                        single-line
                        :rules="[rules.limitMin]"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        class="number-input"
                        v-model="item.max"
                        label="สูงสุด"
                        dense
                        outlined
                        :disabled="agentReadOnly"
                        @change="(e) => onKeyChange(e, index)"
                        @keypress="validateNumeric"
                        single-line
                        :error-messages="
                          (item.max === '' ||
                            item.max === null ||
                            item.max === undefined) &&
                          index + 1 === formData.stepPrice.length
                            ? ''
                            : checkValue(index)
                        "
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        class="number-input"
                        v-model="item.price"
                        label="ราคาเต็ม*"
                        prefix="฿"
                        outlined
                        dense
                        single-line
                        :rules="[rules.min]"
                        :disabled="agentReadOnly"
                      />
                    </v-col>
                    <v-col cols="12" sm="1" class="text-center">
                      <v-btn
                        color="error"
                        icon
                        :disabled="agentReadOnly"
                        @click="deleteItem"
                        v-if="index + 1 === formData.stepPrice.length"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-btn
                  block
                  outlined
                  dense
                  color="#009b3f"
                  class="border-dashed"
                  @click="addItem"
                  :disabled="agentReadOnly"
                  ><v-icon>mdi-plus-circle-outline</v-icon
                  ><span class="ml-2">เพิ่มช่วงราคา</span></v-btn
                >
              </v-card>
              <v-card class="mt-6 pa-4">
                <div class="title mb-4">ภาพสินค้า*</div>
                <GalleryUploadFile
                  :disabled="agentReadOnly"
                  v-model="formData.image"
                  :max="5"
                  :removeable="true"
                  :option="upOption"
                  accept="image/*"
                  @remove="removeImage"
                />
              </v-card>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="clearData">ปิดหน้าต่าง</v-btn>
          <v-btn
            color="success"
            @click="submitForm"
            :disabled="validate || agentReadOnly"
            >บันทึก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep, forEach, findIndex, size } from "lodash";
import Utils from "@/mixin";

import GalleryUploadFile from "@/features/GalleryUploadFile";

export default {
  components: {
    GalleryUploadFile,
  },
  mixins: [Utils],
  computed: {
    validate() {
      const {
        name,
        code,
        description,
        brand,
        producttype,
        unit,
        image,
        price,
        salePrice,
        stepPrice,
      } = this.formData;
      let result = false;
      if (size(stepPrice) > 0) {
        forEach(stepPrice, (i) => {
          if (parseInt(i.min) > parseInt(i.max) || parseInt(i.price) === 0) {
            result = true;
          }
        });
        if (stepPrice[0].min < 2) {
          result = true;
        }
      } else {
        result = false;
      }
      if (
        !(
          name &&
          code &&
          brand &&
          producttype &&
          description &&
          unit &&
          price > 0 &&
          salePrice > 0 &&
          image.find((e) => !!e)
        )
      ) {
        result = true;
      }
      return result;
    },
  },
  created() {
    this.getInitialData();
  },
  data: () => ({
    modalTitle: "เพิ่ม",
    data: {},
    initialForm: {
      name: "",
      code: "",
      description: "",
      brand: "",
      producttype: "",
      categoryId: [],
      image: [],
      price: 0,
      salePrice: 0,
      unit: "",
      stepPrice: [],
      alcohol: false,
    },
    formData: {
      name: "",
      code: "",
      description: "",
      brand: "",
      producttype: "",
      categoryId: [],
      image: [],
      price: 0,
      salePrice: 0,
      unit: "",
      stepPrice: [],
      alcohol: false,
    },
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
      min: (value) => value > 0 || "กรุณากรอกมากกว่า 0",
      limitMin: (value) => value >= 2 || "กรุณากรอกมากกว่าเท่ากับ 2",
    },
    upOption: {
      maxWidth: 1000,
    },
    deleteStepPrice: [],
  }),
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    removeImage(item, index) {
      const ritem = this.data?.productPhotos?.find((e) => e.url === item);

      if (ritem?.id) {
        this.removeProductPhoto(ritem).then(() => this.removeImgIndex(index));
        return;
      }
      this.removeImgIndex(index);
    },
    removeImgIndex(index) {
      const imgs = this.formData.image;
      imgs.splice(index, 1);
      this.formData.image = imgs;
    },
    async getInitialData() {
      await this.getBrands({ limit: 500 });
      await this.getCategories({});
    },
    clearData() {
      if (this.$props.editId !== "") this.$props.setInitialDialog();
      this.toggleDialog();
      this.$nextTick(() => {
        this.modalTitle = "เพิ่ม";
        const cloneInit = cloneDeep(this.initialForm);
        this.formData = Object.assign({}, cloneInit);
        this.deleteStepPrice = [];
        if (size(this.formData.stepPrice) > 0) this.formData.stepPrice = [];
        if (this.$refs.form) this.$refs.form.resetValidation();
      });
    },
    async submitForm() {
      const { formData, deleteStepPrice } = this;
      const submitForm = { ...formData, deleteStepPrice };
      this.$emit("submit", submitForm);
      this.clearData();
    },
    addItem() {
      const { stepPrice } = this.formData;
      let item = { min: 0, max: 0, price: 0, newValue: true };
      if (size(stepPrice) > 0) {
        item = {
          min: parseInt(stepPrice[size(stepPrice) - 1].max) + 1,
          max: 0,
          price: 0,
          newValue: true,
        };
      }
      this.formData.stepPrice.push(item);
    },
    deleteItem() {
      const { stepPrice } = this.formData;
      const paramId = stepPrice[stepPrice.length - 1].id;
      if (paramId !== undefined) this.deleteStepPrice.push(paramId);
      this.formData.stepPrice.pop();
    },
    onKeyChange(e, index) {
      const { stepPrice } = this.formData;
      if (size(stepPrice) - 1 > index) {
        this.formData.stepPrice[index + 1].min = parseInt(e) + 1;
      }
      this.changeNewValueStatus(index);
    },
    changeNewValueStatus(index) {
      this.formData.stepPrice[index].newValue = false;
    },
    checkValue(index) {
      const { stepPrice } = this.formData;
      const valid =
        parseInt(stepPrice[index].min) <= parseInt(stepPrice[index].max);
      const checkNewValue = stepPrice[index].newValue;
      return !valid && !checkNewValue ? "ไม่น้อยกว่าค่าต่ำสุด" : "";
    },
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    noCreateButton: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      default: () => {},
    },
    setInitialDialog: {
      type: Function,
      default: () => {},
    },
    agentReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "แก้ไข";
        const { products } = this;
        const cloneProducts = cloneDeep(products);
        const index = findIndex(
          cloneProducts?.data || [],
          (i) => i.id === this.editId
        );

        if (index !== -1) {
          const data = cloneProducts?.data?.[index] || {};
          this.data = data;

          this.formData = {
            name: data.name,
            code: data.sku,
            description: data.description,
            brand: data.brandId,
            producttype: data.productTypeId,
            categoryId: data?.categories?.map((e) => e.id),
            price: data.price,
            unit: data.unit,
            salePrice: data.salePrice,
            image: data.productPhotos?.map((e) => e.url) || [],
            stepPrice: data.productStepPrices || [],
            alcohol: data.alcohol,
          };
        }
      }
    },
  },
};
</script>

<style lang="scss">
.product-form-container {
  .title {
    font-size: 14px;
  }
}

.number-input {
  input {
    text-align: right;
  }
}
</style>