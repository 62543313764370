<template>
  <div class="agent-product-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">รายการสินค้า</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="dataList"
        :server-items-length="products ? products.total : total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" :height="checkScreen">
            <v-row>
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="12" lg="8">
                    <v-text-field
                      :disabled="isLoading"
                      v-model="keyword"
                      label="ค้นหาจาก"
                      single-line
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-combobox
                      v-model="category"
                      :items="categoryItems"
                      label="หมวดหมู่"
                      outlined
                      single-line
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="2">
                <v-btn
                  outlined
                  depressed
                  :block="checkMdScreen"
                  @click="searchKeyword"
                  color="#009b3f"
                  >ค้นหา</v-btn
                >
              </v-col>
              <v-col lg="4">
                <div class="d-flex justify-end">
                  <ProductForm
                    :editId="editId"
                    :dialog="dialog"
                    :toggleDialog="toggleDialog"
                    :setInitialDialog="setInitialDialog"
                    @submit="submit"
                  />
                  <v-btn
                    class="ml-2 download-button"
                    color="#C8E6C9"
                    @click="openProductModal"
                  >
                    <v-icon left> mdi-download </v-icon>เพิ่มสินค้าไทยเบฟ
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.productPhotosUrl="{ item }">
          <v-img
            :src="item.productPhotosUrl"
            contain
            width="100"
            height="100"
            @click="
              item.productPhotosArray.length > 0
                ? showImg(item.productPhotosArray)
                : () => {}
            "
          />
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.price="{ item }">
          <div v-if="item.salePrice !== item.price">
            <b
              class="red--text"
              v-if="
                item.productStepPrices && item.productStepPrices.length === 0
              "
              >{{ $numberWithCommas(item.salePrice) }}</b
            >
            <div
              v-if="item.productStepPrices && item.productStepPrices.length > 0"
            >
              {{ $numberWithCommas(calStep(item).min) }} ~
              {{ $numberWithCommas(calStep(item).max) }}
            </div>
            <div class="text-decoration-line-through" v-else>
              {{ $numberWithCommas(item.price) }}
            </div>
          </div>
          <div v-else>
            <span
              v-if="item.productStepPrices && item.productStepPrices.length > 0"
            >
              {{ $numberWithCommas(calStep(item).min) }} ~
              {{ $numberWithCommas(calStep(item).max) }}</span
            >
            <span v-else>{{ $numberWithCommas(item.salePrice) }}</span>
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            v-if="item.status === 'อนุมัติ'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="อนุมัติ"
          />
          <StatusChip
            v-else-if="item.status === 'รออนุมัติ'"
            color="#fff2cd"
            text-color="#f67b00"
            chipText="รออนุมัติ"
          />
          <StatusChip
            v-else-if="item.status !== 'รออนุมัติ' && item.status !== 'อนุมัติ'"
            color="#f4cbcc"
            text-color="#da291c"
            :chipText="item.status"
          />
        </template>
        <template v-slot:item.product_status="{ item }">
          <StatusChip
            v-if="item.product_status === 'วางขาย'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="วางขาย"
          />
          <StatusChip
            v-else
            color="#f3f3f3ff"
            text-color="#666666"
            :chipText="item.product_status"
          />
        </template>
        <template v-slot:item.alcohol="{ item }">
          <v-icon v-if="!item.alcohol" color="red">mdi-close</v-icon>
          <v-icon v-else color="green">mdi-check</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
          <v-btn depressed text color="error" @click="deleteItem(item)"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <CustomModal
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :modal="productDialog"
      @confirm="closeProductModal"
      disableCancel
      disableBottomAction
      confirmIcon
    >
      <template v-slot:actionHeader>
        <h3 class="text-black">สินค้าไทยเบฟ</h3>
      </template>
      <template v-slot:body>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="dataList"
          :server-items-length="products ? products.total : total"
          :footer-props="{
            itemsPerPageOptions,
          }"
          :options.sync="options"
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" :height="checkModalScreen">
              <v-row>
                <v-col cols="12" md="10" lg="6">
                  <v-row>
                    <v-col cols="12" lg="8">
                      <v-text-field
                        :disabled="isLoading"
                        v-model="keyword"
                        label="ค้นหาจาก"
                        single-line
                        outlined
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-combobox
                        v-model="category"
                        :items="categoryItems"
                        label="หมวดหมู่"
                        outlined
                        single-line
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    outlined
                    depressed
                    @click="searchKeyword"
                    color="#009b3f"
                    :block="checkMdScreen"
                    >ค้นหา</v-btn
                  >
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.productPhotosUrl="{ item }">
            <v-img
              :src="item.productPhotosUrl"
              contain
              width="100"
              height="100"
              @click="
                item.productPhotosArray.length > 0
                  ? showImg(item.productPhotosArray)
                  : () => {}
              "
            />
          </template>
          <template v-slot:item.name="{ item }">
            <b class="success--text">{{ item.name }}</b>
          </template>
          <template v-slot:item.price="{ item }">
            <div v-if="item.salePrice !== item.price">
              <b
                class="red--text"
                v-if="
                  item.productStepPrices && item.productStepPrices.length === 0
                "
                >{{ $numberWithCommas(item.salePrice) }}</b
              >
              <div
                v-if="
                  item.productStepPrices && item.productStepPrices.length > 0
                "
              >
                {{ $numberWithCommas(calStep(item).min) }} ~
                {{ $numberWithCommas(calStep(item).max) }}
              </div>
              <div class="text-decoration-line-through" v-else>
                {{ $numberWithCommas(item.price) }}
              </div>
            </div>
            <div v-else>
              <span
                v-if="
                  item.productStepPrices && item.productStepPrices.length > 0
                "
              >
                {{ $numberWithCommas(calStep(item).min) }} ~
                {{ $numberWithCommas(calStep(item).max) }}</span
              >
              <span v-else>{{ $numberWithCommas(item.salePrice) }}</span>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip
              v-if="item.status === 'อนุมัติ'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="อนุมัติ"
            />
            <StatusChip
              v-else-if="item.status === 'รออนุมัติ'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="รออนุมัติ"
            />
            <StatusChip
              v-else-if="
                item.status !== 'รออนุมัติ' && item.status !== 'อนุมัติ'
              "
              color="#f4cbcc"
              text-color="#da291c"
              :chipText="item.status"
            />
          </template>
          <template v-slot:item.product_status="{ item }">
            <StatusChip
              v-if="item.product_status === 'วางขาย'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="วางขาย"
            />
            <StatusChip
              v-else
              color="#f3f3f3ff"
              text-color="#666666"
              :chipText="item.product_status"
            />
          </template>
          <template v-slot:item.alcohol="{ item }">
            <v-icon v-if="!item.alcohol" color="red">mdi-close</v-icon>
            <v-icon v-else color="green">mdi-check</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              depressed
              text
              color="success"
              @click="cloneProduct(item.id)"
              :disabled="!!item.child"
            >
              <b>{{ !!item.child ? "สินค้าเข้าแล้ว" : "นำสินค้าเข้า" }}</b>
            </v-btn>
          </template>
        </v-data-table>
      </template>
    </CustomModal>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import { forEach, get, reduce, size } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";
import { calStep } from "@/utils/product";

import CustomModal from "@/components/Modal";
import StatusChip from "@/components/StatusChip";
import LightBox from "@/components/LightBox.vue";

import ProductForm from "./Product/Form";
import Confirm from "@/components/Confirm";

export default {
  name: "Agent-Product",
  components: {
    Confirm,
    CustomModal,
    LightBox,
    StatusChip,
    ProductForm,
  },
  computed: {
    dataList() {
      const { products } = this;
      const result = reduce(
        products.data,
        (o, e) => {
          let productPhotosArray = [];
          let categoriesName = "";
          let count = 1;
          forEach(e.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          forEach(e.categories, (i) => {
            categoriesName += i.name;
            if (count < size(e.categories)) {
              categoriesName += ", ";
              count++;
            }
          });
          o.push({
            ...e,
            productPhotosArray,
            productPhotosUrl: e.productPhotos?.[0]?.url || "",
            categoriesName,
          });
          return o;
        },
        []
      );
      return result;
    },
    categoryItems() {
      const { categories } = this;
      const result = reduce(
        categories.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 1264) {
        return "64px";
      } else if (windowWidth < 1264) {
        return "250px";
      }
      return "0px";
    },
    checkModalScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 1264) {
        return "64px";
      } else if (windowWidth < 1264) {
        return "180px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1264) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    dialog: false,
    productDialog: false,
    itemList: [],
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      {
        text: "ภาพสินค้า",
        value: "productPhotosUrl",
        align: "start",
        sortable: false,
      },
      { text: "ชื่อสินค้า", value: "name" },
      { text: "รหัสสินค้า", value: "sku" },
      { text: "หมวดหมู่", value: "categoriesName" },
      // { text: "แบรนด์", value: "brand.name" },
      { text: "ราคา", value: "price", align: "center" },
      { text: "สถานะอนุมัติ", value: "status", width: "70px" },
      { text: "สถานะวางขาย", value: "product_status", width: "70px" },
      {
        text: "แอลกอฮอล์",
        value: "alcohol",
        width: "120px",
        sortable: false,
        align: "center",
      },
      { text: "ดำเนินการ", value: "actions", sortable: false, width: "180px" },
    ],
    category: "",
    forImport: false,
    windowWidth: window.innerWidth,
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `สินค้า`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
  }),
  mounted() {
    const { selectedShop } = this;
    if (selectedShop !== "") this.getInitialData();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  async created() {
    await this.getProductTypes({});
    await this.getCategories({});
    await this.getShopBrands({ companyGroup: "thaibev", limit: 500 });
  },
  methods: {
    calStep,
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      const { categories, forImport } = this;
      let condition = {
        forImport,
        all: true,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.category) {
        let categoryIds = 0;
        forEach(categories.data, (e) => {
          if (e.name === this.category) {
            categoryIds = e.id;
          }
        });
        condition.categoryIds = categoryIds;
      }

      // paging
      {
        // Per

        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getShopProducts(condition);
    },
    selectItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    searchKeyword() {
      this.getInitialData();
    },
    openProductModal() {
      this.productDialog = true;
      this.forImport = true;
      this.getInitialData();
    },
    closeProductModal() {
      this.clearProductModal();
      this.getInitialData();
    },
    async cloneProduct(paramId) {
      // this.clearProductModal();
      await this.transferProduct(paramId);
      await this.getInitialData();
    },
    clearProductModal() {
      this.productDialog = false;
      this.forImport = false;
      this.keyword = "";
      this.options = {};
      this.category = "";
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const stepPrices = reduce(
        get(data, "stepPrice"),
        (a, b) => {
          if (b.id !== undefined) {
            a.push({
              productStepPriceId: b.id,
              min: parseInt(b.min),
              max: parseInt(b.max),
              price: parseFloat(b.price),
            });
          } else {
            a.push({
              min: parseInt(b.min),
              max: parseInt(b.max),
              price: parseFloat(b.price),
            });
          }
          return a;
        },
        []
      );
      const submitForm = {
        productTypeId: get(data, "producttype"),
        name: get(data, "name"),
        sku: get(data, "code"),
        description: get(data, "description"),
        price: parseFloat(get(data, "price")) || 0,
        salePrice: parseFloat(get(data, "salePrice")) || 0,
        unit: get(data, "unit"),
        categoryId: get(data, "categoryId"),
        stepPrices: {
          updates: stepPrices,
          deletes: get(data, "deleteStepPrice"),
        },
        alcohol: get(data, "alcohol"),
        product_status: get(data, "productStatus"),
        addons: get(data, "promotion"),
        addonsDetail: get(data, "promotionDetail"),
        refStandardPrice: get(data,"refStandardPrice")
      };

      let product = {};
      if (this.editId !== "") {
        submitForm.id = this.editId;
        product = await this.editAgentProduct(submitForm);
      } else {
        product = await this.createAgentProduct(submitForm);
      }

      const photos = get(data, "image");
      if (photos && Array.isArray(photos) && photos.length > 0) {
        const files = photos.reduce((o, e) => {
          if (e instanceof File) o.push(e);
          return o;
        }, []);

        if (files.length > 0)
          await this.uploadProductPhoto({ id: product.id, photos: files });
      }

      this.editId = "";
      this.getInitialData();
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `สินค้า ${data?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deleteProduct({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    options: {
      handler() {
        const { selectedShop } = this;
        if (selectedShop !== "") this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
$black: #000;
$green: #1b5e20;

.text-black {
  color: $black;
}

.agent-product-container {
  padding: 20px;
  .download-button {
    color: $green;
  }
}
</style>