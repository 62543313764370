<template>
  <div class="order-detail-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <CustomModal
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :modal="modal"
      @confirm="submitAction"
      disableBottomAction
      disableCancel
      confirmIcon
    >
      <template v-slot:actionHeader>
        <h3 class="text-black">รายละเอียดออเดอร์</h3>
      </template>
      <template v-slot:body>
        <v-row>
          <v-col cols="12" lg="6">
            <OrderInfo
              :orderDetail="orderDetail"
              :adminReadOnly="adminReadOnly"
              :confirmAction="confirmAction"
              :rejectAction="rejectAction"
            />
          </v-col>
        </v-row>
        <v-row class="mb-lg-3">
          <v-col cols="12" lg="6">
            <v-card
              class="elevation-5 rounded-xl"
              v-if="orderDetail && orderDetail.status !== 'pending'"
            >
              <v-card-title
                ><v-icon color="#009b3f">mdi-alpha-b-circle-outline</v-icon>
                การชำระเงิน
                <div class="ml-auto">
                  <StatusChip
                    v-if="orderDetail.paymentStatus === 'paid'"
                    color="#d9ead3ff"
                    text-color="#009b3f"
                    chipText="ชำระแล้ว"
                    largeSize
                  />
                  <StatusChip
                    v-else-if="orderDetail.paymentStatus === 'pending'"
                    color="#fff2cd"
                    text-color="#f67b00"
                    chipText="รอชำระ"
                    largeSize
                  />
                  <StatusChip
                    v-else-if="orderDetail.paymentStatus === 'inform'"
                    color="#f4cbcc"
                    text-color="#da291c"
                    chipText="ที่ต้องตรวจสอบ"
                    largeSize
                  /></div
              ></v-card-title>
              <v-divider class="mx-3" />
              <v-card-text v-if="orderPaymentList.data.length > 0">
                <div>
                  <v-stepper
                    v-model="currentPaymentStatus"
                    alt-labels
                    class="payment-method-stepper"
                  >
                    <v-stepper-header>
                      <v-stepper-step
                        step="1"
                        :complete="currentPaymentStatus > 1"
                        color="#009b3f"
                      >
                        แจ้งชำระเงิน
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step
                        step="2"
                        :complete="currentPaymentStatus > 2"
                        color="#009b3f"
                        >ตรวจสอบ</v-stepper-step
                      >
                      <v-divider />
                      <v-stepper-step
                        step="3"
                        :complete="currentPaymentStatus === 3"
                        color="#009b3f"
                        >ขำระแล้ว</v-stepper-step
                      >
                    </v-stepper-header>
                  </v-stepper>
                </div>
                <div
                  v-for="(item, index) in orderPaymentList.data"
                  :key="index"
                >
                  <v-row>
                    <v-col lg="6">
                      <div><b>ประเภทการชำระ</b></div>
                      {{ item.paymentType.name }}
                    </v-col>
                    <v-col
                      lg="6"
                      v-if="
                        item.shopPaymentMethodDetail &&
                        item.shopPaymentMethodDetail.bank &&
                        item.shopPaymentMethodDetail.bank.name
                      "
                    >
                      <div><b>ธนาคาร</b></div>
                      {{ item.shopPaymentMethodDetail.bank.name }}
                    </v-col>
                    <v-col lg="6" v-else-if="item.bank && item.bank.name">
                      <div><b>ธนาคาร</b></div>
                      {{ item.bank.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="6">
                      <div><b>ช่องทางการชำระ</b></div>
                      {{
                        item.shopPaymentMethod.paymentMethod &&
                        item.shopPaymentMethod.paymentMethod.name
                      }}
                    </v-col>
                    <v-col
                      lg="6"
                      v-if="
                        item.shopPaymentMethodDetail &&
                        item.shopPaymentMethodDetail.branchName
                      "
                    >
                      <div><b>สาขา</b></div>
                      {{ item.shopPaymentMethodDetail.branchName }}
                    </v-col>
                    <v-col lg="6" v-else-if="item.branchName">
                      <div><b>สาขา</b></div>
                      {{ item.branchName }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="6">
                      <div><b>วันที่ชำระ</b></div>
                      {{ $generateDate(item.transferTime) }}
                    </v-col>
                    <v-col lg="6">
                      <div><b>จำนวนเงิน</b></div>
                      {{ $numberWithCommas(item.amount) }} บาท
                    </v-col>
                  </v-row>
                  <v-row v-if="item.transferSlip">
                    <v-col>
                      <div class="mb-4"><b>หลักฐานการชำระเงิน</b></div>
                      <GalleryUploadFile
                        v-model="item.transferSlip"
                        disabled
                        disableText
                        :max="5"
                        :removeable="true"
                        :option="upOption"
                        accept="image/*"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-text v-else>
                <div>
                  <v-stepper
                    v-model="mockPaymentStep"
                    alt-labels
                    class="payment-method-stepper"
                  >
                    <v-stepper-header>
                      <v-stepper-step
                        step="1"
                        :complete="mockPaymentStep > 1"
                        color="#009b3f"
                      >
                        แจ้งชำระเงิน
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step
                        step="2"
                        :complete="mockPaymentStep > 2"
                        color="#009b3f"
                        >ตรวจสอบ</v-stepper-step
                      >
                      <v-divider />
                      <v-stepper-step
                        step="3"
                        :complete="mockPaymentStep === 3"
                        color="#009b3f"
                        >ขำระแล้ว</v-stepper-step
                      >
                    </v-stepper-header>
                  </v-stepper>
                </div>
                <div>
                  <v-row>
                    <v-col lg="6">
                      <div><b>ประเภทการชำระ</b></div>
                      {{ orderDetail.paymentType.name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="6">
                      <div><b>ช่องทางการชำระ</b></div>
                      -
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="6">
                      <div><b>วันที่ชำระ</b></div>
                      -
                    </v-col>
                    <v-col lg="6">
                      <div><b>จำนวนเงิน</b></div>
                      - บาท
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-divider class="mx-3" />
              <v-card-actions class="py-3 justify-end">
                <v-btn
                  color="success"
                  @click="paymentAction"
                  :disabled="
                    orderDetail.status === 'cancel' ||
                    (orderDetail.paymentStatus !== 'inform' &&
                      orderPaymentList.data.length > 0 &&
                      orderPaymentList.data[0].approve === 'pending') ||
                    (orderPaymentList.data.length > 0 &&
                      orderPaymentList.data[0].approve === 'approve') ||
                    orderPaymentList.data.length === 0 ||
                    adminReadOnly
                  "
                  >ยืนยันชำระเงิน</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" class="mb-3">
            <v-card
              class="elevation-5 rounded-xl"
              v-if="
                (orderDetail &&
                  orderPaymentList.data.length > 0 &&
                  orderDetail.status !== 'pending') ||
                (orderDetail &&
                  orderDetail.paymentType.name !== 'สด' &&
                  orderDetail.status !== 'pending')
              "
            >
              <v-card-title
                ><v-icon color="#009b3f">mdi-truck-outline</v-icon> การจัดส่ง
                <div class="ml-auto">
                  <StatusChip
                    v-if="orderDetail.shipStatus === 'ship'"
                    color="#fff2cd"
                    text-color="#f67b00"
                    chipText="รอยืนยันรับ"
                    largeSize
                  />
                  <StatusChip
                    v-else-if="orderDetail.shipStatus === 'pending'"
                    color="#f4cbcc"
                    text-color="#da291c"
                    chipText="ที่ต้องส่ง"
                    largeSize
                  />
                  <StatusChip
                    v-else-if="orderDetail.shipStatus === 'deliver'"
                    color="#d9ead3ff"
                    text-color="#009b3f"
                    chipText="ส่งสำเร็จ"
                    largeSize
                  /></div
              ></v-card-title>
              <v-divider class="mx-3" />
              <v-card-text>
                <div>
                  <v-stepper
                    v-model="currentShipStatus"
                    alt-labels
                    class="ship-method-stepper"
                  >
                    <v-stepper-header>
                      <v-stepper-step
                        step="1"
                        :complete="currentShipStatus > 1"
                        color="#009b3f"
                      >
                        จัดส่งสินค้า
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step
                        step="2"
                        :complete="currentShipStatus > 2"
                        color="#009b3f"
                        >รับสินค้า</v-stepper-step
                      >
                      <v-divider />
                      <v-stepper-step
                        step="3"
                        :complete="currentShipStatus === 3"
                        color="#009b3f"
                        >ส่งสำเร็จ</v-stepper-step
                      >
                    </v-stepper-header>
                  </v-stepper>
                </div>
                <v-row>
                  <v-col lg="6">
                    <div><b>ชื่อ</b></div>
                    {{ orderDetail.shipAddress.name }}
                  </v-col>
                  <v-col lg="6">
                    <div><b>ชื่อผู้ติดต่อ</b></div>
                    {{ orderDetail.shipAddress.contact_person }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div><b>ที่อยู่</b></div>
                    {{ shipAddress }}
                  </v-col>
                  <v-col lg="6">
                    <div><b>เบอร์โทร</b></div>
                    {{ orderDetail.shipAddress.tel }}
                  </v-col>
                </v-row>
                <v-row v-if="orderDetail">
                  <v-col>
                    <div class="mb-4"><b>หลักฐานการส่งสินค้า</b></div>
                    <GalleryUploadFile
                      v-model="shipDocument.shipImage"
                      disableText
                      :label="'ผู้ขาย'"
                      :disabled="
                        orderDetail.status === 'cancel' ||
                        orderDetail.shipStatus === 'deliver' ||
                        adminReadOnly
                      "
                      :max="5"
                      :removeable="true"
                      :option="upOption"
                      accept="image/*"
                      @input="addImage"
                      @remove="removeImage"
                    />
                    <v-divider :class="`mb-3${adminReadOnly ? ' mt-3' : ''}`" />
                    <GalleryUploadFile
                      v-model="shipDocument.receiveImage"
                      disableText
                      :label="'ผู้ซื้อ'"
                      disabled
                      :max="5"
                      :option="upOption"
                      accept="image/*"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mx-3" />
              <v-card-actions class="py-3 justify-end">
                <v-btn
                  color="success"
                  :disabled="
                    orderDetail.status === 'cancel' ||
                    orderDetail.shipStatus === 'deliver' ||
                    adminReadOnly
                  "
                  @click="updateShipmentAction"
                  >บันทึกส่งสินค้า</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="elevation-5 rounded-xl" v-if="orderDetail">
          <v-card-title
            ><v-icon color="#009b3f">mdi-package-variant-closed</v-icon>
            รายการสินค้า
            <div class="ml-auto">
              <v-btn
                depressed
                text
                color="success"
                @click="updateOrderList"
                :disabled="orderDetail.status !== 'pending' || adminReadOnly"
                ><b>แก้ไขสินค้า</b></v-btn
              >
            </div>
          </v-card-title>
          <v-divider class="mx-3" />
          <v-card-text>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="orderListDetail"
              :server-items-length="
                orderListDetail ? orderListDetail.total : total
              "
              hide-default-footer
              no-data-text="ไม่พบข้อมูล"
              no-results-text="ไม่พบข้อมูล"
            >
              <template v-slot:item.product.name="{ item }">
                <div>
                  <span v-if="item.premium">[ของแถม] </span
                  >{{ item.product.name }}
                </div>
              </template>
              <template v-slot:item.productPhotosUrl="{ item }">
                <v-img
                  :src="item.productPhotosUrl"
                  contain
                  width="100"
                  height="100"
                  @click="
                    item.productPhotosArray.length > 0
                      ? showImg(item.productPhotosArray)
                      : () => {}
                  "
                />
              </template>
              <template v-slot:item.qty="{ item }">
                <div>{{ item.qty }}</div>
                <div>
                  <small
                    >ค้างส่ง
                    <span class="red-text">{{
                      item.qty - item.ship
                    }}</span></small
                  >
                </div>
                <div>
                  <small
                    >ส่งแล้ว
                    <span class="yellow-text">{{ item.ship }}</span></small
                  >
                </div>
                <div>
                  <small
                    >รับแล้ว
                    <span class="green-text">{{ item.receive }}</span></small
                  >
                </div>
              </template>
              <template v-slot:item.unitPrice="{ item }">
                {{ $numberWithCommas(item.unitPrice) }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ $numberWithCommas(item.total) }}
              </template>
              <template v-slot:item.discount="{ item }">
                {{ $numberWithCommas(item.discount) }}
              </template>
              <template v-slot:item.net="{ item }">
                {{ $numberWithCommas(item.net) }}
              </template>
            </v-data-table>
            <div class="mt-2" v-if="orderDetail && orderListDetail.length > 0">
              <v-row>
                <v-col class="text-right" offset-lg="9" lg="2">
                  <div>ราคารวม</div>
                  <div>ส่วนลดโปรโมชั่น</div>
                  <div>ส่วนลดคูปอง</div>
                  <div>ส่วนลดท้ายบิล</div>
                  <div><h3>ราคารวมทั้งหมด</h3></div>
                  <div>{{ receivePointLabel }}</div>
                </v-col>
                <v-col class="text-right" lg="1">
                  <div>{{ $numberWithCommas(totalAmount) }}</div>
                  <div>{{ $numberWithCommas(promotionDiscount) }}</div>
                  <div>{{ $numberWithCommas(couponDiscount) }}</div>
                  <div>{{ $numberWithCommas(totalDiscount) }}</div>
                  <div>
                    <h3>
                      {{
                        $numberWithCommas(
                          totalNet -
                            totalDiscount -
                            promotionDiscount -
                            couponDiscount
                        )
                      }}
                    </h3>
                  </div>
                  <div>{{ receivePoint }}</div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <div class="mt-4" v-if="orderDetail && orderHistory.length > 0">
          <v-card class="elevation-5 rounded-xl">
            <v-card-title
              ><v-icon color="#009b3f">mdi-history</v-icon
              >ประวัติออเดอร์</v-card-title
            >
            <v-divider class="mx-3" />
            <v-card-text v-if="orderHistory.length > 0">
              <v-timeline align-top dense>
                <v-timeline-item
                  :color="item.color"
                  small
                  v-for="(item, index) in orderHistory"
                  :key="index"
                >
                  <v-row class="pt-1">
                    <v-col cols="12" lg="2">
                      <strong>{{ $generateThaiDate(item.createdAt) }}</strong>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <strong v-if="item.status === 'checkout'"
                        >สร้างออเดอร์ใหม่</strong
                      >
                      <strong v-else-if="item.status === 'pending'"
                        >ยกเลิกอนุมัติ</strong
                      >
                      <strong v-else-if="item.status === 'edit'"
                        >แก้ไขออเดอร์</strong
                      >
                      <strong v-else-if="item.status === 'approve'"
                        >ยืนยันออเดอร์</strong
                      >
                      <strong v-else-if="item.status === 'cancel'"
                        >ยกเลิกออเดอร์</strong
                      >
                      <strong v-else-if="item.status === 'cancel payment'"
                        >ยกเลิกชำระเงิน</strong
                      >
                      <strong v-else-if="item.status === 'inform'"
                        >แจ้งชำระเงิน</strong
                      >
                      <strong v-else-if="item.status === 'paid'"
                        >ยืนยันชำระเงิน</strong
                      >
                      <strong v-else-if="item.status === 'ship'"
                        >ส่งสินค้า</strong
                      >
                      <strong v-else-if="item.status === 'receive'"
                        >รับสินค้า</strong
                      >
                      <strong v-else-if="item.status === 'done'"
                        >เสร็จสิ้น</strong
                      >
                    </v-col>
                    <v-col cols="12" lg="2">
                      <strong>{{ item.note }}</strong>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <strong>{{ item.shop.name }} / {{ item.name }}</strong>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </CustomModal>
    <CustomModal :modal="editModal" @confirm="updateAction" :width="1400">
      <template v-slot:header>
        <v-icon color="#009b3f">mdi-package-variant-closed</v-icon>
        <span class="product-header-modal">แก้ไขสินค้า</span>
        <div class="ml-auto">
          <v-btn
            depressed
            text
            color="success"
            @click="addNewProduct"
            :disabled="adminReadOnly"
            ><b>เพิ่มสินค้า</b></v-btn
          >
        </div>
      </template>
      <template v-slot:body>
        <v-divider />
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="updateList"
          :server-items-length="updateList ? updateList.length : total"
          hide-default-footer
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
        >
          <template v-slot:item.productPhotosUrl="{ item }">
            <v-img
              :src="item.productPhotosUrl"
              contain
              width="100"
              height="100"
              @click="
                item.productPhotosArray.length > 0
                  ? showImg(item.productPhotosArray)
                  : () => {}
              "
            />
          </template>
          <template v-slot:item.qty="{ item }">
            <div class="d-flex">
              <v-text-field
                class="number-input"
                v-model="item.qty"
                outlined
                dense
                hide-details
                @keypress="validateNumeric"
                @keyup="checkPrice(item)"
              />
              <v-btn
                color="error"
                icon
                @click="deleteAction(item)"
                :disabled="adminReadOnly"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.unitPrice="{ item }">
            {{ $numberWithCommas(item.unitPrice) }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ $numberWithCommas(item.unitPrice * item.qty) }}
          </template>
          <template v-slot:item.discount="{ item }">
            <v-text-field
              class="number-input pt-6"
              v-model="item.discount"
              outlined
              dense
              @keypress="validateNumericWithDot"
              :error-messages="
                item.unitPrice * item.qty < item.discount
                  ? 'ส่วนลดเกินราคาสินค้า'
                  : ''
              "
            />
          </template>
          <template v-slot:item.net="{ item }">
            {{ $numberWithCommas(item.unitPrice * item.qty - item.discount) }}
          </template>
        </v-data-table>
        <div class="mt-2" v-if="orderDetail && orderListDetail.length > 0">
          <v-row>
            <v-col class="text-right" offset-lg="8" lg="2">
              <div>ราคารวม</div>
              <div>ส่วนลดโปรโมชั่น</div>
              <div>ส่วนลดคูปอง</div>
              <div class="input-height">ส่วนลดท้ายบิล</div>
              <div><h3>ราคารวมทั้งหมด</h3></div>
            </v-col>
            <v-col class="text-right" lg="2">
              <div>{{ $numberWithCommas(totalUpdateAmount) }}</div>
              <div>{{ $numberWithCommas(promotionDiscount) }}</div>
              <div>{{ $numberWithCommas(couponDiscount) }}</div>
              <div>
                <v-text-field
                  class="number-input pt-3"
                  v-model="overallDiscount"
                  outlined
                  dense
                  @keypress="validateNumericWithDot"
                  :error-messages="
                    totalUpdateAmount < overallDiscount
                      ? 'ส่วนลดเกินราคายอดรวมสินค้า'
                      : ''
                  "
                />
              </div>
              <div class="green--text">
                <h3>{{ $numberWithCommas(totalUpdateNet) }}</h3>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </CustomModal>
    <CustomModal
      :modal="addProductModal"
      disableConfirm
      @confirm="closeAddProduct"
      :width="1280"
    >
      <template v-slot:header>
        <v-icon color="#009b3f">mdi-package-variant-closed</v-icon>
        <span class="product-header-modal">เพิ่มสินค้า</span>
        <v-spacer />
        <v-btn color="error" icon @click="closeAddProduct">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:body>
        <v-divider class="mb-4" />
        <v-data-table
          :loading="isLoading"
          :headers="productHeaders"
          :items="dataList"
          :server-items-length="products ? products.total : total"
          :options.sync="options"
          hide-default-footer
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" :height="checkScreen">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field
                        :disabled="isLoading"
                        v-model="keyword"
                        label="ค้นหาจาก"
                        single-line
                        outlined
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-combobox
                        v-model="category"
                        :items="categoryItems"
                        label="หมวดหมู่"
                        outlined
                        single-line
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    outlined
                    depressed
                    @click="searchKeyword"
                    color="#009b3f"
                    :block="checkMdScreen"
                    >ค้นหา</v-btn
                  >
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.productPhotosUrl="{ item }">
            <v-img
              :src="item.productPhotosUrl"
              contain
              width="100"
              height="100"
              @click="
                item.productPhotosArray.length > 0
                  ? showImg(item.productPhotosArray)
                  : () => {}
              "
            />
          </template>
          <template v-slot:item.name="{ item }">
            <b class="success--text">{{ item.name }}</b>
          </template>
          <template v-slot:item.salePrice="{ item }">
            {{ $numberWithCommas(item.salePrice) }}
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip
              v-if="item.status === 'วางขาย'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="ปกติ"
            />
            <StatusChip
              v-if="item.status === 'รออนุมัติ'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="รออนุมัติ"
            />
            <StatusChip
              v-if="item.status !== 'รออนุมัติ' && item.status !== 'วางขาย'"
              color="#f4cbcc"
              text-color="#da291c"
              :chipText="item.status"
            />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              depressed
              text
              color="success"
              @click="addAction(item)"
              :disabled="item.disabled || adminReadOnly"
              ><b>เพิ่ม</b></v-btn
            >
          </template>
        </v-data-table>
      </template>
    </CustomModal>
    <CustomModal :modal="shipmentModal" :width="1400">
      <template v-slot:header>
        <v-icon color="#009b3f">mdi-package-variant-closed</v-icon>
        <h4>บันทึกส่งสินค้า</h4>
        <div class="ml-auto">
          <v-btn
            depressed
            text
            color="grey"
            @click="clearData"
            :disabled="adminReadOnly"
            ><b>ล้าง</b></v-btn
          >
          <v-btn
            depressed
            text
            color="success"
            @click="autoFillData"
            :disabled="adminReadOnly"
            ><b>กรอกจำนวนค้างส่ง</b></v-btn
          >
        </div>
      </template>
      <template v-slot:body>
        <v-divider />
        <v-data-table
          :loading="isLoading"
          :headers="orderDetailHeaders"
          :items="updateShipList"
          :server-items-length="updateShipList ? updateShipList.length : total"
          hide-default-footer
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
        >
          <template v-slot:item.productPhotosUrl="{ item }">
            <v-img
              :src="item.productPhotosUrl"
              contain
              width="100"
              height="100"
              @click="
                item.productPhotosArray.length > 0
                  ? showImg(item.productPhotosArray)
                  : () => {}
              "
            />
          </template>
          <template v-slot:item.backlog="{ item }">
            <b>
              <span class="red-text">{{ item.qty - item.ship }}</span>
            </b>
          </template>
          <template v-slot:item.ship="{ item }">
            <b>
              <span class="yellow-text">{{ item.ship }}</span>
            </b>
          </template>
          <template v-slot:item.waitReceive="{ item }">
            <b>
              <span class="yellow-text">{{ item.ship - item.receive }}</span>
            </b>
          </template>
          <template v-slot:item.receive="{ item }">
            <b>
              <span class="green-text">{{ item.receive }}</span>
            </b>
          </template>
          <template v-slot:item.total="{ item }">
            {{ $numberWithCommas(item.total) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-text-field
                class="number-input mt-8"
                :disabled="item.qty === item.receive"
                v-model="item.newQty"
                outlined
                dense
                @keypress="validateNumeric"
                :error-messages="
                  item.newQty !== '' && item.newQty > item.qty - item.ship
                    ? 'ยอดส่งเพิ่มต้องน้อยกว่าเท่ากับยอดค้างส่ง'
                    : ''
                "
              />
            </div>
          </template>
        </v-data-table>
      </template>
      <template v-slot:action>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="shipmentAction(false)">
          ปิดหน้าต่าง
        </v-btn>
        <v-btn
          color="success"
          @click="shipmentAction(true)"
          :disabled="validate"
        >
          บันทึกส่งสินค้า
        </v-btn>
      </template>
    </CustomModal>
    <Confirm
      v-model="conf.status"
      :data="conf"
      @confirm="confirmOrderAction"
      textArea
    />
    <Confirm
      v-model="reconf.status"
      :data="reconf"
      @confirm="rejectOrderAction"
      textArea
    />
    <Confirm
      v-model="payconf.status"
      :data="payconf"
      @confirm="paymentOrderAction"
      textArea
    />
    <Confirm
      v-model="shipconf.status"
      :data="shipconf"
      @confirm="shipOrderAction"
      textArea
    />
    <Confirm
      v-model="additemconf.status"
      :data="additemconf"
      @confirm="addItemAction"
    />
    <Confirm
      v-model="deleteitemconf.status"
      :data="deleteitemconf"
      @confirm="deleteItemAction"
    />
  </div>
</template>

<script>
import { cloneDeep, findIndex, forEach, reduce, size } from "lodash";
import Utils from "@/mixin";
import { calStep } from "@/utils/product";

import CustomModal from "@/components/Modal";
import StatusChip from "@/components/StatusChip";
import Confirm from "@/components/Confirm";
import LightBox from "@/components/LightBox.vue";

import OrderInfo from "@/features/OrderInfo.vue";

import GalleryUploadFile from "@/features/GalleryUploadFile";

export default {
  name: "OrderDetail",
  components: {
    Confirm,
    CustomModal,
    StatusChip,
    OrderInfo,
    GalleryUploadFile,
    LightBox,
  },
  computed: {
    validate() {
      const { updateShipList } = this;
      let check = false;
      forEach(updateShipList, (i) => {
        if (i.newQty === "" || i.newQty > i.qty - i.ship) check = true;
      });
      return check;
    },
    shipAddress() {
      const { detail, subDistrict, postcode } = this.orderDetail.shipAddress;
      const fullAddress = `${detail} ${subDistrict.name} ${subDistrict.district} ${subDistrict.province} ${postcode}`;
      return fullAddress;
    },
    orderListDetail() {
      const { orderList } = this;
      const result = reduce(
        orderList.data,
        (o, e) => {
          let productPhotosArray = [];
          forEach(e.product?.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          o.push({
            ...e,
            productPhotosArray,
            productPhotosUrl: e.product?.productPhotos?.[0]?.url || "",
          });
          return o;
        },
        []
      );
      return result;
    },
    totalAmount() {
      const { orderList } = this;
      let total = 0;
      forEach(orderList.data, (i) => {
        total += i.net;
      });
      return Math.round(total * 100) / 100;
    },
    promotionDiscount() {
      const { orderDetail } = this;
      return orderDetail.promotion;
    },
    couponDiscount() {
      const { orderDetail } = this;
      return orderDetail.couponDiscount;
    },
    totalUpdateAmount() {
      const { updateList } = this;
      let total = 0;
      forEach(updateList, (i) => {
        total += i.unitPrice * i.qty - i.discount;
      });
      return Math.round(total * 100) / 100;
    },
    totalDiscount() {
      const { orderDetail } = this;
      return orderDetail.discount;
    },
    totalNet() {
      const { orderList } = this;
      let total = 0;
      forEach(orderList.data, (i) => {
        total += i.net;
      });
      return Math.round(total * 100) / 100;
    },
    totalUpdateNet() {
      const { updateList, overallDiscount, orderDetail } = this;
      let total =
        0 -
        overallDiscount -
        orderDetail.promotion -
        orderDetail.couponDiscount;
      forEach(updateList, (i) => {
        total += i.unitPrice * i.qty - i.discount;
      });
      return Math.round(total * 100) / 100;
    },
    receivePointLabel() {
      const { status } = this.orderDetail;
      return status === "complete" ? "คะแนนที่ได้รับ" : "คะแนนที่จะได้รับ";
    },
    receivePoint() {
      const { expectPoint, point, status } = this.orderDetail;
      return status === "complete" ? point : expectPoint;
    },
    currentPaymentStatus() {
      const { paymentItems, orderDetail, orderPaymentList } = this;
      const { paymentStatus } = orderDetail;
      const approve =
        size(orderPaymentList.data) > 0
          ? orderPaymentList.data[0].approve
          : "pending";

      if (approve === "pending") {
        const index = findIndex(paymentItems, (i) => i.value === paymentStatus);
        const step = index === -1 ? 0 : paymentItems[index].step;
        return step;
      } else {
        return 3;
      }
    },
    currentShipStatus() {
      const { shipItems, orderDetail } = this;
      const { shipStatus } = orderDetail;
      const index = findIndex(shipItems, (i) => i.value === shipStatus);
      const step = index === -1 ? 0 : shipItems[index].step;
      return step;
    },
    orderHistory() {
      const { orderHistoryList } = this;
      let index = 0;
      const result = reduce(
        orderHistoryList.data,
        (o, e) => {
          let color = "grey";
          if (index === orderHistoryList.data.length - 1) {
            color = "green";
          }
          o.push({ ...e, name: e.user?.name, color });
          index++;
          return o;
        },
        []
      );
      return result;
    },
    dataList() {
      const {
        products,
        // updateList
      } = this;
      const result = reduce(
        products.data,
        (o, e) => {
          // let index = 0;
          // index = findIndex(updateList, (i) => {
          //   return i.productId === e.id;
          // });
          let productPhotosArray = [];
          let categoriesName = "";
          let count = 1;
          forEach(e.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          forEach(e.categories, (i) => {
            categoriesName += i.name;
            if (count < size(e.categories)) {
              categoriesName += ", ";
              count++;
            }
          });
          o.push({
            ...e,
            productPhotosArray,
            productPhotosUrl: e.productPhotos?.[0]?.url || "",
            categoriesName,
            // disabled: index !== -1 ? true : false,
            disabled: false,
          });
          return o;
        },
        []
      );
      return result;
    },
    categoryItems() {
      const { categories } = this;
      const result = reduce(
        categories.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "64px";
      } else if (windowWidth < 960) {
        return "180px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: () => ({
    mockPaymentStep: 1,
    imgArr: [],
    imgShow: false,
    total: 0,
    headers: [
      { text: "ภาพสินค้า", value: "productPhotosUrl", sortable: false },
      { text: "ชื่อสินค้า", value: "product.name" },
      { text: "รหัสสินค้า", value: "product.sku" },
      { text: "จำนวน", value: "qty" },
      { text: "ราคาต่อหน่วย", value: "unitPrice", align: "right" },
      { text: "ราคารวม", value: "total", align: "right" },
      { text: "ส่วนลด", value: "discount", align: "right" },
      { text: "ราคารวมหลังลด", value: "net", align: "right" },
    ],
    orderDetailHeaders: [
      { text: "ภาพสินค้า", value: "productPhotosUrl", sortable: false },
      { text: "ชื่อสินค้า", value: "product.name" },
      { text: "รหัสสินค้า", value: "product.sku" },
      { text: "ราคารวม", value: "total", align: "right" },
      { text: "จำนวน", value: "qty" },
      { text: "ค้างส่ง", value: "backlog", align: "right" },
      { text: "ส่งแล้ว", value: "ship", align: "right" },
      { text: "รอรับสินค้า", value: "waitReceive", align: "right" },
      { text: "ส่งสำเร็จ", value: "receive", align: "right" },
      { text: "บันทึกส่งเพิ่ม", value: "actions", sortable: false },
    ],
    upOption: {
      maxWidth: 1000,
    },
    paymentItems: [
      { text: "ตรวจสอบ", step: 1, value: "pending" },
      { text: "แจ้งชำระเงิน", step: 2, value: "inform" },
      { text: "ขำระแล้ว", step: 3, value: "paid" },
    ],
    shipItems: [
      { text: "รับสินค้า", step: 1, value: "pending" },
      { text: "จัดส่งสินค้า", step: 2, value: "ship" },
      { text: "ส่งสำเร็จ", step: 3, value: "deliver" },
    ],
    editModal: false,
    shipmentModal: false,
    updateList: [],
    updateShipList: [],
    orderId: null,
    overallDiscount: 0,
    keyword: "",
    options: {},
    productHeaders: [
      { text: "ภาพสินค้า", value: "productPhotosUrl", align: "start" },
      { text: "ชื่อสินค้า", value: "name" },
      { text: "รหัสสินค้า", value: "sku" },
      { text: "หมวดหมู่", value: "categoriesName" },
      // { text: "แบรนด์", value: "brand.name" },
      { text: "ราคา", value: "salePrice", align: "center" },
      { text: "สถานะการขาย", value: "status", width: "70px" },
      { text: "ดำเนินการ", value: "actions", sortable: false },
    ],
    category: "",
    addProductModal: false,
    deleteList: [],
    conf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    reconf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    payconf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    shipconf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    additemconf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    deleteitemconf: {
      status: false,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    windowWidth: window.innerWidth,
    shipDocument: {
      shipImage: [],
      receiveImage: [],
    },
  }),
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    calStep,
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    validateNumericWithDot(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async getOrderDetail() {
      const { adminReadOnly, orderDetailId } = this;
      let condition = {
        orderId: orderDetailId,
      };

      if (adminReadOnly) {
        await this.getAdminOrderIndex(condition);
        await this.getAdminOrdersList(condition);
        await this.getAdminOrderPayment(condition);
        await this.getAdminOrderHistory({
          ...condition,
          order: "createdAt:asc",
        });
      } else {
        await this.getOrderIndex(condition);
        await this.getOrdersList(condition);
        await this.getOrderPayment(condition);
        await this.getOrderHistory({ ...condition, order: "createdAt:asc" });
      }
      // await this.getOrderShipment(condition);
      this.getShipOrderImage();
    },
    async getShipOrderImage() {
      const { adminReadOnly, orderDetailId } = this;
      let condition = {
        orderId: orderDetailId,
      };
      if (adminReadOnly) {
        const { orderDetail } = this;
        const { receivePhoto, shipPhoto } = orderDetail;
        this.shipDocument.shipClone = cloneDeep(shipPhoto);
        this.shipDocument.shipImage = reduce(
          shipPhoto,
          (a, b) => {
            a.push(b.photo);
            return a;
          },
          []
        );
        this.shipDocument.receiveClone = cloneDeep(receivePhoto);
        this.shipDocument.receiveImage = reduce(
          receivePhoto,
          (a, b) => {
            a.push(b.photo);
            return a;
          },
          []
        );
      } else {
        await this.getShipmentImage({ ...condition, type: "ship" }).then(
          (res) => {
            const { orderShipmentImageList } = res;
            this.shipDocument.shipClone = cloneDeep(orderShipmentImageList);
            this.shipDocument.shipImage = reduce(
              orderShipmentImageList,
              (a, b) => {
                a.push(b.photo);
                return a;
              },
              []
            );
          }
        );
        await this.getShipmentImage({ ...condition, type: "receive" }).then(
          (res) => {
            const { orderShipmentImageList } = res;
            this.shipDocument.receiveClone = cloneDeep(orderShipmentImageList);
            this.shipDocument.receiveImage = reduce(
              orderShipmentImageList,
              (a, b) => {
                a.push(b.photo);
                return a;
              },
              []
            );
          }
        );
      }
    },
    async addImage(image) {
      const { orderDetailId } = this;
      await this.uploadShipmentImage({
        orderId: orderDetailId,
        type: "ship",
        image: image[image.length - 1],
      });
      await this.getShipOrderImage();
    },
    async removeImage(item, index) {
      const ritem = this.shipDocument.shipClone?.find((e) => e.photo === item);
      if (ritem?.id) {
        await this.deleteShipmentImage({ deleteId: ritem?.id }).then(() =>
          this.removeImgIndex(index)
        );
        return;
      }
      this.removeImgIndex(index);
    },
    removeImgIndex(index) {
      const imgs = this.shipDocument.shipImage;
      imgs.splice(index, 1);
      this.shipDocument.shipImage = imgs;
    },
    updateOrderList() {
      const { orderDetail, orderList } = this;
      let orderId = null;
      const result = reduce(
        orderList.data,
        (o, e) => {
          let productPhotosArray = [];
          forEach(e.product?.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          if (e.premium === false) {
            o.push({
              ...e,
              productPhotosArray,
              productPhotosUrl: e.product?.productPhotos?.[0]?.url || "",
              oldOrder: true,
            });
          }
          orderId = e.orderId;
          return o;
        },
        []
      );
      this.orderId = orderId;
      this.updateList = result;
      this.overallDiscount = orderDetail.discount;
      this.editModal = true;
    },
    updateShipmentAction() {
      const { orderList } = this;
      const result = reduce(
        orderList.data,
        (o, e) => {
          o.push({
            ...e,
            productPhotosUrl: e.product.productPhotos?.[0]?.url || "",
            newQty: 0,
          });
          return o;
        },
        []
      );
      this.updateShipList = result;
      this.shipmentModal = true;
    },
    clearData() {
      const { updateShipList } = this;
      const result = reduce(
        updateShipList,
        (o, e) => {
          o.push({ ...e, newQty: 0 });
          return o;
        },
        []
      );
      this.updateShipList = result;
    },
    autoFillData() {
      const { updateShipList } = this;
      const result = reduce(
        updateShipList,
        (o, e) => {
          if (e.newQty === 0 || e.newQty === "") {
            o.push({ ...e, newQty: e.qty - e.ship });
          } else {
            o.push({ ...e });
          }
          return o;
        },
        []
      );
      this.updateShipList = result;
    },
    async shipmentAction(event = false) {
      if (event) {
        this.shipAction();
      }
      this.shipmentModal = false;
    },
    async updateAction(event = false) {
      const { overallDiscount, deleteList, updateList, orderId } = this;
      if (event) {
        const updates = reduce(
          updateList,
          (o, e) => {
            if (e.oldOrder) {
              o.push({
                orderListId: e.id,
                productId: e.productId,
                qty: parseInt(e.qty),
                discount: parseFloat(e.discount),
              });
            } else {
              o.push({
                productId: e.id,
                qty: parseInt(e.qty),
                discount: parseFloat(e.discount),
              });
            }
            return o;
          },
          []
        );
        const formData = {
          discount: parseFloat(overallDiscount),
          deletes: deleteList,
          updates,
          orderId,
        };
        await this.editOrder(formData);
        this.getOrderDetail();
      }
      this.editModal = false;
      this.overallDiscount = 0;
      // this.submitAction();
    },
    addNewProduct() {
      this.addProductModal = true;
      this.getProduct();
    },
    closeAddProduct() {
      this.addProductModal = false;
    },
    searchKeyword() {
      this.getProduct();
    },
    async getProduct() {
      const { categories } = this;
      let condition = {
        forImport: false,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.category) {
        let categoryIds = 0;
        forEach(categories.data, (e) => {
          if (e.name === this.category) {
            categoryIds = e.id;
          }
        });
        condition.categoryIds = categoryIds;
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getShopProducts(condition);
      // await this.getShopBrands({ companyGroup: "thaibev" });
      await this.getCategories({});
    },
    deleteItem(item) {
      const { updateList } = this;
      const result = reduce(
        updateList,
        (o, e) => {
          if (e.id !== item.id) {
            o.push(e);
          }
          return o;
        },
        []
      );
      this.deleteList.push(item.id);
      this.updateList = result;
    },
    addItem(item) {
      const { updateList } = this;
      updateList.push({
        ...item,
        product: { name: item.name, sku: item.sku },
        qty: 1,
        discount: 0,
        unitPrice: item.salePrice,
        productId: item.id,
        oldOrder: false,
      });
      this.closeAddProduct();
    },
    confirmAction() {
      this.conf = {
        ...this.conf,
        title: "ยืนยันออเดอร์?",
        status: true,
      };
    },
    confirmOrderAction(value = false) {
      if (value) {
        this.$emit("confirmOrder", { event: true, note: this.conf.note });
      }
      this.conf = {
        ...this.conf,
        status: false,
      };
    },
    rejectAction() {
      this.reconf = {
        ...this.reconf,
        title: "ยืนยันยกเลิกออเดอร์?",
        status: true,
      };
    },
    rejectOrderAction(value = false) {
      if (value) {
        this.$emit("confirmOrder", { event: false, note: this.reconf.note });
      }
      this.reconf = {
        ...this.reconf,
        status: false,
      };
    },
    paymentAction() {
      this.payconf = {
        ...this.payconf,
        title: "ยืนยันการชำระเงิน?",
        status: true,
      };
    },
    paymentOrderAction(value = false) {
      if (value) {
        this.$emit("confirmPayment", { event: true, note: this.payconf.note });
      }
      this.payconf = {
        ...this.payconf,
        status: false,
      };
    },
    shipAction() {
      this.shipconf = {
        ...this.shipconf,
        title: "ยืนยันการจัดส่งสินค้า?",
        status: true,
      };
    },
    shipOrderAction(value = false) {
      const { updateShipList } = this;
      if (value) {
        this.$emit("confirmShipment", {
          event: true,
          updateShipList,
          note: this.shipconf.note,
        });
      }
      this.shipconf = {
        ...this.shipconf,
        status: false,
      };
    },
    deleteAction(item) {
      this.deleteitemconf = {
        ...this.deleteitemconf,
        title: "ยืนยันการลบสินค้า?",
        status: true,
        data: item,
      };
    },
    deleteItemAction(value = false) {
      if (value) {
        const { data } = this.deleteitemconf;
        this.deleteItem(data);
      }
      this.deleteitemconf = {
        ...this.deleteitemconf,
        status: false,
        data: undefined,
      };
    },
    addAction(item) {
      this.additemconf = {
        ...this.additemconf,
        title: "ยืนยันการเพิ่มสินค้า?",
        status: true,
        data: item,
      };
    },
    addItemAction(value = false) {
      if (value) {
        const { data } = this.additemconf;
        this.addItem(data);
      }
      this.additemconf = {
        ...this.additemconf,
        status: false,
        data: undefined,
      };
    },
    async checkPrice(item) {
      const { updateList } = this;
      const { qty, productId } = item;
      const newPrice = await this.getPrice({ productId, qty });
      const cloneList = cloneDeep(updateList);
      const newUpdateList = reduce(
        cloneList,
        (a, b) => {
          if (b.productId === productId) {
            a.push({ ...b, unitPrice: newPrice });
          } else {
            a.push(b);
          }
          return a;
        },
        []
      );
      this.updateList = newUpdateList;
    },
  },
  props: {
    orderDetailId: {
      type: [Number, String],
    },
    modal: {
      type: Boolean,
    },
    submitAction: {
      type: Function,
    },
    adminReadOnly: {
      type: Boolean,
    },
  },
  mixins: [Utils],
  watch: {
    orderDetailId(nextProps, prevProps) {
      if (nextProps !== prevProps && nextProps !== null) {
        this.getOrderDetail();
      }
    },
  },
};
</script>

<style lang="scss">
$red: #da291c;
$yellow: #e97700;
$green: #009b3f;

.red-text {
  color: $red;
  font-weight: bold;
}
.yellow-text {
  color: $yellow;
  font-weight: bold;
}
.green-text {
  color: $green;
  font-weight: bold;
}

.gallery-item-container {
  padding-bottom: 0 !important;
}

.payment-method-stepper,
.ship-method-stepper {
  box-shadow: none !important;
}

.input-height {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
}

.number-input {
  input {
    text-align: right;
  }
}

.product-header-modal {
  font-size: 20px;
}
</style>