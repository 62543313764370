<template>
  <div class="permission-container">
    <h2 class="mb-3">จัดการสิทธิ์การใช้งาน</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="dataList"
        :items-per-page.sync="itemPerPage"
        :page.sync="page"
        :server-items-length="total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row>
              <v-col sm="4">
                <v-text-field
                  :disabled="isLoading"
                  v-model="keyword"
                  label="ค้นหาจาก"
                  single-line
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col sm="2">
                <v-btn outlined depressed @click="searchKeyword" color="#009b3f"
                  >ค้นหา</v-btn
                >
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.permission="{ item }">
          <v-layout row wrap class="justify-center">
            <v-checkbox
              class="shrink mr-0 mt-0"
              v-model="item.permission"
              color="#009b3f"
              :ripple="false"
              hide-details
              @change="updatePermssion(item)"
            />
          </v-layout>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

export default {
  name: "ManagePermission",
  computed: {
    dataList() {
      const { itemList } = this;
      return itemList;
    },
  },
  data: () => ({
    itemList: [],
    keyword: "",
    itemsPerPageOptions: [],
    itemPerPage: 50,
    page: 1,
    total: 0,
    headers: [
      { text: "รหัสสินค้า", value: "code", align: "start" },
      { text: "ชื่อร้านค้า", value: "shopName" },
      { text: "ชื่อผู้ใช้งาน", value: "userName" },
      { text: "ความสัมพันธ์", value: "relationship" },
      {
        text: "สิทธิ์เข้าใช้ร้านค้า",
        value: "permission",
        sortable: false,
        align: "center",
      },
      { text: "ดำเนินการ", value: "actions", sortable: false },
    ],
  }),
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    getInitialData() {
      const arrayList = [
        {
          id: 1,
          code: "1001234",
          shopName: "ชื่อร้านเอเย่นต์",
          userName: "Test01",
          relationship: "มารดา",
          permission: true,
        },
        {
          id: 2,
          code: "1001234",
          shopName: "ชื่อร้านเอเย่นต์",
          userName: "Test02",
          relationship: "บุตร",
          permission: true,
        },
        {
          id: 3,
          code: "1001234",
          shopName: "ชื่อร้านเอเย่นต์",
          userName: "Test03",
          relationship: "บุตร",
          permission: false,
        },
      ];
      this.itemList = arrayList;
      this.total = arrayList.length;
    },
    selectItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    updatePermssion(item) {
      console.log(item);
    },
    searchKeyword() {
      console.log("Search");
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
.permission-container {
  padding: 20px;
}
</style>