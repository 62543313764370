<template>
  <div class="premium-product-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">สินค้าพรีเมี่ยม</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="(premiumProducts && premiumProducts.data) || []"
        :server-items-length="premiumProducts && premiumProducts.total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" :height="checkScreen">
            <v-row>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      :disabled="isLoading"
                      v-model="keyword"
                      label="ค้นหาจาก"
                      single-line
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      outlined
                      depressed
                      @click="searchKeyword"
                      color="#009b3f"
                      :block="checkMdScreen"
                      >ค้นหา</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <PremiumProductForm
                  class="text-right"
                  :editId="editId"
                  :dialog="modalDialog"
                  :toggleDialog="toggleDialog"
                  :setInitialDialog="setInitialDialog"
                  @submit="submit"
                />
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.photo="{ item }">
          <v-img
            :src="item.photo && item.photo[0] && item.photo[0].uri"
            contain
            max-height="100"
            max-width="100"
            @click="showImg(item.photo[0].uri)"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="editItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
          <v-btn depressed text color="error" @click="deleteItem(item)"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import { get } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import Confirm from "@/components/Confirm";
import LightBox from "@/components/LightBox.vue";

import PremiumProductForm from "./PremiumProduct/Form";

export default {
  name: "Admin-PremiumProduct",
  mixins: [Utils],
  components: {
    PremiumProductForm,
    Confirm,
    LightBox,
  },
  computed: {
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "64px";
      } else if (windowWidth < 960) {
        return "180px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    modalDialog: false,
    dialog: false,
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      {
        text: "ภาพสินค้า",
        value: "photo",
        sortable: false,
        align: "center",
      },
      { text: "ชื่อสินค้าพรีเมี่ยม", value: "name", sortable: false },
      { text: "รหัสสินค้าพรีเมี่ยม", value: "code", sortable: false },
      {
        text: "ดำเนินการ",
        value: "actions",
        align: "center",
        sortable: false,
        width: "200px",
      },
    ],
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `สินค้าพรีเมี่ยม`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    windowWidth: window.innerWidth,
  }),
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getPremiumProducts(condition);
    },
    editItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `สินค้าพรีเมี่ยม ${data?.name}`,
        status: true,
        data,
      };
    },
    searchKeyword() {
      this.getInitialData();
    },
    toggleDialog() {
      this.modalDialog = !this.modalDialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const submitForm = {
        name: get(data, "name"),
        code: get(data, "code"),
      };

      let product = {};
      if (this.editId !== "") {
        submitForm.id = this.editId;
        product = await this.editPremiumProduct(submitForm);
      } else {
        product = await this.createPremiumProduct(submitForm);
      }

      const photos = get(data, "image");
      if (photos && Array.isArray(photos) && photos.length > 0) {
        const files = photos.reduce((o, e) => {
          if (e instanceof File) o.push(e);
          return o;
        }, []);

        if (files.length > 0)
          await this.uploadPremiumProductPhoto({
            id: product.id,
            photos: files,
          });
      }

      this.editId = "";
      this.getInitialData();
    },
    async deleteData(value = false) {
      if (value) {
        await this.deletePremiumProduct({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.premium-product-container {
  padding: 20px;
}
</style>