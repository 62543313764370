<template>
  <v-card class="elevation-5 rounded-xl" v-if="orderDetail">
    <v-card-title
      ><v-icon color="#009b3f">mdi-clipboard-text-outline</v-icon>
      ข้อมูลทั่วไป</v-card-title
    >
    <v-divider class="mx-3" />
    <v-card-text>
      <v-row>
        <v-col lg="6">
          <div><b>เลขที่ออเดอร์</b></div>
          {{ orderDetail.orderNumber }}
        </v-col>
        <v-col lg="6">
          <div><b>วันที่สั่งซื้อ</b></div>
          {{ $generateDate(orderDetail.createdAt) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6">
          <div><b>ร้านค้า</b></div>
          {{ orderDetail.seller.name }}
        </v-col>
        <v-col lg="6">
          <div><b>ลูกค้า</b></div>
          {{ orderDetail.buyer.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6">
          <div><b>ประเภทการชำระ</b></div>
          {{ orderDetail.paymentType.name }}
        </v-col>
        <v-col lg="6">
          <div><b>สถานะออเดอร์</b></div>
          <div class="status-chip">
            <StatusChip
              v-if="orderDetail.status === 'complete'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="เสร็จสิ้น"
              largeSize
            />
            <StatusChip
              v-else-if="orderDetail.status === 'inprogress'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="กำลังดำเนินการ"
              largeSize
            />
            <StatusChip
              v-else-if="orderDetail.status === 'pending'"
              color="#f4cbcc"
              text-color="#da291c"
              chipText="ที่ต้องยืนยัน"
              largeSize
            />
            <StatusChip
              v-else-if="orderDetail.status === 'cancel'"
              color="#f3f3f3ff"
              text-color="#666666"
              chipText="ยกเลิก"
              largeSize
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-3" />
    <v-card-actions class="py-3 justify-end">
      <v-btn
        class="mr-2"
        color="success"
        :disabled="
          orderDetail.status === 'complete' ||
          orderDetail.status === 'inprogress' ||
          orderDetail.status === 'cancel' ||
          adminReadOnly
        "
        @click="confirmAction"
        >ยืนยันออเดอร์</v-btn
      >
      <v-btn
        color="error"
        @click="rejectAction"
        :disabled="
          orderDetail.status === 'cancel' ||
          (orderDetail.paymentStatus === 'paid' &&
            orderDetail.shipStatus === 'deliver') ||
          adminReadOnly
        "
        >ยกเลิกออเดอร์</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import StatusChip from "@/components/StatusChip";

export default {
  components: {
    StatusChip,
  },
  props: {
    orderDetail: {
      type: Object,
    },
    adminReadOnly: {
      type: Boolean,
    },
    confirmAction: {
      type: Function,
    },
    rejectAction: {
      type: Function,
    },
  },
};
</script>