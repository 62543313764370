<template>
  <div class="user-product-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">สินค้าผู้ขาย</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="dataList"
        :server-items-length="products ? products.total : total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
            class="table-header"
            :height="checkScreen"
          >
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  :disabled="isLoading"
                  v-model="keyword"
                  label="ค้นหาจากรหัส ชื่อสินค้า"
                  single-line
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="2" lg="3">
                <v-combobox
                  :items="sellerItem"
                  v-model="sellerSelected"
                  label="ผู้ขาย"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="2" lg="3">
                <v-combobox
                  v-model="producttype"
                  item-value="id"
                  item-text="name"
                  :items="typeList"
                  label="ประเภทสินค้า"
                  outlined
                  dense
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  class="table-button"
                  :block="checkMdScreen"
                  >ค้นหา</v-btn
                >
              </v-col>
              <v-col cols="12" md="5" lg="4">
                <ProductForm
                  approve
                  readOnly
                  noCreateButton
                  class="text-right"
                  :editId="editId"
                  :dialog="dialog"
                  :toggleDialog="toggleDialog"
                  :setInitialDialog="setInitialDialog"
                  @submit="submit"
                />
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.productPhotosUrl="{ item }">
          <v-img
            :src="item.productPhotosUrl"
            contain
            width="100"
            height="100"
            @click="
              item.productPhotosArray.length > 0
                ? showImg(item.productPhotosArray)
                : () => {}
            "
          />
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            v-if="item.status === 'อนุมัติ'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="อนุมัติ"
          />
          <StatusChip
            v-else-if="item.status === 'รออนุมัติ'"
            color="#fff2cd"
            text-color="#f67b00"
            chipText="รออนุมัติ"
          />
          <StatusChip
            v-else-if="item.status !== 'รออนุมัติ' && item.status !== 'อนุมัติ'"
            color="#f4cbcc"
            text-color="#da291c"
            :chipText="item.status"
          />
        </template>
        <template v-slot:item.product_status="{ item }">
          <StatusChip
            v-if="item.product_status === 'วางขาย'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="วางขาย"
          />
          <StatusChip
            v-else
            color="#f3f3f3ff"
            text-color="#666666"
            :chipText="item.product_status"
          />
        </template>
        <template v-slot:item.shop.shopType="{ item }">
          {{ shopTypeName(item.shop && item.shop.shopType) }}
        </template>
        <template v-slot:item.shop.name="{ item }">
          <div v-if="item.shop && item.shop.shopType === 'agent'" class="green--text">
            {{ item.shop.name }}
          </div>
          <div v-if="item.shop && item.shop.shopType === 'retail'" class="blue--text">
            {{ item.shop.name }}
          </div>
          <div
            v-if="
              item.shop && item.shop.shopType === 'sub-agent' ||
              item.shop && item.shop.shopType === 'sub-direct'
            "
            class="light-green--text"
          >
            {{ item.shop.name }}
          </div>
        </template>
        <template v-slot:item.price="{ item }">
          <span>
            <span
              v-if="item.productStepPrices && item.productStepPrices.length > 0"
            >
              {{ calStep(item).min }} ~ {{ calStep(item).max }}</span
            >
            <span v-else>{{ item.price }}</span></span
          >
        </template>
        <template v-slot:item.alcohol="{ item }">
          <v-icon v-if="!item.alcohol" color="red">mdi-close</v-icon>
          <v-icon v-else color="green">mdi-check</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { forEach, get, reduce } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";
import { calStep } from "@/utils/product";

import StatusChip from "@/components/StatusChip";
import LightBox from "@/components/LightBox.vue";

import ProductForm from "../User/Product/Form";

export default {
  name: "Admin-AgentProduct",
  components: {
    LightBox,
    StatusChip,
    ProductForm,
  },
  computed: {
    typeList() {
      return [{ name: "ทั้งหมด", id: 0 }, ...this.producttypes?.data] || [];
    },
    dataList() {
      const { products } = this;
      const result = reduce(
        products.data,
        (o, e) => {
          let productPhotosArray = [];
          forEach(e.productPhotos, (i) => {
            productPhotosArray.push(i.url);
          });
          o.push({
            ...e,
            productPhotosArray,
            productPhotosUrl: e.productPhotos?.[0]?.url || "",
          });
          return o;
        },
        []
      );
      return result;
    },
    sellerItem() {
      const { sellersList } = this;
      const result = reduce(
        sellersList.data,
        (o, e) => {
          o.push(`${e.name} - ${this.shopTypeName(e.shopType)}`);
          return o;
        },
        []
      );
      return result;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "64px";
      } else if (windowWidth < 960) {
        return "250px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    dialog: false,
    itemList: [],
    keyword: "",
    sellerSelected: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      { text: "ภาพสินค้า", value: "productPhotosUrl", align: "start" },
      { text: "ชื่อสินค้า", value: "name" },
      { text: "รหัสสินค้า", value: "sku" },
      { text: "ชื่อผู้ขาย", value: "shop.name" },
      { text: "ประเภทผู้ขาย", value: "shop.shopType" },
      { text: "ประเภทสินค้า", value: "productType.name" },
      // { text: "แบรนด์", value: "brand.name" },
      { text: "ราคา", value: "price", align: "center" },
      { text: "สถานะอนุมัติ", value: "status", width: "70px" },
      { text: "สถานะวางขาย", value: "product_status", width: "70px" },
      {
        text: "แอลกอฮอล์",
        value: "alcohol",
        width: "120px",
        sortable: false,
        align: "center",
      },
      { text: "ดำเนินการ", value: "actions", sortable: false },
    ],
    producttype: "",
    windowWidth: window.innerWidth,
  }),
  async created() {
    await this.getProductTypes({});
    await this.getProductTypes({});
    await this.getBrands({ limit: 500 });
  },
  mounted() {
    this.getInitialData();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    calStep,
    shopTypeName(txt) {
      let result;
      switch (txt) {
        case "agent":
          result = "เอเย่นต์";
          break;
        case "sub-agent":
          result = "ซับเอเยนต์";
          break;
        case "sub-direct":
          result = "ซับเอเยนต์";
          break;
        case "retail":
          result = "ร้านค้าปลีก";
          break;
        default:
          break;
      }
      return result;
    },
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      const { producttypes, sellerSelected, sellersList } = this;
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.producttype) {
        let productTypeIds = 0;
        forEach(producttypes.data, (e) => {
          if (e.name === this.producttype?.name) {
            productTypeIds = e.id;
          }
        });
        if (productTypeIds !== 0) condition.productTypeIds = productTypeIds;
      }

      // paging
      {
        // Per

        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      if (sellerSelected) {
        let sellerId = 0;
        forEach(sellersList.data, (e) => {
          if (
            `${e.name} - ${this.shopTypeName(e.shopType)}` === sellerSelected
          ) {
            sellerId = e.id;
          }
        });
        condition.shopId = sellerId;
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getAgentProducts(condition);
      await this.getAdminSellers();
    },
    selectItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    searchKeyword() {
      this.getInitialData();
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const status = get(data, "status");
      if (status) {
        await this.updateProductStatus({
          productId: this.editId,
          status,
        });
        // switch (status) {
        //   case "วางขาย":
        //     await this.updateProductStatus({
        //       productId: this.editId,
        //       status: "accept",
        //     });
        //     break;
        //   case "ระงับ":
        //     await this.updateProductStatus({
        //       productId: this.editId,
        //       status: "reject",
        //     });
        //     break;
        //   default:
        //     break;
        // }
      }
      this.editId = "";
      this.getInitialData();
    },
  },
  mixins: [Utils],
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
$blue-text: #4986e8;
$light-green-text: #92c47c;
$green-text: #009b3f;

.green--text {
  color: $green-text;
}
.blue-text {
  color: $blue-text;
}
.light-green--text {
  color: $light-green-text;
}

.user-product-container {
  padding: 20px;
  .table-header {
    .v-toolbar__content {
      display: block;
      .table-button {
        margin-top: 3px;
      }
    }
  }
}
</style>