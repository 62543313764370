<template>
  <div class="brand-container">
    <LightBox :items="imgArr" :show.sync="imgShow" />
    <h2 class="mb-3">แบรนด์</h2>
    <v-card class="pa-4 elevation-5 rounded-xl">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="(brands && brands.data) || []"
        :server-items-length="brands && brands.total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" :height="checkScreen">
            <v-row>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      :disabled="isLoading"
                      v-model="keyword"
                      label="ค้นหาจาก"
                      single-line
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      outlined
                      depressed
                      @click="searchKeyword"
                      color="#009b3f"
                      :block="checkMdScreen"
                      >ค้นหา</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <BrandForm
                  class="text-right"
                  :editId="editId"
                  :dialog="modalDialog"
                  :toggleDialog="toggleDialog"
                  :setInitialDialog="setInitialDialog"
                />
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.logoImgUrl="{ item }">
          <v-img
            contain
            :lazy-src="item.logoImgUrl"
            max-height="100"
            max-width="200"
            :src="item.logoImgUrl"
            @click="showImg(item.logoImgUrl)"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="editItem(item.id)"
            ><b>แก้ไข</b></v-btn
          >
          <v-btn depressed text color="error" @click="deleteItem(item)"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import Confirm from "@/components/Confirm";
import LightBox from "@/components/LightBox.vue";

import BrandForm from "./Brand/Form";

export default {
  name: "Admin-Brand",
  mixins: [Utils],
  components: {
    BrandForm,
    Confirm,
    LightBox,
  },
  computed: {
    dataList() {
      const { itemList } = this;
      return itemList;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "64px";
      } else if (windowWidth < 960) {
        return "180px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
  data: () => ({
    imgArr: [],
    imgShow: false,
    editId: "",
    modalDialog: false,
    dialog: false,
    itemList: [],
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      { text: "รหัสแบรนด์", value: "slug", align: "start" },
      { text: "ชื่อแบรนด์", value: "name" },
      { text: "บริษัท", value: "company.name" },
      {
        text: "กลุ่มบริษัท",
        value: "company.companyGroup.name",
        align: "center",
      },
      {
        text: "โลโก้",
        value: "logoImgUrl",
        width: "200px",
        sortable: false,
        align: "center",
      },
      {
        text: "ดำเนินการ",
        value: "actions",
        align: "center",
        sortable: false,
        width: "200px",
      },
    ],
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `แบรนด์`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    windowWidth: window.innerWidth,
  }),
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    showImg(image) {
      this.imgShow = true;
      if (typeof image === "string") {
        this.imgArr = [image];
      } else {
        this.imgArr = image;
      }
    },
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getBrands(condition);
    },
    editItem(id) {
      this.editId = id;
      this.toggleDialog();
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `แบรนด์ ${data?.name}`,
        status: true,
        data,
      };
    },
    searchKeyword() {
      this.getInitialData();
    },
    toggleDialog() {
      this.modalDialog = !this.modalDialog;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async deleteData(value = false) {
      if (value) {
        await this.deleteBrand({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-container {
  padding: 20px;
}
</style>