<template>
  <div id="auth-layout">
    <div v-if="hamburger">
      <v-navigation-drawer temporary absolute v-model="drawer">
        <v-list-item class="nav-content">
          <v-list-item-content>
            <v-list-item-title class="title">
              <v-img
                class="home-logo"
                :src="require('@/assets/logo.png')"
                height="63px"
                contain
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense nav>
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
              color="success"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
              color="success"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <v-list-item @click="signOut()">
            <v-list-item-action>
              <v-icon>fas fa-sign-out-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>ออกจากระบบ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div v-else>
      <v-navigation-drawer permanent app floating>
        <v-list-item class="nav-content">
          <v-list-item-content>
            <v-list-item-title class="title">
              <v-img
                class="home-logo"
                :src="require('@/assets/auth-logo.png')"
                height="63px"
                contain
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense nav>
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
              color="success"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
              color="success"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <v-list-item @click="signOut()">
            <v-list-item-action>
              <v-icon>fas fa-sign-out-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>ออกจากระบบ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-app-bar app color="white" flat height="64">
      <v-toolbar-title class="d-flex align-center">
        <v-btn
          icon
          v-if="hamburger"
          @click.stop="drawer = !drawer"
          class="mr-2"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <span class="shopname-text">{{ shopName }}</span>
      </v-toolbar-title>
      <v-spacer />
      <!-- <v-row v-show="shopList.length > 0" class="mr-2">
        <v-col offset-sm="6" sm="6">
          <v-select
            :items="shopList"
            :value="selectedShop"
            @change="handleChangeShop"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row> -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="secondary" depressed text v-bind="attrs" v-on="on">
            <span class="mr-2">{{ userName }}</span>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>
          <div v-for="(item, index) in generateUserMenu" :key="index">
            <v-list-item-group color="success">
              <v-list-item
                v-show="!item.subGroup"
                :key="index"
                :to="item.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-menu left offset-x :key="`submain-${index}`">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  depressed
                  text
                  block
                  v-bind="attrs"
                  v-on="on"
                  v-show="item.subGroup"
                  class="branch-button"
                >
                  {{ item.name }}
                </v-btn>
              </template>
              <v-list dense nav>
                <v-list-item
                  v-for="subItem in item.subItems"
                  :key="subItem.value"
                  :id="subItem.value"
                >
                  <!-- <v-list-item-content>
                    <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                  </v-list-item-content> -->
                  <v-btn
                    color="success"
                    depressed
                    text
                    block
                    class="branch-button"
                    :value="subItem.active"
                    @click="
                      !subItem.active
                        ? handleChangeShop(subItem.value)
                        : () => {}
                    "
                  >
                    {{ subItem.text }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list>
      </v-menu>
    </v-app-bar>
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      :onClose="() => onCloseAlert()"
    />
    <div v-show="isLoading" class="loading-process">
      <v-progress-circular indeterminate color="white" />
    </div>
    <CustomModal
      :modal="modal"
      @confirm="submitAction"
      disableCancel
      :disabled="checkPdpa"
    >
      <template v-slot:header>
        <div v-if="pdpaDetail !== null">
          <h4>{{ pdpaDetail.title }}</h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="container" v-if="pdpaDetail !== null">
          <!-- <div v-for="(item, index) in pdpa" :key="index"> -->
          <div v-html="pdpaDetail.content" />
          <!-- </div> -->
        </div>
        <div v-if="pdpaDetail !== null && pdpaDetail.subConsent">
          <v-row
            class="ma-0"
            v-for="(item, index) in pdpaDetail.subConsent"
            :key="index"
          >
            <v-col class="pa-0" cols="1">
              <v-checkbox v-model="item.checkbox" />
            </v-col>
            <v-col class="pa-0 align-self-center">
              <div v-html="parseText(item.description)" />
            </v-col>
          </v-row>
        </div>
        <v-row
          class="ma-0"
          v-else-if="pdpaDetail !== null && !pdpaDetail.subConsent"
        >
          <v-col class="pa-0" cols="1">
            <v-checkbox v-model="pdpaDetail.checkbox" />
          </v-col>
          <v-col class="pa-0 align-self-center">
            {{ pdpaDetail.agreeText }}
          </v-col>
        </v-row>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { forEach, findIndex, isEmpty, reduce, size } from "lodash";
import Swal from "sweetalert2";
import Utils from "@/mixin";
import { getJWTShop, getJWTToken, PERMISSION } from "@/plugins/auth";

import Alert from "@/components/Alert.vue";
import CustomModal from "@/components/Modal";

export default {
  components: {
    Alert,
    CustomModal,
  },
  created() {
    this.jwtToken = getJWTToken();
    if (isEmpty(this.jwtToken)) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่", "error").then(
        () => {
          this.logout();
        }
      );
    }
    window.addEventListener("resize", this.checkHamburger);
    this.flag = true;
  },
  destroyed() {
    window.removeEventListener("resize", this.checkHamburger);
  },
  computed: {
    generateMenu() {
      const { jwtToken } = this;
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          return this.adminMenu;
        }
        case PERMISSION.SHOP_ADMIN: {
          return this.agentMenu;
        }
        default: {
          return [];
        }
      }
    },
    snackbar() {
      if (!isEmpty(this.alert?.message)) return true;
      return false;
    },
    alertText() {
      if (!isEmpty(this.alert?.message)) return this.alert?.message;
      return "";
    },
    alertType() {
      if (!isEmpty(this.alert?.message)) return this.alert?.type;
      return "";
    },
    shopName() {
      const { jwtToken, shopList, selectedShop } = this;
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          return "ช้างอินเตอร์เนชั่นแนล";
        }
        case PERMISSION.SHOP_ADMIN: {
          let result = "";
          if (size(shopList) > 0 && selectedShop !== "") {
            const index = findIndex(shopList, (i) => i.value === selectedShop);
            result = shopList[index].text;
          }
          return result;
        }
        default: {
          return jwtToken?.name;
        }
      }
    },
    userName() {
      const { jwtToken } = this;
      return jwtToken?.name;
    },
    generateUserMenu() {
      const { accountMenu, shopList, selectedShop } = this;
      const result = accountMenu;
      const recheckResult = reduce(
        result,
        (a, b) => {
          if (b.name !== "เลือกร้านค้า") a.push(b);
          return a;
        },
        []
      );
      if (size(shopList) > 0 && selectedShop !== "") {
        const subItems = reduce(
          shopList,
          (a, b) => {
            if (b.value === selectedShop) {
              a.push({ ...b, active: true });
            } else {
              a.push({ ...b, active: false });
            }
            return a;
          },
          []
        );
        recheckResult.push({
          name: "เลือกร้านค้า",
          subGroup: true,
          active: true,
          subItems,
        });
      }
      return recheckResult;
    },
    checkPdpa() {
      const { pdpaDetail } = this;
      let result = false;
      if (pdpaDetail !== null) {
        if (pdpaDetail.subConsent) {
          forEach(pdpaDetail.subConsent, (i) => {
            if (!i.checkbox && i.isRequired) result = true;
          });
        } else {
          if (pdpaDetail.checkbox !== undefined && !pdpaDetail.checkbox)
            result = true;
          else if (pdpaDetail.checkbox === undefined) result = true;
        }
      }
      return result;
    },
  },
  data: () => ({
    accountMenu: [
      // { name: "โปรไฟล์ของฉัน", pathName: "/account" },
      { name: "เปลี่ยนรหัสผ่าน", pathName: "/setting" },
      { name: "ข้อมูลการยินยอม", pathName: "/pdpa" },
    ],
    adminMenu: [
      // {
      //   id: "dashboard",
      //   iconName: "mdi-chart-bar",
      //   pathName: "/",
      //   name: "แดชบอร์ด",
      // },
      {
        id: "all-dashboard",
        iconName: "mdi-chart-bar",
        name: "แดชบอร์ด",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "dashboard",
            name: "แดชบอร์ดภาพรวมร้านค้า",
            pathName: "/",
          },
          // {
          //   id: "dashboard-retail",
          //   name: "แดชบอร์ดการสั่งซื้อ",
          //   pathName: "/dashboard-retail",
          // },
          // {
          //   id: "dashboard-coupon",
          //   name: "แดชบอร์ดการใช้คูปอง",
          //   pathName: "/dashboard-coupon",
          // },
        ],
      },
      {
        id: "all-order",
        iconName: "mdi-clipboard-list-outline",
        name: "ออเดอร์",
        subGroup: true,
        active: true,
        subItems: [{ id: "order", name: "รายการออเดอร์", pathName: "/order" }],
      },
      {
        id: "point-setting",
        iconName: "mdi-bitcoin",
        pathName: "/point-setting",
        name: "ตั้งค่าคะแนน",
      },
      {
        id: "all-coupon",
        iconName: "mdi-ticket-percent-outline",
        name: "ตั้งค่าคูปอง",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "premium-product",
            name: "สินค้าพรีเมี่่ยม",
            pathName: "/admin/premium-product",
          },
          {
            id: "coupon",
            name: "เงื่อนไขการใช้คูปอง",
            pathName: "/admin/coupon",
          },
          {
            id: "admin-coupon-report",
            name: "เอกสารการใช้คูปอง",
            pathName: "/admin/setting/coupon-report",
          },
        ],
      },
      {
        id: "all-product",
        iconName: "mdi-package-variant-closed",
        name: "สินค้า",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "admin-producttype",
            name: "ประเภทสินค้า",
            pathName: "/admin/product-type",
          },
          {
            id: "admin-category",
            name: "หมวดหมู่",
            pathName: "/admin/category",
          },
          {
            id: "admin-brand",
            name: "แบรนด์",
            pathName: "/admin/brand",
          },
          {
            id: "admin-product",
            name: "สินค้าไทยเบฟฯ",
            pathName: "/admin/product",
          },
          {
            id: "agent-product",
            name: "สินค้าผู้ขาย",
            pathName: "/admin/agent-product",
          },
        ],
      },
      // {
      //   id: "manage-agent",
      //   iconName: "mdi-store",
      //   name: "ร้านค้าเอเย่นต์",
      //   subGroup: true,
      //   active: false,
      //   subItems: [
      //     {
      //       id: "manage-permission",
      //       name: "จัดการสิทธิ์ใช้งาน",
      //       pathName: "/manage-permission",
      //     },
      //   ],
      // },
    ],
    agentMenu: [
      // {
      //   id: "dashboard",
      //   iconName: "mdi-chart-bar",
      //   pathName: "/",
      //   name: "แดชบอร์ด",
      // },
      {
        id: "all-dashboard",
        iconName: "mdi-chart-bar",
        name: "แดชบอร์ด",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "dashboard",
            name: "แดชบอร์ดภาพรวมร้านค้า",
            pathName: "/",
          },
          {
            id: "dashboard-retail",
            name: "แดชบอร์ดการสั่งซื้อ",
            pathName: "/dashboard-retail",
          },
          {
            id: "dashboard-coupon",
            name: "แดชบอร์ดการใช้คูปอง",
            pathName: "/dashboard-coupon",
          },
        ],
      },
      {
        id: "notification-setting",
        iconName: "mdi-alarm-light",
        name: "แจ้งเตือน",
        pathName: "/agent/notification",
      },
      {
        id: "all-order",
        iconName: "mdi-clipboard-list-outline",
        name: "ออเดอร์",
        subGroup: true,
        active: true,
        subItems: [{ id: "order", name: "รายการออเดอร์", pathName: "/order" }],
      },
      {
        id: "all-product",
        iconName: "mdi-package-variant-closed",
        name: "สินค้า",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "agent-product",
            name: "รายการสินค้า",
            pathName: "/agent/product",
          },
          // {
          //   id: "admin-product",
          //   name: "สินค้าไทยเบฟฯ",
          //   pathName: "/admin/product",
          // },
        ],
      },
      {
        id: "all-setting",
        iconName: "mdi-cog-outline",
        name: "ตั้งค่าร้านค้า",
        subGroup: true,
        active: true,
        subItems: [
          {
            id: "payment-setting",
            name: "การชำระเงิน",
            pathName: "/agent/setting/payment",
          },
          {
            id: "banking-setting",
            name: "บัญชีธนาคาร",
            pathName: "/agent/setting/banking",
          },
          {
            id: "promotion-setting",
            name: "ตั้งค่าโปรโมชั่น",
            pathName: "/agent/setting/promotion",
          },
          {
            id: "coupon-report",
            name: "เอกสาร",
            pathName: "/agent/setting/coupon-report",
          },
        ],
      },
    ],
    jwtToken: null,
    hamburger: false,
    drawer: false,
    flag: false,
    modal: false,
    pdpa: null,
    pdpaDetail: null,
  }),
  watch: {
    snackbar(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.alert.message = "";
        }, 3000);
      }
    },
  },
  mixins: [Utils],
  async mounted() {
    const { jwtToken } = this;
    const token = this.auth.token;
    if (isEmpty(token)) {
      this.$router.push({ name: "Login" });
    }
    this.getConsent();
    switch (jwtToken?.role) {
      case PERMISSION.APP_ADMIN: {
        break;
      }
      case PERMISSION.SHOP_ADMIN: {
        const shopList = getJWTShop();
        await this.updateShopList(shopList);
        if (this.selectedShop === "") {
          await this.selectedShopList(shopList[0].id);
        }
        break;
      }
      default: {
        break;
      }
    }
    this.checkHamburger();
  },
  methods: {
    parseText(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
    async getConsent() {
      const res = await this.checkUserConsent();
      const pdpa = res?.data;
      const result = [];
      forEach(pdpa, (i) => {
        const { isForce, title, content, subConsent } = i;
        if (isForce) {
          this.modal = true;
          result.push({
            ...i,
            id: i.id,
            title,
            content,
            subConsent,
          });
        }
      });
      if (size(result) > 0) {
        this.pdpa = result;
        this.pdpaDetail = result[0];
      }
    },
    async submitAction(event = false) {
      if (event) {
        const { pdpaDetail } = this;
        const SubConsentAccept = reduce(
          pdpaDetail.subConsent,
          (a, b) => {
            a.push({ subConsentId: b.subConsentId, isAccept: b.checkbox || false });
            return a;
          },
          []
        );
        const result = [
          {
            consentId: pdpaDetail.id,
            isAccept: true,
            SubConsentAccept,
          },
        ];
        this.modal = false;
        await this.acceptUserConsent(result).then(() => {
          this.getConsent();
        });
      }
    },
    checkHamburger(e) {
      const md = 992;
      const innerWidth = e?.target?.innerWidth || window.innerWidth;
      this.hamburger = innerWidth > md ? false : true;
      // console.log(innerWidth, this.hamburger);
      if (this.flag || innerWidth > md) {
        this.drawer = false;
        this.flag = false;
      }
    },
    onCloseAlert() {
      this.alert.message = "";
    },
    signOut() {
      this.logout();
    },
    async handleChangeShop(id) {
      await this.selectedShopList(id);
    },
  },
};
</script>

<style lang="scss">
$green: #009b3f;

.branch-button {
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0 !important;
  font-size: 0.8125rem !important;
  padding: 2px 8px !important;
  span {
    justify-content: flex-start;
  }
}

#auth-layout {
  min-height: 100vh;
  position: relative;
  @media (max-width: 600px) {
    .shopname-text {
      font-size: 14px;
      white-space: normal;
    }
  }
  .v-overlay {
    // min-width: calc(1184px + 256px);
  }
  .v-navigation-drawer {
    position: fixed;
  }
  .nav-content {
    height: 64px !important;
  }
  header,
  .nav-content {
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  }
  .v-list {
    .v-list-item--active {
      color: $green;
      &::before {
        background: gray;
      }
    }
  }
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>