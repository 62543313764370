<template>
  <div class="promotion-point-container">
    <h2 class="mb-3">ตั้งค่าคะแนนโปรโมชั่น</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="dataList"
        :server-items-length="pointPromotions ? pointPromotions.total : total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" :height="checkScreen">
            <v-row>
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="12" lg="8">
                    <v-text-field
                      :disabled="isLoading"
                      v-model="keyword"
                      label="ค้นหาจาก"
                      single-line
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-combobox
                      v-model="status"
                      item-value="value"
                      item-text="text"
                      :items="statusItems"
                      label="สถานะ"
                      outlined
                      dense
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="2">
                <v-btn
                  class="table-button"
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  :block="checkMdScreen"
                  >ค้นหา</v-btn
                >
              </v-col>
              <v-col lg="4">
                <div class="d-flex justify-end">
                  <v-btn
                    color="success"
                    class="mb-2"
                    :block="checkMdScreen"
                    @click="createForm"
                    >+ เพิ่มคะแนนโปรโมชั่น</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ $generateDate(item.createdAt) }}
        </template>
        <template v-slot:item.name="{ item }">
          <b class="success--text">{{ item.name }}</b>
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            v-if="item.status === 'active'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="กำลังดำเนินการ"
          />
          <StatusChip
            v-else-if="item.status === 'waiting'"
            color="#fff2cd"
            text-color="#f67b00"
            chipText="รอดำเนินการ"
          />
          <StatusChip
            v-else-if="item.status !== 'active' && item.status !== 'waiting'"
            color="#f4cbcc"
            text-color="#da291c"
            chipText="หมดอายุ"
          />
        </template>
        <template v-slot:item.startTime="{ item }">
          {{ $generateDate(item.startTime) }}
        </template>
        <template v-slot:item.endTime="{ item }">
          {{ $generateDate(item.endTime) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item.id)"
            ><b>ดูข้อมูล</b></v-btn
          >
          <v-btn depressed text color="error" @click="deleteItem(item)"
            ><b>ลบ</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Confirm v-model="conf.status" :data="conf" @confirm="deleteData" />
    <PromotionPointForm
      :modal="dialog"
      :clearData="resetForm"
      :create="create"
      @submit="submit"
      :editId="editId"
    />
  </div>
</template>

<script>
import moment from "moment";
import { get, reduce } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import StatusChip from "@/components/StatusChip";
import PromotionPointForm from "./PromotionPointForm";

import Confirm from "@/components/Confirm";

export default {
  name: "Admin-PromotionPoint",
  components: {
    StatusChip,
    PromotionPointForm,
    Confirm,
  },
  computed: {
    dataList() {
      const { pointPromotions } = this;
      const result = reduce(
        pointPromotions.data,
        (o, e) => {
          const currentDate = moment();
          const startDate = moment(e.startTime);
          const endDate = moment(e.endTime);
          o.push({
            ...e,
            status:
              startDate <= currentDate && endDate >= currentDate
                ? "active"
                : endDate < currentDate
                ? "closed"
                : "waiting",
          });
          return o;
        },
        []
      );
      return result;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "64px";
      } else if (windowWidth < 1440 && windowWidth >= 1264) {
        return "64px";
      } else if (windowWidth < 1264) {
        return "250px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1264) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
  data: () => ({
    editId: "",
    dialog: false,
    create: false,
    keyword: "",
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      {
        text: "วันที่สร้าง",
        value: "createdAt",
        sortable: false,
      },
      { text: "ชื่อโปรโมชั่น", value: "name", sortable: false },
      { text: "สถานะ", value: "status", sortable: false },
      { text: "วันที่เริ่มต้น", value: "startTime", sortable: false },
      {
        text: "วันที่สิ้นสุด",
        value: "endTime",
        sortable: false,
      },
      {
        text: "ดำเนินการ",
        value: "actions",
        align: "center",
        sortable: false,
        width: "200px",
      },
    ],
    status: "",
    statusItems: [
      { text: "กำลังดำเนินการ", value: "active" },
      { text: "รอดำเนินการ", value: "waiting" },
      { text: "หมดอายุ", value: "closed" },
    ],
    conf: {
      status: false,
      title: "ยืนยันการลบข้อมูล?",
      text: `คะแนนโปรโมชั่น`,
      cancelText: `ยกเลิก`,
      confirmText: `ยืนยัน`,
    },
    windowWidth: window.innerWidth,
  }),
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.status) {
        condition.status = this.status.value;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getPointPromotions(condition);
    },
    selectItem(id) {
      this.editId = id;
      this.toggleForm();
    },
    searchKeyword() {
      this.getInitialData();
    },
    toggleForm() {
      this.dialog = !this.dialog;
    },
    resetForm() {
      this.dialog = false;
      this.create = false;
      this.setInitialDialog();
    },
    createForm() {
      this.dialog = !this.dialog;
      this.create = !this.create;
    },
    setInitialDialog() {
      this.editId = "";
    },
    async submit(data = {}) {
      const premiums = reduce(
        data.premiums,
        (o, e) => {
          o.push({
            ...e,
            unit: e.unit === "จำนวน" ? "amount" : "qty",
            volume: parseFloat(e.volume),
          });
          return o;
        },
        []
      );
      const conditions = reduce(
        data.conditions,
        (o, e) => {
          o.push({
            ...e,
            volume: parseFloat(e.volume),
          });
          return o;
        },
        []
      );
      const submitForm = {
        name: get(data, "name", ""),
        description: get(data, "description", ""),
        startTime: moment(get(data, "startTime")).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(get(data, "endTime")).format("YYYY-MM-DD HH:mm:ss"),
        conditions,
        premiums,
      };

      if (this.editId !== "") {
        submitForm.id = this.editId;
        await this.editPointPromotion(submitForm);
      } else {
        await this.createPointPromotion(submitForm);
      }

      this.editId = "";
      this.getInitialData();
    },
    deleteItem(data = {}) {
      this.conf = {
        ...this.conf,
        text: `คะแนนโปรโมชั่น ${data?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deletePointPromotion({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
.promotion-point-container {
  .table-button {
    margin-top: 3px;
  }
}
</style>