import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  products: {
    data: [],
    total: 0,
  },
  premiumProducts: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_PRODUCTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/products', data.form)
      state.products.data = res?.data?.data || []
      state.products.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_AGENT_PRODUCTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/products/agents', data.form)
      state.products.data = res?.data?.data || []
      state.products.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_PREMIUM_PRODUCTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/premium-products', data.form)
      state.premiumProducts.data = res?.data?.data || []
      state.premiumProducts.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_PRODUCTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.selectedShop}/products`,
        data.form
      )
      state.products.data = res?.data?.data || []
      state.products.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/cms/products', data.form)
      state.products.data.push(res.data)
      state.products.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_AGENT_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/shops/${data.selectedShop}/products`,
        data.form
      )
      state.products.data.push(res.data)
      state.products.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_PREMIUM_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/premium-products', data.form)
      state.premiumProducts.data.push(res.data)
      state.premiumProducts.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/cms/products/${data.form?.id}`, data.form)

      const products = cloneDeep(state.products.data)
      const index = findIndex(products, { id: res?.data?.id })

      products[index] = res.data
      state.products = {
        ...state.products,
        data: products,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_AGENT_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/cms/shops/${data.selectedShop}/products/${data.form?.id}`,
        data.form
      )

      const products = cloneDeep(state.products.data)
      const index = findIndex(products, { id: res?.data?.id })

      products[index] = res.data
      state.products = {
        ...state.products,
        data: products,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PREMIUM_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/premium-products/${data.form?.id}`,
        data.form
      )

      const premiumProducts = cloneDeep(state.premiumProducts.data)
      const index = findIndex(premiumProducts, { id: res?.data?.id })

      premiumProducts[index] = res.data
      state.premiumProducts = {
        ...state.premiumProducts,
        data: premiumProducts,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/cms/products/${data.form?.id}`)

      const products = cloneDeep(state.products.data)
      const index = findIndex(products, { id: data.form?.id })

      products.splice(index, 1)
      state.products = {
        ...state.products,
        data: products,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_PREMIUM_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/premium-products/${data.form?.id}`)

      const premiumProducts = cloneDeep(state.premiumProducts.data)
      const index = findIndex(premiumProducts, { id: data.form?.id })

      premiumProducts.splice(index, 1)
      state.premiumProducts = {
        ...state.premiumProducts,
        data: premiumProducts,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async TRANSFER_PRODUCT(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_POST(
        `/api/products/${data.paramId}/clone/${data.selectedShop}`,
        data.form
      )
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async UPDATE_PRODUCT_STATUS(state, data) {
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('status', data.form?.status)
      const res = await REQUEST_POST(
        `/cms/products/${data.form?.productId}/status`,
        formData
      )
      const { status } = res
      let alert
      data.toggleLoading()
      switch (status) {
        case 200: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
          break
        }
        default: {
          // Alert
          alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.ERROR,
            'error',
            'error'
          )
          break
        }
      }
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getProducts(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_PRODUCTS(state, { toggleLoading, form, toggleAlert })
  },
  getAgentProducts(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_AGENT_PRODUCTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getPremiumProducts(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_PREMIUM_PRODUCTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  getShopProducts(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_SHOP_PRODUCTS(state, {
      toggleLoading,
      selectedShop,
      form,
      toggleAlert,
    })
  },
  createProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_PRODUCT(state, { toggleLoading, form, toggleAlert })
  },
  createAgentProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.CREATE_AGENT_PRODUCT(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  createPremiumProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_PREMIUM_PRODUCT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  editProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_PRODUCT(state, { toggleLoading, form, toggleAlert })
  },
  editAgentProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const { shopId } = form
    const selectedShop =
      shopId !== undefined ? shopId : context?.rootGetters?.selectedShop
    return mutations.EDIT_AGENT_PRODUCT(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  editPremiumProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_PREMIUM_PRODUCT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  deleteProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_PRODUCT(state, { toggleLoading, form, toggleAlert })
  },
  deletePremiumProduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_PREMIUM_PRODUCT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  transferProduct(context, paramId) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.TRANSFER_PRODUCT(state, {
      toggleLoading,
      selectedShop,
      paramId,
      toggleAlert,
    })
  },
  updateProductStatus(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPDATE_PRODUCT_STATUS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  products(state) {
    // eslint-disable-line
    return state.products
  },
  premiumProducts(state) {
    // eslint-disable-line
    return state.premiumProducts
  },
}

export const products = {
  state,
  mutations,
  actions,
  getters,
}
