<template>
  <v-menu
    v-model="modalDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="className"
        :label="label"
        prepend-inner-icon="mdi-calendar-month"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        hide-details
        clearable
        @click:clear="$emit('clear')"
        :value="displayValue"
      />
    </template>
    <v-date-picker
      color="success"
      :min="!disableMin ? min : undefined"
      :range="range"
      @input="updateInput"
      :value="dateValue"
      locale="th-TH"
    />
  </v-menu>
</template>

<script>
import { forEach, size } from "lodash";
import moment from "moment";

export default {
  computed: {
    dateValue: {
      get() {
        return this.value;
      },
      set() {
        return this.value;
      },
    },
    displayValue: {
      get() {
        const { value } = this;
        let result = "";

        if (size(value) > 0) {
          const newValue = [];
          let compare;
          if (size(value) > 1) {
            if (moment(value[0]).diff(value[1], "day") > 0) {
              compare = [value[1], value[0]];
            } else {
              compare = value;
            }
          } else {
            compare = value;
          }
          forEach(compare, (i) => {
            const date = new Date(i);
            const momentDate = moment(date)
              .add(543, "year")
              .format("YYYY-MM-DD");
            newValue.push(momentDate);
          });
          if (size(newValue) === 2) {
            result = newValue.join(" ~ ");
          } else {
            result = newValue;
          }
        }
        return result;
      },
      set() {
        return this.value;
      },
    },
    modalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        return this.dialog;
      },
    },
  },
  data: () => ({
    min: new Date().toISOString().substring(0, 10),
  }),
  props: {
    disableMin: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Date Input",
    },
    range: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String],
    },
    updateInput: {
      type: Function,
    },
  },
};
</script>