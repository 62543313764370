<template>
  <div
    id="reportContainer"
    ref="reportRef"
    :style="{ height: `${innerHeight}px` }"
  >
    Loading the report...
  </div>
</template>

<script>
import { service, factories, models } from "powerbi-client";
import * as config from "@/plugins/ciconfig";

export default {
  computed: {
    innerHeight() {
      const height = window.innerHeight - 65;
      return height;
    },
  },
  props: {
    accessToken: {
      type: String,
    },
    embedUrl: {
      type: String,
    },
    basicFilter: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    const { accessToken, embedUrl, basicFilter } = this;
    const powerbi = new service.Service(
      factories.hpmFactory,
      factories.wpmpFactory,
      factories.routerFactory
    );
    const embedConfiguration = {
      type: "report",
      tokenType: models.TokenType.Embed,
      accessToken,
      embedUrl,
      id: config.reportId,
      /*
      // Enable this setting to remove gray shoulders from embedded report
      settings: {
        background: models.BackgroundType.Transparent
      }
      */
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
      },
      filters: [basicFilter],
    };
    const reportContainer = document.getElementById("reportContainer");
    const report = powerbi.embed(reportContainer, embedConfiguration);

    // Clear any other loaded handler events
    report.off("loaded");

    // Triggers when a content schema is successfully loaded
    report.on("loaded", function () {
      // console.log("Report load successful");
    });

    // Clear any other rendered handler events
    report.off("rendered");

    // Triggers when a content is successfully embedded in UI
    report.on("rendered", function () {
      // console.log("Report render successful");
    });

    // Clear any other error handler event
    report.off("error");

    // Below patch of code is for handling errors that occur during embedding
    report.on("error", function (event) {
      const errorMsg = event.detail;

      // Use errorMsg variable to log error in any destination of choice
      console.error(errorMsg);
    });
  },
};
</script>

<style lang="scss">
iframe {
  border: none;
}
</style>