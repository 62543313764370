<template>
  <div class="order-container">
    <h2 class="mb-3">รายการออเดอร์</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        :height="tableHeight"
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="dataList"
        :server-items-length="orders ? orders.total : total"
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
            class="table-header"
            :height="checkScreen"
          >
            <v-row v-if="!adminReadOnly">
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-text-field
                  :disabled="isLoading"
                  v-model="keyword"
                  label="ค้นหาจากเลขที่"
                  single-line
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-combobox
                  :items="buyerItem"
                  className="mr-2"
                  v-model="buyerSelected"
                  label="ผู้ซื้อ"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0" cols="12" :md="true">
                <DateInput
                  label="วันที่สั่งซื้อ"
                  range
                  disableMin
                  :dialog="dateDialog"
                  :value="createdDate"
                  :updateInput="updateDateInput"
                  @clear="onClear"
                />
              </v-col>
              <v-col class="px-0 text-right pr-2" cols="12" lg="2" md="3">
                <v-btn
                  outlined
                  depressed
                  :block="checkMdScreen"
                  @click="searchKeyword"
                  color="#009b3f"
                  class="table-button mr-2"
                  >ค้นหา</v-btn
                >
                <v-btn
                  outlined
                  depressed
                  :block="checkMdScreen"
                  @click="submitAction"
                  :class="`table-button${checkMdScreen ? ' mt-3' : ''}`"
                >
                  ส่งออก
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="adminReadOnly">
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-text-field
                  :disabled="isLoading"
                  v-model="keyword"
                  label="ค้นหาจากเลขที่"
                  single-line
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <DateInput
                  label="วันที่สั่งซื้อ"
                  range
                  disableMin
                  :dialog="dateDialog"
                  :value="createdDate"
                  :updateInput="updateDateInput"
                  @clear="onClear"
                />
              </v-col>
              <v-col class="px-0 pr-2 pr-md-0" cols="12" :md="true">
                <v-combobox
                  :items="regionItem"
                  v-model="regionSelected"
                  label="ภาค"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col
                v-if="adminReadOnly && checkMdScreen"
                class="px-0 pr-2"
                cols="12"
                :md="true"
              >
                <v-combobox
                  :items="sellerItem"
                  v-model="sellerSelected"
                  label="ผู้ขาย"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col
                v-if="adminReadOnly && checkMdScreen"
                class="px-0 pr-2"
                cols="12"
                :md="true"
              >
                <v-combobox
                  :items="buyerItem"
                  className="mr-2"
                  v-model="buyerSelected"
                  label="ผู้ซื้อ"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0 text-right pr-2" cols="12" lg="2" md="3">
                <v-btn
                  outlined
                  depressed
                  :block="checkMdScreen"
                  @click="searchKeyword"
                  color="#009b3f"
                  class="table-button mr-2"
                  >ค้นหา</v-btn
                >
                <v-btn
                  outlined
                  depressed
                  :block="checkMdScreen"
                  @click="submitAction"
                  :class="`table-button${checkMdScreen ? ' mt-3' : ''}`"
                >
                  ส่งออก
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="adminReadOnly && !checkMdScreen"
              :class="`${collapse ? 'hidden' : 'show'}`"
            >
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-combobox
                  :items="sellerItem"
                  v-model="sellerSelected"
                  label="ผู้ขาย"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-combobox
                  :items="buyerItem"
                  className="mr-2"
                  v-model="buyerSelected"
                  label="ผู้ซื้อ"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <!-- <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-combobox
                  :items="retailItem"
                  className="mr-2"
                  v-model="retailSelected"
                  label="ร้านค้าปลีก"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col> -->
            </v-row>
            <v-row :class="`mt-0${collapse ? ' hidden' : ' show'}`">
              <v-col class="pl-0 pr-1">
                <div>สถานะ</div>
                <v-card
                  outlined
                  class="
                    pa-2
                    button-card
                    d-sm-flex
                    justify-sm-center
                    text-center
                  "
                >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1${status.pending ? ' active' : ''}`"
                    @click="
                      () => {
                        status = {
                          ...status,
                          pending: !status.pending,
                        };
                      }
                    "
                    >ที่ต้องยืนยัน</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      status.inprogress ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        status = {
                          ...status,
                          inprogress: !status.inprogress,
                        };
                      }
                    "
                    >กำลังดำเนินการ</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      status.complete ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        status = {
                          ...status,
                          complete: !status.complete,
                        };
                      }
                    "
                    >เสร็จสิ้น</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mt-2 mt-sm-0${status.cancel ? ' active' : ''}`"
                    @click="
                      () => {
                        status = {
                          ...status,
                          cancel: !status.cancel,
                        };
                      }
                    "
                    >ยกเลิก</v-btn
                  >
                </v-card>
              </v-col>
              <v-col class="pl-0 pr-1">
                <div>การชำระ</div>
                <v-card
                  outlined
                  class="
                    pa-2
                    button-card
                    d-sm-flex
                    justify-sm-center
                    text-center
                  "
                >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      paymentStatus.pending ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        paymentStatus = {
                          ...paymentStatus,
                          pending: !paymentStatus.pending,
                        };
                      }
                    "
                    >รอชำระ</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      paymentStatus.inform ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        paymentStatus = {
                          ...paymentStatus,
                          inform: !paymentStatus.inform,
                        };
                      }
                    "
                    >ที่ต้องตรวจสอบ</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mt-2 mt-sm-0${
                      paymentStatus.paid ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        paymentStatus = {
                          ...paymentStatus,
                          paid: !paymentStatus.paid,
                        };
                      }
                    "
                    >ชำระแล้ว</v-btn
                  >
                </v-card>
              </v-col>
              <v-col class="pl-0 pr-1">
                <div>การจัดส่ง</div>
                <v-card
                  outlined
                  class="
                    pa-2
                    button-card
                    d-sm-flex
                    justify-sm-center
                    text-center
                  "
                >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      shipStatus.pending ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        shipStatus = {
                          ...shipStatus,
                          pending: !shipStatus.pending,
                        };
                      }
                    "
                    >ที่ต้องส่ง</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      shipStatus.ship ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        shipStatus = {
                          ...shipStatus,
                          ship: !shipStatus.ship,
                        };
                      }
                    "
                    >รอยืนยันรับ</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mt-2 mt-sm-0${
                      shipStatus.deliver ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        shipStatus = {
                          ...shipStatus,
                          deliver: !shipStatus.deliver,
                        };
                      }
                    "
                    >ส่งสำเร็จ</v-btn
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row :class="`mt-0${collapse ? ' hidden' : ' show'}`">
              <v-col cols="12" md="4" class="pl-0 pr-1">
                <div>ประเภทลูกค้า</div>
                <v-card
                  outlined
                  class="
                    pa-2
                    button-card
                    d-sm-flex
                    justify-sm-center
                    text-center
                  "
                >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      shopType.subAgent ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        shopType = {
                          ...shopType,
                          subAgent: !shopType.subAgent,
                        };
                      }
                    "
                    >ซับ-เอเยนต์</v-btn
                  >
                  <v-btn
                    outlined
                    depressed
                    :class="`mr-1 mt-2 mt-sm-0${
                      shopType.retail ? ' active' : ''
                    }`"
                    @click="
                      () => {
                        shopType = {
                          ...shopType,
                          retail: !shopType.retail,
                        };
                      }
                    "
                    >ร้านปลีก</v-btn
                  >
                </v-card>
              </v-col>
              <v-col class="px-0 pb-5 pr-2 align-self-end" cols="12" md="4">
                <v-combobox
                  :items="couponItem"
                  v-model="couponSelected"
                  label="คูปอง"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                :class="`collapse-button text-center pa-0${
                  collapse ? ' rotate-icon' : ''
                }`"
              >
                <v-icon @click="collapse = !collapse">mdi-chevron-up</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.orderNumber="{ item }">
          <b class="success--text order-number" @click="selectItem(item)">{{
            item.orderNumber
          }}</b>
        </template>
        <template v-slot:item.seller.regionId="{ item }">
          {{ regionName(item.seller.regionId) }}
        </template>
        <template v-slot:item.seller.name="{ item }">
          <div v-if="item.seller.shopType === 'agent'" class="green--text">
            {{ item.seller.name }}
          </div>
          <div v-if="item.seller.shopType === 'retail'" class="blue--text">
            {{ item.seller.name }}
          </div>
          <div
            v-if="
              item.seller.shopType === 'sub-agent' ||
              item.seller.shopType === 'sub-direct'
            "
            class="light-green--text"
          >
            {{ item.seller.name }}
          </div>
        </template>
        <template v-slot:item.seller.shopType="{ item }">
          {{ shopTypeName(item.seller.shopType) }}
        </template>
        <template v-slot:item.buyer.name="{ item }">
          <div v-if="item.buyer.shopType === 'agent'" class="green--text">
            {{ item.buyer.name }}
          </div>
          <div v-if="item.buyer.shopType === 'retail'" class="blue--text">
            {{ item.buyer.name }}
          </div>
          <div
            v-if="
              item.buyer.shopType === 'sub-agent' ||
              item.buyer.shopType === 'sub-direct'
            "
            class="light-green--text"
          >
            {{ item.buyer.name }}
          </div>
        </template>
        <template v-slot:item.buyer.shopType="{ item }">
          {{ shopTypeName(item.buyer.shopType) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ $generateDate(item.createdAt) }}
        </template>
        <template v-slot:item.net="{ item }">
          {{ $numberWithCommas(item.net) }}
        </template>
        <template v-slot:item.masterProductPrice="{ item }">
          {{ $numberWithCommas(item.masterProductPrice) }}
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            v-if="item.status === 'complete'"
            color="#d9ead3ff"
            text-color="#009b3f"
            chipText="เสร็จสิ้น"
          />
          <StatusChip
            v-else-if="item.status === 'inprogress'"
            color="#fff2cd"
            text-color="#f67b00"
            chipText="กำลังดำเนินการ"
          />
          <StatusChip
            v-else-if="item.status === 'pending'"
            color="#f4cbcc"
            text-color="#da291c"
            chipText="ที่ต้องยืนยัน"
          />
          <StatusChip
            v-else-if="item.status === 'cancel'"
            color="#f3f3f3ff"
            text-color="#666666"
            chipText="ยกเลิก"
          />
        </template>
        <template v-slot:item.paymentStatus="{ item }">
          <div v-if="item.status !== 'pending'">
            <StatusChip
              v-if="item.paymentStatus === 'paid'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="ชำระแล้ว"
            />
            <StatusChip
              v-else-if="item.paymentStatus === 'pending'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="รอชำระ"
            />
            <StatusChip
              v-else-if="item.paymentStatus === 'inform'"
              color="#f4cbcc"
              text-color="#da291c"
              chipText="ที่ต้องตรวจสอบ"
            />
          </div>
        </template>
        <template v-slot:item.shipStatus="{ item }">
          <div v-if="item.status !== 'pending'">
            <StatusChip
              v-if="item.shipStatus === 'ship'"
              color="#fff2cd"
              text-color="#f67b00"
              chipText="รอยืนยันรับ"
            />
            <StatusChip
              v-else-if="item.shipStatus === 'pending'"
              color="#f4cbcc"
              text-color="#da291c"
              chipText="ที่ต้องส่ง"
            />
            <StatusChip
              v-else-if="item.shipStatus === 'deliver'"
              color="#d9ead3ff"
              text-color="#009b3f"
              chipText="ส่งสำเร็จ"
            />
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item)"
            ><b>ดูข้อมูล</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <OrderDetail
      :modal="modal"
      :orderDetailId="orderDetailId"
      :submitAction="reset"
      :adminReadOnly="adminReadOnly"
      @confirmOrder="confirmOrder"
      @confirmPayment="confirmPayment"
      @confirmShipment="confirmShipment"
    />
  </div>
</template>

<script>
import { forEach, reduce, size } from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";

import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";
import { getJWTToken, PERMISSION } from "@/plugins/auth";

import StatusChip from "@/components/StatusChip";
import DateInput from "@/components/DateInput";

import OrderDetail from "./OrderDetail";

export default {
  name: "Order",
  components: {
    StatusChip,
    DateInput,
    OrderDetail,
  },
  computed: {
    dataList() {
      const { orders } = this;
      const result = reduce(
        orders.data,
        (o, e) => {
          o.push({
            ...e,
          });
          return o;
        },
        []
      );
      return result;
    },
    buyerItem() {
      const { buyersList, jwtToken } = this;
      let result = [];
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          result = reduce(
            buyersList.data,
            (o, e) => {
              o.push(e.name);
              return o;
            },
            []
          );
          break;
        }
        case PERMISSION.SHOP_ADMIN: {
          result = reduce(
            buyersList.data,
            (o, e) => {
              o.push(e.buyer.name);
              return o;
            },
            []
          );
          break;
        }
        default: {
          break;
        }
      }
      return result;
    },
    headers() {
      const { jwtToken } = this;
      let result = [
        { text: "เลขที่ออเดอร์", value: "orderNumber", align: "start" },
        { text: "วันที่สั่งซื้อ", value: "createdAt", width: "100px" },
        { text: "ภาค", value: "seller.regionId", sortable: false },
        { text: "รหัสผู้ขาย", value: "seller.code", sortable: false },
        {
          text: "ชื่อผู้ขาย",
          value: "seller.name",
          width: "250px",
          sortable: false,
        },
        {
          text: "ประเภทผู้ขาย",
          value: "seller.shopType",
          sortable: false,
        },
        { text: "รหัสผู้ซื้อ", value: "buyer.code", sortable: false },
        {
          text: "ชื่อผู้ซื้อ",
          value: "buyer.name",
          width: "250px",
          sortable: false,
        },
        {
          text: "ประเภทผู้ซื้อ",
          value: "buyer.shopType",
          sortable: false,
        },
        { text: "ราคารวม", value: "net", align: "right" },
        {
          text: "ราคาไทยเบฟ",
          value: "masterProductPrice",
          align: "right",
          sortable: false,
        },
        { text: "ประเภทชำระ", value: "paymentType.name", sortable: false },
        { text: "สถานะ", value: "status", width: "70px", sortable: false },
        // { text: "หมายเหตุ", value: "note", sortable: false },
        {
          text: "การชำระ",
          value: "paymentStatus",
          width: "70px",
          sortable: false,
        },
        {
          text: "การจัดส่ง",
          value: "shipStatus",
          width: "70px",
          sortable: false,
        },
        { text: "ดำเนินการ", value: "actions", sortable: false },
      ];
      switch (jwtToken?.role) {
        case PERMISSION.SHOP_ADMIN: {
          result = [
            { text: "เลขที่ออเดอร์", value: "orderNumber", align: "start" },
            { text: "วันที่สั่งซื้อ", value: "createdAt", width: "100px" },
            { text: "รหัสผู้ซื้อ", value: "buyer.code", sortable: false },
            {
              text: "ชื่อผู้ซื้อ",
              value: "buyer.name",
              width: "250px",
              sortable: false,
            },
            {
              text: "ประเภทผู้ซื้อ",
              value: "buyer.shopType",
              sortable: false,
            },
            { text: "ราคารวม", value: "net", align: "right" },
            { text: "ประเภทชำระ", value: "paymentType.name", sortable: false },
            { text: "สถานะ", value: "status", width: "70px", sortable: false },
            // { text: "หมายเหตุ", value: "note", sortable: false },
            {
              text: "การชำระ",
              value: "paymentStatus",
              width: "70px",
              sortable: false,
            },
            {
              text: "การจัดส่ง",
              value: "shipStatus",
              width: "70px",
              sortable: false,
            },
            { text: "ดำเนินการ", value: "actions", sortable: false },
          ];
          break;
        }
        default: {
          break;
        }
      }
      return result;
    },
    retailItem() {
      const { shopTypeList, jwtToken } = this;
      let result = [];
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          result = reduce(
            shopTypeList.data,
            (o, e) => {
              o.push(e.name);
              return o;
            },
            []
          );
          break;
        }
        // case PERMISSION.SHOP_ADMIN: {
        //   result = reduce(
        //     shopTypeList.data,
        //     (o, e) => {
        //       o.push(e.buyer.name);
        //       return o;
        //     },
        //     []
        //   );
        //   break;
        // }
        default: {
          break;
        }
      }
      return result;
    },
    sellerItem() {
      const { sellersList } = this;
      const result = reduce(
        sellersList.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    regionItem() {
      const { regionList } = this;
      const result = reduce(
        regionList.data,
        (o, e) => {
          o.push(e.region_desc);
          return o;
        },
        []
      );
      return result;
    },
    couponItem() {
      const { coupons } = this;
      const result = reduce(
        coupons.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    tableHeight() {
      // const { windowWidth, jwtToken, collapse } = this;
      // let height = "auto";
      // if (jwtToken?.role === PERMISSION.APP_ADMIN) {
      //   if (windowWidth >= 600) {
      //     if (collapse) height = "450px";
      //     else height = "290px";
      //   }
      // } else {
      //   if (windowWidth >= 600) {
      //     if (collapse) height = "450px";
      //     else height = "360px";
      //   }
      // }
      // return height;
      return "100%";
    },
    checkScreen() {
      const { windowWidth, jwtToken, collapse } = this;
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          if (collapse) {
            if (windowWidth >= 1440) {
              return "80px";
            } else if (windowWidth < 1440 && windowWidth >= 960) {
              return "80px";
            } else if (windowWidth < 960 && windowWidth >= 734) {
              return "450px";
            } else if (windowWidth < 734 && windowWidth >= 600) {
              return "450px";
            } else if (windowWidth < 600 && windowWidth >= 445) {
              return "450px";
            } else if (windowWidth < 445) {
              return "450px";
            }
          }
          if (windowWidth >= 1440) {
            // return "240px";
            return "335px";
          } else if (windowWidth < 1440 && windowWidth >= 960) {
            // return "340px";
            return "435px";
          } else if (windowWidth < 960 && windowWidth >= 734) {
            // return "645px";
            return "740px";
          } else if (windowWidth < 734 && windowWidth >= 600) {
            // return "745px";
            return "840px";
          } else if (windowWidth < 600 && windowWidth >= 445) {
            // return "745px";
            return "840px";
          } else if (windowWidth < 445) {
            // return "790px";
            return "955px";
          }
          break;
        }
        case PERMISSION.SHOP_ADMIN: {
          if (collapse) {
            if (windowWidth >= 1440) {
              return "80px";
            } else if (windowWidth < 1440 && windowWidth >= 960) {
              return "80px";
            } else if (windowWidth < 960 && windowWidth >= 734) {
              return "320px";
            } else if (windowWidth < 734 && windowWidth >= 600) {
              return "320px";
            } else if (windowWidth < 600 && windowWidth >= 445) {
              return "320px";
            } else if (windowWidth < 445) {
              return "320px";
            }
          }
          if (windowWidth >= 1440) {
            // return "170px";
            return "265px";
          } else if (windowWidth < 1440 && windowWidth >= 960) {
            // return "270px";
            return "365px";
          } else if (windowWidth < 960 && windowWidth >= 734) {
            // return "510px";
            return "605px";
          } else if (windowWidth < 734 && windowWidth >= 600) {
            // return "620px";
            return "715px";
          } else if (windowWidth < 600 && windowWidth >= 445) {
            // return "620px";
            return "715px";
          } else if (windowWidth < 445) {
            // return "660px";
            return "825px";
          }
          break;
        }
        default: {
          break;
        }
      }

      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.jwtToken = getJWTToken();
    this.getRegions();
  },
  data: () => ({
    modal: false,
    dialog: false,
    dateDialog: false,
    createdDate: [],
    itemList: [],
    keyword: "",
    options: {
      sortBy: ["createdAt"],
      sortDesc: [true],
      itemsPerPage: 50,
    },
    buyerSelected: "",
    retailSelected: "",
    sellerSelected: "",
    regionSelected: "",
    couponSelected: "",
    itemsPerPageOptions: [],
    total: 0,
    paymentStatus: {
      pending: false,
      inform: false,
      paid: false,
    },
    shipStatus: {
      pending: false,
      ship: false,
      deliver: false,
    },
    shopType: {
      subAgent: false,
      retail: false,
    },
    status: {
      pending: false,
      inprogress: false,
      complete: false,
      cancel: false,
    },
    orderDetailId: null,
    jwtToken: null,
    adminReadOnly: false,
    windowWidth: window.innerWidth,
    flag: false,
    collapse: false,
  }),
  mounted() {
    const { selectedShop, jwtToken, filterOrder, orderNumber } = this;
    if (filterOrder !== undefined) {
      const key = Object.keys(filterOrder)[0];
      this[key] = { ...this[key], ...filterOrder?.[key] };
    }
    if (orderNumber !== undefined) {
      this.keyword = orderNumber;
      this.flag = true;
    }
    switch (jwtToken?.role) {
      case PERMISSION.APP_ADMIN: {
        this.getInitialData();
        this.adminReadOnly = true;
        break;
      }
      case PERMISSION.SHOP_ADMIN: {
        if (selectedShop !== "") this.getInitialData();
        break;
      }
      default: {
        break;
      }
    }
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    regionName(regionId) {
      const { regionList } = this;
      const region = regionList?.data?.find((i) => i.id === regionId);
      return region?.region_desc || "";
    },
    shopTypeName(txt) {
      let result;
      switch (txt) {
        case "agent":
          result = "เอเย่นต์";
          break;
        case "sub-agent":
          result = "ซับเอเยนต์";
          break;
        case "sub-direct":
          result = "ซับเอเยนต์";
          break;
        case "retail":
          result = "ร้านค้าปลีก";
          break;
        default:
          break;
      }
      return result;
    },
    filterCondition() {
      const {
        buyerSelected,
        buyersList,
        sellerSelected,
        sellersList,
        jwtToken,
        createdDate,
        paymentStatus,
        shipStatus,
        shopType,
        status,
        regionSelected,
        regionList,
        retailSelected,
        shopTypeList,
        couponSelected,
        coupons,
      } = this;
      let condition = {
        photo: true,
      };
      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (this.category) {
        condition.categoryIds = this.category;
      }

      let paymentCount = 0;
      let shipCount = 0;
      let shopCount = 0;
      let statusCount = 0;

      for (const [key, value] of Object.entries(paymentStatus)) {
        if (value === true) {
          if (paymentCount === 0) {
            condition.paymentStatus = `${key}`;
            paymentCount++;
          } else {
            condition.paymentStatus += `,${key}`;
          }
        }
      }

      for (const [key, value] of Object.entries(shipStatus)) {
        if (value === true) {
          if (shipCount === 0) {
            condition.shipStatus = `${key}`;
            shipCount++;
          } else {
            condition.shipStatus += `,${key}`;
          }
        }
      }

      for (const [key, value] of Object.entries(shopType)) {
        if (value === true) {
          if (shopCount === 0) {
            if (key === "subAgent") {
              condition.shopTypes = `sub-agent,sub-direct`;
            } else {
              condition.shopTypes = `${key}`;
            }
            shopCount++;
          } else {
            if (key === "subAgent") {
              condition.shopTypes += `,sub-agent,sub-direct`;
            } else {
              condition.shopTypes += `,${key}`;
            }
          }
        }
      }

      for (const [key, value] of Object.entries(status)) {
        if (value === true) {
          if (statusCount === 0) {
            condition.status = `${key}`;
            statusCount++;
          } else {
            condition.status += `,${key}`;
          }
        }
      }

      if (size(createdDate) > 0) {
        const startDate = new Date(createdDate[0]);
        const end = new Date(createdDate[1]);
        const endDate = moment(end).endOf("day").toDate();
        if (size(createdDate) === 1) condition.startDate = startDate;
        if (size(createdDate) === 2) {
          condition.startDate = startDate;
          condition.endDate = endDate;
        }
      }

      // paging
      {
        // Per

        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      if (couponSelected) {
        let couponId = 0;
        forEach(coupons.data, (e) => {
          if (e.name === couponSelected) {
            couponId = e.id;
          }
        });
        condition.couponId = couponId;
      }

      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          if (buyerSelected) {
            let buyerId = 0;
            forEach(buyersList.data, (e) => {
              if (e.name === buyerSelected) {
                buyerId = e.id;
              }
            });
            condition.buyerId = buyerId;
          }

          if (retailSelected) {
            let buyerId = 0;
            forEach(shopTypeList.data, (e) => {
              if (e.name === retailSelected) {
                buyerId = e.id;
              }
            });
            if (condition.buyerId !== undefined)
              condition.buyerId += "," + buyerId;
            else condition.buyerId = buyerId;
          }

          if (sellerSelected) {
            let sellerId = 0;
            forEach(sellersList.data, (e) => {
              if (e.name === sellerSelected) {
                sellerId = e.id;
              }
            });
            condition.sellerId = sellerId;
          }

          if (regionSelected) {
            let regionId = 0;
            forEach(regionList.data, (e) => {
              if (e.region_desc === regionSelected) {
                regionId = e.id;
              }
            });
            condition.regionId = regionId;
          }
          break;
        }
        case PERMISSION.SHOP_ADMIN: {
          if (buyerSelected) {
            let buyerId = 0;
            forEach(buyersList.data, (e) => {
              if (e.buyer.name === buyerSelected) {
                buyerId = e.buyerId;
              }
            });
            condition.buyerId = buyerId;
          }
          break;
        }
        default: {
          break;
        }
      }
      return condition;
    },
    async getInitialData() {
      const { jwtToken, orderNumber } = this;
      const condition = this.filterCondition();
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          await this.getAdminOrders(condition);
          await this.getAdminBuyers();
          await this.getAdminSellers();
          await this.getShopTypes({ shopTypes: "retail" });
          break;
        }
        case PERMISSION.SHOP_ADMIN: {
          await this.getOrders(condition);
          await this.getBuyers();
          break;
        }
        default: {
          break;
        }
      }
      if (orderNumber && this.flag) {
        this.flag = false;
        this.selectItem(this.orders?.data?.[0]);
      }
      this.getCoupons();
    },
    async submitAction() {
      // TODO: Export Data
      const { jwtToken, shopList, selectedShop } = this;
      const condition = this.filterCondition();
      let filename = "OrderExport.xlsx";
      let res;
      switch (jwtToken?.role) {
        case PERMISSION.APP_ADMIN: {
          res = await this.exportAdminOrder(condition);
          filename = `OrderExport_${moment().format("YYMMDD_HHmmss")}.xlsx`;
          break;
        }
        case PERMISSION.SHOP_ADMIN: {
          let shop = shopList.find((e) => e.value === selectedShop);
          res = await this.exportOrders(condition);
          filename = `${shop?.code || ""}_OrderExport_${moment().format(
            "YYMMDD_HHmmss"
          )}.xlsx`;
          break;
        }
        default: {
          break;
        }
      }
      fileDownload(res, filename);
    },
    async confirmOrder({ event = false, note = "" }) {
      if (event) {
        await this.approveOrder({
          orderId: this.orderDetail.id,
          approve: true,
          note,
        });
      } else {
        await this.cancelOrder({ orderId: this.orderDetail.id, note });
      }
      this.reset();
    },
    async confirmPayment({ event = false, note = "" }) {
      if (event) {
        await forEach(this.orderPaymentList.data, (i) => {
          this.approvePayment({
            paymentId: i.id,
            orderId: this.orderDetail.id,
            note,
          });
        });
      }
      this.reset();
    },
    async confirmShipment({ event = false, updateShipList = [], note = "" }) {
      const orderList = reduce(
        updateShipList,
        (a, b) => {
          if (b.newQty !== 0)
            a.push({ orderListId: b.id, qty: parseInt(b.newQty) });
          return a;
        },
        []
      );
      if (event) {
        await this.uploadShipment({
          orderId: this.orderDetail.id,
          note,
          // image: this.orderDetail.image,
          orderList,
        });
      }
      this.reset();
    },
    selectItem(item) {
      this.orderDetailId = item.id;
      this.modal = true;
    },
    reset() {
      this.orderDetailId = null;
      this.modal = false;
      this.getInitialData();
    },
    searchKeyword() {
      this.getInitialData();
    },
    onClear() {
      this.createdDate = [];
    },
    updateDateInput(e) {
      let compare;
      if (size(e) > 1) {
        if (moment(e[0]).diff(e[1], "day") > 0) {
          compare = [e[1], e[0]];
        } else {
          compare = e;
        }
      } else {
        compare = e;
      }
      this.createdDate = compare;
    },
  },
  mixins: [Utils],
  props: {
    filterOrder: {
      type: Object,
    },
    orderNumber: {
      type: String,
    },
  },
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    options: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            this.getInitialData();
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    paymentStatus: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            this.getInitialData();
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    shipStatus: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            this.getInitialData();
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    shopType: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            this.getInitialData();
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
    status: {
      handler() {
        const { selectedShop, jwtToken } = this;
        switch (jwtToken?.role) {
          case PERMISSION.APP_ADMIN: {
            this.getInitialData();
            break;
          }
          case PERMISSION.SHOP_ADMIN: {
            if (selectedShop !== "") this.getInitialData();
            break;
          }
          default: {
            break;
          }
        }
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
$blue-text: #4986e8;
$light-green-text: #92c47c;
$gray: #d9d9d9;
$green-text: #009b3f;
$green-bg: #d9ead3ff;

.green--text {
  color: $green-text;
}
.blue-text {
  color: $blue-text;
}
.light-green--text {
  color: $light-green-text;
}

.order-container {
  padding: 20px;
  .order-number {
    cursor: pointer;
  }
  button {
    &.active {
      color: $green-text;
      background: $green-bg;
    }
  }
  .table-header {
    transition: 0.1s;
    .v-toolbar__content {
      display: block;
      .table-button {
        margin-top: 3px;
      }
      .button-card {
        border-radius: 8px;
      }
      @keyframes fade_in_show {
        0% {
          opacity: 0;
          // transform: scaleY(0);
        }

        100% {
          opacity: 1;
          // transform: scaleY(1);
        }
      }
      .show {
        display: flex;
        animation: fade_in_show 0.5s;

        // max-height: auto;
        // transition: max-height 0.25s ease-in;

        // transition-property: visibility, opacity;
        // transition-duration: 1s, 0s, 1s;

        //  transform: scaleY(1);
      }
      .hidden {
        display: none;

        // max-height: 0px;
        // transition: max-height 0.15s ease-out;

        // opacity: 0;
        // visibility: hidden;
        // transition-property: opacity, visibility;
        // transition-duration: 1s, 0s, 1s;
        // transition-delay: 0s, 1s, 1s;

        // transform: scaleY(0);
        // transform-origin: top;
        // transition: transform 0.26s ease;
      }
      .collapse-button {
        &.rotate-icon {
          transform: rotate(180deg);
        }
        button {
          &:focus {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
</style>