import { get, isEmpty, reduce } from 'lodash'

import { setAuthorization, REQUEST_GET, REQUEST_POST } from './API_Request'
import router from '@/router'
import { getToken } from '@/plugins/auth'
import { application, AlertMessage, errorMessage } from '@/plugins/common'
import {
  GLOBAL_MESSAGE,
  LOGIN_MESSAGE,
  REGISTER_MESSAGE,
  FORGOT_PASSWORD_MESSAGE,
  RESET_PASSWORD_MESSAGE,
  CHANGE_PASSWORD_MESSAGE,
} from '@/plugins/message'

export const state = {
  token: '',
  apptoken: '',
  access_count: 0,
  shopList: [],
  selectedShop: '',
}

const sleep = (ms = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const mutations = {
  async APP_ACCESS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/access_token', {
        application,
        code: data.code,
      })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.apptoken = token.accessToken
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      if (state.access_count >= 2) {
        localStorage.setItem('access_count', 0)
        const error = errorMessage(e)
        const alert = new AlertMessage(error, 'error', 'error')
        data.toggleAlert(alert)

        await sleep(1000)
        window.location = '/login'
      } else {
        state.access_count++

        await sleep(2000)
        return mutations.APP_ACCESS(state, data)
      }
      return e
    }
  },
  async LOGIN(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const form = {
        channel: 'password',
        credential: data.formData,
      }

      const res = await REQUEST_POST('/api/auth', form, { headers })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.token = token.accessToken
      localStorage.setItem('token', token.accessToken)
      data.toggleLoading()

      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(LOGIN_MESSAGE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          router.push({ name: 'Home' })
          setAuthorization(state.token)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error) alert = new AlertMessage(LOGIN_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async REGISTER(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const form = {
        name: data.formData?.name,
        email: data.formData?.email,
        phone: data.formData?.phone,
        inviteRef: data.formData?.inviteRef,
        authChannel: 'password',
        authCredential: {
          password: data.formData?.password,
        },
        consents: data.formData?.consent,
      }

      const res = await REQUEST_POST('/api/users', form, { headers })
      data.toggleLoading()

      switch (get(res, 'status')) {
        case 201: {
          const alert = new AlertMessage(
            REGISTER_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
          await sleep(1000)
          window.location = '/login'
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(REGISTER_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  LOGOUT() {
    localStorage.removeItem('token')
    // if (get(router, 'history.current.name') !== 'Login') router.push({ name: "Login" })
    location.reload()
  },
  async FORGOT_PASSWORD(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const res = await REQUEST_POST('/api/forgot-password', data.formData, {
        headers,
      })
      data.toggleLoading()
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            FORGOT_PASSWORD_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(
          FORGOT_PASSWORD_MESSAGE.ERROR,
          'error',
          'error'
        )
      data.toggleAlert(alert)
      return e
    }
  },
  async RESET_PASSWORD(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const res = await REQUEST_POST('/api/reset-password', data.formData, {
        headers,
      })
      data.toggleLoading()
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            RESET_PASSWORD_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)

          await sleep(1000)
          window.location = '/login'
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(RESET_PASSWORD_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async CHANGE_PASSWORD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/change-password', data.formData)

      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            CHANGE_PASSWORD_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
          await sleep(1000)
          location.reload()
          break
        }
        default: {
          data.toggleLoading()
          break
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(
          CHANGE_PASSWORD_MESSAGE.ERROR,
          'error',
          'error'
        )
      data.toggleAlert(alert)
      return e
    }
  },
  UPDATE_SHOP_LIST(state, data) {
    const { shopList } = data
    const result = reduce(
      shopList,
      (o, e) => {
        o.push({ text: e.name, code: e.code, value: e.id })
        return o
      },
      []
    )
    state.shopList = result
    return state
  },
  SELECT_SHOP_LIST(state, data) {
    state.selectedShop = data.selectedShop
    return state
  },
  async GET_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const res = await REQUEST_GET(`/api/consents`, {}, headers)
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async GET_COMPLETE_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/consents/complete`)
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async CHECK_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/consents/waiting`)
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async ACCEPT_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(`/api/consents`, { data: data.form })
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
          data.toggleLoading()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
}

export const actions = {
  appAccess(context, code) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.APP_ACCESS(state, { toggleLoading, code, toggleAlert })
  },
  login(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('LOGIN', { toggleLoading, formData, toggleAlert })
  },
  register(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REGISTER', { toggleLoading, formData, toggleAlert })
  },
  logout(context) {
    context.commit('LOGOUT')
  },
  forgotPassword(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('FORGOT_PASSWORD', { toggleLoading, formData, toggleAlert })
  },
  resetPassword(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('RESET_PASSWORD', { toggleLoading, formData, toggleAlert })
  },
  changePassword(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CHANGE_PASSWORD', { toggleLoading, formData, toggleAlert })
  },
  updateShopList(context, shopList) {
    context.commit('UPDATE_SHOP_LIST', { shopList })
  },
  selectedShopList(context, selectedShop) {
    context.commit('SELECT_SHOP_LIST', { selectedShop })
  },
  getUserConsent(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_USER_CONSENT(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  getCompleteConsent(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_COMPLETE_CONSENT(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  checkUserConsent(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CHECK_USER_CONSENT(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  acceptUserConsent(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.ACCEPT_USER_CONSENT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  auth(state) {
    const token = getToken()
    if (!isEmpty(token)) state.token = token
    return state
  },
  role(state) {
    return state.role
  },
  shopList(state) {
    return state.shopList
  },
  selectedShop(state) {
    return state.selectedShop
  },
}

export const authentication = {
  state,
  mutations,
  actions,
  getters,
}
