<template>
  <div id="home-layout">
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      :onClose="() => onCloseAlert()"
    />
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import Utils from "@/mixin";

import Alert from "@/components/Alert.vue";

export default {
  components: {
    Alert,
  },
  computed: {
    snackbar() {
      if (!isEmpty(this.alert?.message)) return true;
      return false;
    },
    alertText() {
      if (!isEmpty(this.alert?.message)) return this.alert?.message;
      return "";
    },
    alertType() {
      if (!isEmpty(this.alert?.message)) return this.alert?.type;
      return "";
    },
  },
  method: {
    onCloseAlert() {
      console.log(this);
      this.alert.message = "";
    },
  },
  watch: {
    snackbar(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.alert.message = "";
        }, 3000);
      }
    },
  },
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
#home-layout {
  min-height: 100vh;
  .home-logo {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }
}
</style>