import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, jsonToForm, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  brands: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_BRANDS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/cms/brands', data.form)
      state.brands.data = res?.data?.data || []
      state.brands.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_BRANDS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shop/${data.selectedShop}/brands`,
        data.form
      )
      state.brands.data = res?.data?.data || []
      state.brands.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_BRAND(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/cms/brand', jsonToForm(data.form))
      state.brands.data.push(res.data)
      state.brands.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_BRAND(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/cms/brand/${data.form?.id}`,
        jsonToForm(data.form)
      )
      const brands = cloneDeep(state.brands.data)
      const index = findIndex(brands, { id: res?.data?.id })

      brands[index] = res.data
      state.brands = {
        ...state.brands,
        data: brands,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_BRAND(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/cms/brand/${data.form?.id}`)

      const brands = cloneDeep(state.brands.data)
      const index = findIndex(brands, { id: data.form?.id })
      brands.splice(index, 1)

      state.brands = {
        ...state.brands,
        data: brands,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  async getBrands(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_BRANDS(state, { toggleLoading, form, toggleAlert })
  },
  async getShopBrands(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    const selectedShop = context?.rootGetters?.selectedShop
    return mutations.GET_SHOP_BRANDS(state, {
      toggleLoading,
      form,
      selectedShop,
      toggleAlert,
    })
  },
  async createBrand(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_BRAND(state, { toggleLoading, form, toggleAlert })
  },
  async editBrand(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_BRAND(state, { toggleLoading, form, toggleAlert })
  },
  async deleteBrand(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_BRAND(state, { toggleLoading, form, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  brands(state) {
    // eslint-disable-line
    return state.brands
  },
}

export const brands = {
  state,
  mutations,
  actions,
  getters,
}
