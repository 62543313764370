<template>
  <div class="coupon-report-container">
    <h2 class="mb-3">รายงานการใช้คูปอง</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="couponTransactionsList"
        :server-items-length="
          couponTransactions ? couponTransactions.total : total
        "
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
            class="table-header"
            :height="checkScreen"
          >
            <v-row>
              <v-col class="px-0 pr-2" md="4" cols="12">
                <DateInput
                  label="วันที่สั่งซื้อ"
                  range
                  disableMin
                  :dialog="dateDialog"
                  :value="createdDate"
                  :updateInput="updateDateInput"
                  @clear="onClear"
                />
              </v-col>
              <v-col class="px-0 pr-2" md="4" cols="12">
                <v-combobox
                  :items="regionItem"
                  v-model="regionSelected"
                  label="ภาค"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col
                class="px-0 text-right pr-2"
                cols="12"
                md="4"
                v-if="!checkMdScreen"
              >
                <v-btn
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  class="mr-2"
                  >ค้นหา</v-btn
                >
                <v-btn outlined depressed @click="submitAction"> ส่งออก </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-0 pr-2" md="4" cols="12">
                <v-combobox
                  :items="sellerItem"
                  v-model="sellerSelected"
                  label="ผู้ขาย"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" md="4" cols="12">
                <v-combobox
                  :items="buyerItem"
                  className="mr-2"
                  v-model="buyerSelected"
                  label="ผู้ซื้อ"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" md="4" cols="12">
                <v-combobox
                  :items="couponItem"
                  className="mr-2"
                  v-model="couponSelected"
                  label="คูปอง"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col
                class="px-0 text-right pr-2"
                cols="12"
                md="4"
                v-if="checkMdScreen"
              >
                <v-btn
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  block
                  class="table-button mr-2"
                  >ค้นหา</v-btn
                >
                <v-btn
                  outlined
                  depressed
                  @click="submitAction"
                  block
                  class="table-button mt-3"
                >
                  ส่งออก
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.order.seller.regionId="{ item }">
          {{ regionName(item.order.seller.regionId) }}
        </template>
        <template v-slot:item.order.orderNumber="{ item }">
          <b
            v-if="item"
            class="success--text order-number"
            @click="selectItem(item)"
          >
            {{ item.order.orderNumber }}
          </b>
        </template>
        <template v-slot:item.order.seller.name="{ item }">
          <div
            v-if="item && item.order.seller.shopType === 'agent'"
            class="green--text"
          >
            {{ item.order.seller.name }}
          </div>
          <div
            v-if="item && item.order.seller.shopType === 'retail'"
            class="blue--text"
          >
            {{ item.order.seller.name }}
          </div>
          <div
            v-if="
              (item && item.order.seller.shopType === 'sub-agent') ||
              (item && item.order.seller.shopType === 'sub-direct')
            "
            class="light-green--text"
          >
            {{ item.order.seller.name }}
          </div>
        </template>
        <template v-slot:item.order.seller.shopType="{ item }">
          <div v-if="item">
            {{ shopTypeName(item.order.seller.shopType) }}
          </div>
        </template>
        <template v-slot:item.order.buyer.name="{ item }">
          <div
            v-if="item && item.order.buyer.shopType === 'agent'"
            class="green--text"
          >
            {{ item.order.buyer.name }}
          </div>
          <div
            v-if="item && item.order.buyer.shopType === 'retail'"
            class="blue--text"
          >
            {{ item.order.buyer.name }}
          </div>
          <div
            v-if="
              (item && item.order.buyer.shopType === 'sub-agent') ||
              (item && item.order.buyer.shopType === 'sub-direct')
            "
            class="light-green--text"
          >
            {{ item.order.buyer.name }}
          </div>
        </template>
        <template v-slot:item.order.buyer.shopType="{ item }">
          <div v-if="item">
            {{ shopTypeName(item.order.buyer.shopType) }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ $generateDate(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item)"
            ><b>ดูข้อมูล</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <OrderDetail
      :modal="modal"
      :orderDetailId="orderDetailId"
      :submitAction="reset"
      :adminReadOnly="true"
    />
  </div>
</template>

<script>
import { forEach, get, reduce, size } from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";

import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import DateInput from "@/components/DateInput";

import OrderDetail from "@/views/Auth/OrderDetail";

export default {
  name: "AdminCouponReport",
  components: {
    DateInput,
    OrderDetail,
  },
  computed: {
    couponTransactionsList() {
      const { couponTransactions } = this;
      const result = reduce(
        couponTransactions.data,
        (o, e) => {
          o.push({ ...e, couponName: get(e, "coupon.name", "") });
          return o;
        },
        []
      );
      return result;
    },
    buyerItem() {
      const { buyersList } = this;
      let result = [];
      result = reduce(
        buyersList.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    sellerItem() {
      const { sellersList } = this;
      const result = reduce(
        sellersList.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    regionItem() {
      const { regionList } = this;
      const result = reduce(
        regionList.data,
        (o, e) => {
          o.push(e.region_desc);
          return o;
        },
        []
      );
      return result;
    },
    couponItem() {
      const { coupons } = this;
      const result = reduce(
        coupons.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 1440) {
        return "120px";
      } else if (windowWidth < 1440 && windowWidth >= 960) {
        return "120px";
      } else if (windowWidth < 960 && windowWidth >= 734) {
        return "450px";
      } else if (windowWidth < 734 && windowWidth >= 600) {
        return "450px";
      } else if (windowWidth < 600 && windowWidth >= 445) {
        return "450px";
      } else if (windowWidth < 445) {
        return "450px";
      }
      return "0px";
    },
    checkMdScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return false;
      } else {
        return true;
      }
    },
  },
  data: () => ({
    headers: [
      {
        text: "เลขที่ออเดอร์",
        value: "order.orderNumber",
        align: "start",
        width: "100px",
        sortable: false,
      },
      {
        text: "วันที่สั่งซื้อ",
        value: "created_at",
        width: "100px",
        sortable: false,
      },
      {
        text: "ชื่อคูปอง",
        value: "couponName",
        width: "250px",
        sortable: false,
      },
      {
        text: "รหัสคูปอง",
        value: "couponCode",
        width: "100px",
        sortable: false,
      },
      { text: "ภาค", value: "order.seller.regionId", sortable: false },
      {
        text: "รหัสผู้ขาย",
        value: "order.seller.code",
        width: "100px",
        sortable: false,
      },
      {
        text: "ชื่อผู้ขาย",
        value: "order.seller.name",
        width: "250px",
        sortable: false,
      },
      {
        text: "ประเภทผู้ขาย",
        value: "order.seller.shopType",
        sortable: false,
      },
      {
        text: "รหัสผู้ซื้อ",
        value: "order.buyer.code",
        width: "100px",
        sortable: false,
      },
      {
        text: "ชื่อผู้ซื้อ",
        value: "order.buyer.name",
        width: "250px",
        sortable: false,
      },
      {
        text: "ประเภทผู้ซื้อ",
        value: "order.buyer.shopType",
        width: "100px",
        sortable: false,
      },
      { text: "ดำเนินการ", value: "actions", width: "100px", sortable: false },
    ],
    options: {},
    itemsPerPageOptions: [],
    total: 0,
    buyerSelected: "",
    sellerSelected: "",
    regionSelected: "",
    couponSelected: "",
    dateDialog: false,
    createdDate: [],
    orderDetailId: null,
    modal: false,
    windowWidth: window.innerWidth,
  }),
  methods: {
    regionName(regionId) {
      const { regionList } = this;
      const region = regionList?.data?.find((i) => i.id === regionId);
      return region?.region_desc || "";
    },
    filterCondition() {
      const {
        buyerSelected,
        buyersList,
        sellerSelected,
        sellersList,
        regionSelected,
        regionList,
        couponSelected,
        coupons,
        createdDate,
      } = this;
      let condition = {};
      // filter
      if (size(createdDate) > 0) {
        const startDate = new Date(createdDate[0]);
        const end = new Date(createdDate[1]);
        const endDate = moment(end).endOf("day").toDate();
        if (size(createdDate) === 1) condition.startDate = startDate;
        if (size(createdDate) === 2) {
          condition.startDate = startDate;
          condition.endDate = endDate;
        }
      }

      // paging
      {
        // Per

        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }
      if (buyerSelected) {
        let buyerId = 0;
        forEach(buyersList.data, (e) => {
          if (e.name === buyerSelected) {
            buyerId = e.id;
          }
        });
        condition.buyerId = buyerId;
      }
      if (sellerSelected) {
        let sellerId = 0;
        forEach(sellersList.data, (e) => {
          if (e.name === sellerSelected) {
            sellerId = e.id;
          }
        });
        condition.sellerId = sellerId;
      }

      if (regionSelected) {
        let regionId = 0;
        forEach(regionList.data, (e) => {
          if (e.region_desc === regionSelected) {
            regionId = e.id;
          }
        });
        condition.regionId = regionId;
      }

      if (couponSelected) {
        let couponId = 0;
        forEach(coupons.data, (e) => {
          if (e.name === couponSelected) {
            couponId = e.id;
          }
        });
        condition.couponId = couponId;
      }
      return condition;
    },
    async getInitialData() {
      const condition = this.filterCondition();
      await this.getAdminCouponTransactions(condition);
      await this.getAdminCoupons();
      await this.getCouponBuyers();
      await this.getCouponSellers();
      await this.getRegions();
    },
    shopTypeName(txt) {
      let result;
      switch (txt) {
        case "agent":
          result = "เอเย่นต์";
          break;
        case "sub-agent":
          result = "ซับเอเยนต์";
          break;
        case "sub-direct":
          result = "ซับเอเยนต์";
          break;
        case "retail":
          result = "ร้านค้าปลีก";
          break;
        default:
          break;
      }
      return result;
    },
    searchKeyword() {
      this.getInitialData();
    },
    onClear() {
      this.createdDate = [];
    },
    updateDateInput(e) {
      let compare;
      if (size(e) > 1) {
        if (moment(e[0]).diff(e[1], "day") > 0) {
          compare = [e[1], e[0]];
        } else {
          compare = e;
        }
      } else {
        compare = e;
      }
      this.createdDate = compare;
    },
    selectItem(item) {
      this.orderDetailId = item.orderId;
      this.modal = true;
    },
    reset() {
      this.orderDetailId = null;
      this.modal = false;
      this.getInitialData();
    },
    async submitAction() {
      // TODO: Export Data
      const condition = this.filterCondition();
      let filename = "CouponUsedExport.xlsx";
      let res;
      res = await this.exportAdminCoupons(condition);
      filename = `CouponUsedExport_${moment().format("YYMMDD_HHmmss")}.xlsx`;
      fileDownload(res, filename);
    },
  },
  mounted() {
    this.itemsPerPageOptions = itemsPerPageOptions;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  mixins: [Utils],
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    status: {
      buyerSelected() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
$green-text: #009b3f;
$green-bg: #d9ead3ff;

.coupon-report-container {
  padding: 20px;
  .order-number {
    cursor: pointer;
  }
  button {
    &.active {
      color: $green-text;
      background: $green-bg;
    }
  }
  .table-header {
    transition: 0.1s;
    .v-toolbar__content {
      display: block;
      .table-button {
        margin-top: 3px;
      }
    }
  }
}
</style>