<template>
  <v-container class="login-container" data-testid="login">
    <v-row>
      <v-col
        cols="12"
        offset-md="3"
        md="6"
        offset-lg="4"
        lg="4"
        v-if="!switchForgotPassword"
      >
        <router-link :to="'/'">
          <v-img
            class="home-logo"
            :src="require('@/assets/logo.png')"
            height="200px"
            contain
          />
        </router-link>
        <h1>ช้างชวนช้อป</h1>
        <v-form
          :disabled="isLoading"
          v-model="valid"
          :lazy-validation="false"
          @submit.prevent="submitForm"
        >
          <v-text-field
            label="Email"
            id="email"
            v-model="email"
            type="email"
            :rules="[rules.required, rules.emailValidate]"
          />
          <v-text-field
            label="Password"
            id="password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :rules="[rules.required]"
            v-model="password"
          />
          <v-btn
            data-testid="login-button"
            id="login-button"
            outlined
            rounded
            large
            block
            type="submit"
            :disabled="isLoading || !valid"
            >เข้าสู่ระบบ</v-btn
          >
        </v-form>
        <div class="text-end mt-4">
          <v-btn
            plain
            :ripple="false"
            @click="switchForgotPassword = !switchForgotPassword"
            >ลืมรหัสผ่าน?</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" offset-md="3" md="6" offset-lg="4" lg="4" v-else>
        <router-link :to="'/'">
          <v-img
            class="home-logo"
            :src="require('@/assets/logo.png')"
            height="200px"
            contain
          />
        </router-link>
        <h1>รีเซ็ตรหัสผ่านเข้าสู่ระบบ</h1>
        <v-form
          :disabled="isLoading"
          v-model="valid"
          :lazy-validation="false"
          @submit.prevent="submitForm"
        >
          <v-text-field
            label="Email"
            id="email"
            v-model="email"
            type="email"
            :rules="[rules.required, rules.emailValidate]"
          />
          <v-btn
            data-testid="forgot-password-button"
            id="forgot-password-button"
            outlined
            rounded
            large
            block
            type="submit"
            :disabled="isLoading || !valid"
            >ยืนยันการรีเซ็ต</v-btn
          >
        </v-form>
        <div class="text-end mt-4">
          <v-btn
            plain
            :ripple="false"
            @click="switchForgotPassword = !switchForgotPassword"
            >ต้องการเข้าสู่ระบบ?</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { isEmpty } from "lodash";
import Utils from "@/mixin";

import { SetRedirectURL } from "@/plugins/common";

export default {
  mixins: [Utils],
  created() {
    this.checkHash();
  },
  data: () => ({
    switchForgotPassword: false,
    valid: false,
    show: false,
    email: "",
    password: "",
    rules: {
      required: (value) => !!value || "กรุณากรอกข้อมูล",
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        if (re.test(String(email).toLowerCase())) {
          return true;
        }
        return "รูปแบบอีเมลไม่ถูกต้อง";
      },
    },
    token: "",
  }),
  methods: {
    async checkHash() {
      // replace url pathname
      const { origin, pathname } = window.location;
      const aLink = document.createElement("a");
      aLink.href = origin + pathname;
      const url = new URL(aLink);

      const redirect = SetRedirectURL(this.$route.hash);
      if (redirect.token) {
        this.token = redirect.token;
        await this.appAccess(this.token);
        // replace url pathname
        window.history.pushState({}, "", url);
      } else {
        window.location = redirect.url;
      }
    },
    async submitForm() {
      const { switchForgotPassword, email, password } = this;
      if (switchForgotPassword) {
        const formData = {
          email,
        };
        this.forgotPassword(formData);
      } else {
        const formData = {
          email,
          password,
        };
        this.login(formData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>