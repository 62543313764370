<template>
  <div class="coupon-report-container">
    <h2 class="mb-3">รายงานการใช้คูปอง</h2>
    <v-card class="elevation-5 rounded-xl">
      <v-data-table
        class="pt-5 px-2"
        :loading="isLoading"
        :headers="headers"
        :items="couponTransactionsList"
        :server-items-length="
          couponTransactions ? couponTransactions.total : total
        "
        :footer-props="{
          itemsPerPageOptions,
        }"
        :options.sync="options"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-row>
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-text-field
                  :disabled="isLoading"
                  v-model="keyword"
                  label="ค้นหาจากเลขที่"
                  single-line
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col class="px-0 pr-2" cols="12" :md="true">
                <v-combobox
                  :items="buyerItem"
                  className="mr-2"
                  v-model="buyerSelected"
                  label="ผู้ซื้อ"
                  dense
                  outlined
                  single-line
                  hide-details
                />
              </v-col>
              <v-col class="px-0" cols="12" :md="true">
                <DateInput
                  label="วันที่สั่งซื้อ"
                  range
                  disableMin
                  :dialog="dateDialog"
                  :value="createdDate"
                  :updateInput="updateDateInput"
                  @clear="onClear"
                />
              </v-col>
              <v-col class="px-0 text-right pr-2" cols="12" lg="2" md="3">
                <v-btn
                  outlined
                  depressed
                  @click="searchKeyword"
                  color="#009b3f"
                  class="mr-2"
                  >ค้นหา</v-btn
                >
                <v-btn outlined depressed @click="submitAction"> ส่งออก </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.order.orderNumber="{ item }">
          <b
            v-if="item"
            class="success--text order-number"
            @click="selectItem(item)"
          >
            {{ item.order.orderNumber }}
          </b>
        </template>
        <template v-slot:item.order.buyer.name="{ item }">
          <div
            v-if="item && item.order.buyer.shopType === 'agent'"
            class="green--text"
          >
            {{ item.order.buyer.name }}
          </div>
          <div
            v-if="item && item.order.buyer.shopType === 'retail'"
            class="blue--text"
          >
            {{ item.order.buyer.name }}
          </div>
          <div
            v-if="
              (item && item.order.buyer.shopType === 'sub-agent') ||
              (item && item.order.buyer.shopType === 'sub-direct')
            "
            class="light-green--text"
          >
            {{ item.order.buyer.name }}
          </div>
        </template>
        <template v-slot:item.order.buyer.shopType="{ item }">
          <div v-if="item">
            {{ shopTypeName(item.order.buyer.shopType) }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ $generateDate(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="success" @click="selectItem(item)"
            ><b>ดูข้อมูล</b></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <OrderDetail
      :modal="modal"
      :orderDetailId="orderDetailId"
      :submitAction="reset"
      :adminReadOnly="true"
    />
  </div>
</template>

<script>
import { forEach, get, reduce, size } from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";

import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import DateInput from "@/components/DateInput";

import OrderDetail from "@/views/Auth/OrderDetail";

export default {
  name: "CouponReport",
  components: {
    DateInput,
    OrderDetail,
  },
  computed: {
    couponTransactionsList() {
      const { couponTransactions } = this;
      const result = reduce(
        couponTransactions.data,
        (o, e) => {
          o.push({ ...e, couponName: get(e, "coupon.name", "") });
          return o;
        },
        []
      );
      return result;
    },
    buyerItem() {
      const { buyersList } = this;
      let result = [];
      result = reduce(
        buyersList.data,
        (o, e) => {
          o.push(e.name);
          return o;
        },
        []
      );
      return result;
    },
  },
  data: () => ({
    headers: [
      {
        text: "เลขที่ออเดอร์",
        value: "order.orderNumber",
        align: "start",
        sortable: false,
      },
      {
        text: "วันที่สั่งซื้อ",
        value: "created_at",
        width: "100px",
        sortable: false,
      },
      {
        text: "ชื่อคูปอง",
        value: "couponName",
        width: "250px",
        sortable: false,
      },
      { text: "รหัสคูปอง", value: "couponCode", sortable: false },
      {
        text: "ชื่อผู้ซื้อ",
        value: "order.buyer.name",
        width: "250px",
        sortable: false,
      },
      {
        text: "ประเภทผู้ซื้อ",
        value: "order.buyer.shopType",
        width: "100px",
        sortable: false,
      },
      { text: "ดำเนินการ", value: "actions", sortable: false },
    ],
    options: {},
    itemsPerPageOptions: [],
    total: 0,
    keyword: "",
    buyerSelected: "",
    dateDialog: false,
    createdDate: [],
    orderDetailId: null,
    modal: false,
  }),
  methods: {
    filterCondition() {
      const { buyerSelected, buyersList, createdDate, selectedShop } = this;
      let condition = {
        shopId: selectedShop,
      };
      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      if (size(createdDate) > 0) {
        const startDate = new Date(createdDate[0]);
        const end = new Date(createdDate[1]);
        const endDate = moment(end).endOf("day").toDate();
        if (size(createdDate) === 1) condition.startDate = startDate;
        if (size(createdDate) === 2) {
          condition.startDate = startDate;
          condition.endDate = endDate;
        }
      }

      // paging
      {
        // Per

        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }
      if (buyerSelected) {
        let buyerId = 0;
        forEach(buyersList.data, (e) => {
          if (e.name === buyerSelected) {
            buyerId = e.id;
          }
        });
        condition.buyerId = buyerId;
      }
      return condition;
    },
    async getInitialData() {
      const { selectedShop } = this;
      const condition = this.filterCondition();
      await this.getCouponTransactions(condition);
      await this.getCouponBuyers({ shopId: selectedShop });
    },
    shopTypeName(txt) {
      let result;
      switch (txt) {
        case "agent":
          result = "เอเย่นต์";
          break;
        case "sub-agent":
          result = "ซับเอเยนต์";
          break;
        case "sub-direct":
          result = "ซับเอเยนต์";
          break;
        case "retail":
          result = "ร้านค้าปลีก";
          break;
        default:
          break;
      }
      return result;
    },
    searchKeyword() {
      this.getInitialData();
    },
    onClear() {
      this.createdDate = [];
    },
    updateDateInput(e) {
      let compare;
      if (size(e) > 1) {
        if (moment(e[0]).diff(e[1], "day") > 0) {
          compare = [e[1], e[0]];
        } else {
          compare = e;
        }
      } else {
        compare = e;
      }
      this.createdDate = compare;
    },
    selectItem(item) {
      this.orderDetailId = item.orderId;
      this.modal = true;
    },
    reset() {
      this.orderDetailId = null;
      this.modal = false;
      this.getInitialData();
    },
    async submitAction() {
      // TODO: Export Data
      const { shopList, selectedShop } = this;
      const condition = this.filterCondition();
      let filename = "CouponUsedExport.xlsx";
      let res;
      let shop = shopList.find((e) => e.value === selectedShop);
      res = await this.exportCoupons(condition);
      filename = `${shop?.code || ""}_CouponUsedExport_${moment().format(
        "YYMMDD_HHmmss"
      )}.xlsx`;
      fileDownload(res, filename);
    },
  },
  mounted() {
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  mixins: [Utils],
  watch: {
    selectedShop(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    options: {
      handler() {
        const { selectedShop } = this;
        if (selectedShop !== "") this.getInitialData();
      },
      deep: false,
    },
    status: {
      buyerSelected() {
        const { selectedShop } = this;
        if (selectedShop !== "") this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$green-text: #009b3f;
$green-bg: #d9ead3ff;

.coupon-report-container {
  padding: 20px;
  .order-number {
    cursor: pointer;
  }
  button {
    &.active {
      color: $green-text;
      background: $green-bg;
    }
  }
}
</style>