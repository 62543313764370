import Vue from 'vue'
import Swal from 'sweetalert2'
import browserDetect from 'vue-browser-detect-plugin'
import moment from 'moment'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import DefaultLayout from '@/layouts/default.vue'
import HomeLayout from '@/layouts/homeLayout.vue'
import AuthLayout from '@/layouts/authLayout.vue'

import LightBox from '@/components/LightBox.vue'

import './assets/global.scss'

Vue.component('defaultLayout', DefaultLayout)
Vue.component('homeLayout', HomeLayout)
Vue.component('authLayout', AuthLayout)
Vue.component('LightBox', LightBox)

Vue.use(browserDetect)

Vue.config.productionTip = false

Vue.prototype.$swal = function(text) {
  Swal.fire('Error...', text, 'error')
}

Vue.prototype.$swalNoBtn = function(text) {
  const options = {
    icon: 'error',
    text: text,
    showConfirmButton: false,
    allowOutsideClick: false,
  }
  Swal.fire(options)
}

Vue.prototype.$swalSuccess = function(text) {
  Swal.fire('Success', text, 'success')
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

Vue.prototype.$bankingFormat = (str) => {
  let result = str
  if (str.length === 10) {
    result =
      str.substring(0, 1) +
      '-' +
      str.substring(1, 4) +
      '-' +
      str.substring(4, 7) +
      '-' +
      str.substring(7, 9) +
      '-' +
      str.substring(9, 10)
  }
  return result
}

Vue.prototype.$numberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

Vue.prototype.$generateDate = function(n) {
  return moment(n)
    .add(543, 'year')
    .format('DD/MM/YYYY HH:mm')
  // const date = new Date(n)
  // let month = date.getMonth()
  // const hour = date.getHours()
  // const min = date.getMinutes()
  // if (month < 10) {
  //   month = '0' + month
  // }
  // let result = date.getDate() + '/' + month + '/' + (date.getFullYear() + 543)
  // if (hour > 9) {
  //   if (min > 9) {
  //     result += ' ' + hour + ':' + min
  //   } else {
  //     result += ' ' + hour + ':0' + min
  //   }
  // } else {
  //   if (min > 9) {
  //     result += ' 0' + hour + ':' + min
  //   } else {
  //     result += ' 0' + hour + ':0' + min
  //   }
  // }
  // return result
}

Vue.prototype.$generateThaiDate = function(n) {
  const monthNamesThai = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]

  const date = new Date(n)
  let month = date.getMonth()
  const hour = date.getHours()
  const min = date.getMinutes()
  let result =
    date.getDate() +
    ' ' +
    monthNamesThai[month] +
    ' ' +
    (date.getFullYear() + 543)
  if (hour > 9) {
    if (min > 9) {
      result += ' ' + hour + ':' + min + ' น.'
    } else {
      result += ' ' + hour + ':0' + min + ' น.'
    }
  } else {
    if (min > 9) {
      result += ' 0' + hour + ':' + min + ' น.'
    } else {
      result += ' 0' + hour + ':0' + min + ' น.'
    }
  }
  return result
}
